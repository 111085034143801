import { createFormData } from 'api/createFormData'
import { Api } from 'api/generated'
import { requestInterceptor } from 'api/v1/interceptors/requestInterceptor'
import { responseErrorInterceptor } from 'api/v1/interceptors/responseInterceptor'

const { REACT_APP_API_URL } = process.env

export const generatedApi = new Api({
  baseURL: REACT_APP_API_URL,
})

/** Overriding createFormData method to our implementation  */
const _overriddenGeneratedApiReference = generatedApi as any
_overriddenGeneratedApiReference.createFormData = createFormData

generatedApi.instance.interceptors.request.use(requestInterceptor)
generatedApi.instance.interceptors.response.use(r => r, responseErrorInterceptor)

export const api = generatedApi.instance
