import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ConfigurationProfitChartResponse,
  GetConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGetParams,
  GetUserProfitChartV1ChartsClientProfitChartsUserProfitsGetParams,
} from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getUserProfitChart = createAsyncThunk<
  ConfigurationProfitChartResponse,
  GetUserProfitChartV1ChartsClientProfitChartsUserProfitsGetParams
>('tradingProfits/getUserProfitChart', async (params, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.getUserProfitChartV1ChartsClientProfitChartsUserProfitsGet(params)
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const getConfigurationProfitChart = createAsyncThunk<
  ConfigurationProfitChartResponse,
  GetConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGetParams
>('tradingProfits/getConfigurationProfitChart', async (params, thunkAPI) => {
  try {
    const { data } =
      await generatedApi.v1.getConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGet(
        params
      )
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const tradingProfitsThunks = {
  getUserProfitChart,
  getConfigurationProfitChart,
}

export default tradingProfitsThunks
