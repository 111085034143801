import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { CredentialWithBalanceDetail } from 'api/generated'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import tradingConfigurationsThunks from 'features/trading/redux/tradingConfigurations/tradingConfigurations.thunks'
import credentialsThunks from './credentials.thunks'
const { setUserCredentials, deleteUserCredentials, getUserCredentials, getUserCredentialsById, getServerIPs } =
  credentialsThunks
const { logout } = authThunks
const { updateTradingInfo } = tradingConfigurationsThunks

export interface CredentialsInitialState {
  isLoading: boolean
  isUpdated: boolean
  isLoadingEdit: boolean
  credentials: CredentialWithBalanceDetail[]
  credentialById: CredentialWithBalanceDetail | null
  error: string
  ips?: string[]
  isLoadingIPs: boolean
}

const initialState: CredentialsInitialState = {
  isLoading: false,
  isLoadingEdit: false,
  isUpdated: false,
  credentials: [],
  credentialById: null,
  error: '',
  ips: undefined,
  isLoadingIPs: false,
}

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(updateTradingInfo, () => initialState)
      .addMatcher(isFulfilled(getUserCredentials), (state, { payload }) => {
        state.credentials = payload
        state.isLoading = false
        state.isUpdated = false
      })
      .addMatcher(isFulfilled(getUserCredentialsById), (state, { payload }) => {
        state.credentials = state.credentials.map(credential => (credential.id == payload.id ? payload : credential))
        state.credentialById = payload
        state.isLoading = false
        state.isUpdated = false
      })
      .addMatcher(isFulfilled(deleteUserCredentials), (state, { payload }) => {
        state.credentials = state.credentials.filter(i => i.id !== payload)
        state.isLoadingEdit = false
        state.isUpdated = true
      })
      .addMatcher(isFulfilled(setUserCredentials), state => {
        state.isLoadingEdit = false
        state.isUpdated = true
      })
      .addMatcher(isPending(setUserCredentials, deleteUserCredentials), state => {
        state.isLoadingEdit = true
        state.error = ''
      })
      .addMatcher(isRejected(setUserCredentials, deleteUserCredentials), (state, { payload }) => {
        state.isLoadingEdit = false
        state.error = payload as string
      })
      .addMatcher(isPending(getUserCredentialsById, getUserCredentials), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isRejected(getUserCredentialsById, getUserCredentials), (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addMatcher(isPending(getServerIPs), state => {
        state.isLoadingIPs = true
      })
      .addMatcher(isFulfilled(getServerIPs), (state, { payload }) => {
        state.isLoadingIPs = false
        state.ips = payload
      })
      .addMatcher(isRejected(getServerIPs), state => {
        state.isLoadingIPs = false
      })
  },
})

export const credentialsActions = credentialsSlice.actions

export const { reducer: credentialsReducer } = credentialsSlice

export default credentialsReducer
