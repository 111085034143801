import React, { ChangeEvent, ReactElement } from 'react'
import FloatInput from 'shared/components/FormComponents/FloatInput'
import { Box, Grid, Typography } from '@mui/material'
import styles from 'features/trading/components/CreateConfigurationForm/styles.module.scss'
import { requiredNumberField } from 'constants/formRegisterOptions'
import CustomCheckBox from 'shared/components/FormComponents/CustomCheckBox'
import stopStrategiesNames from 'constants/stopStrategies'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { CurrencyPairDetailsDTO } from 'features/trading/ts/currencyPairs'
import AutoCompleteInput from 'shared/components/FormComponents/AutoCompleteInput'
import { CreateUpdateConfigurationDTO } from '../index'

type GridConfigurationFieldsParams = {
  control: Control<CreateUpdateConfigurationDTO, unknown>
  register: UseFormRegister<CreateUpdateConfigurationDTO>
  errors: FieldErrors<CreateUpdateConfigurationDTO>
  trancheTypesOptions: Array<{ label: string | undefined; value: number | undefined }>
  stopStrategies: Array<{ name: string; id: number }>
  stopLossStrategy: number | undefined
  setStopLossStrategy: (value: number) => void
  setStopLossValue: (value: string) => void
  stopLossValue: string
  stopStrategyError: boolean
  selectedCurrencyPairPrice?: CurrencyPairDetailsDTO
}

const GridConfigurationFields = ({
  control,
  register,
  errors,
  trancheTypesOptions,
  stopStrategies,
  stopLossStrategy,
  setStopLossStrategy,
  setStopLossValue,
  stopLossValue,
  stopStrategyError,
  selectedCurrencyPairPrice,
}: GridConfigurationFieldsParams) => {
  return (
    <>
      <FloatInput
        label={'Current asset price'}
        id={'asset_price'}
        value={selectedCurrencyPairPrice?.last ?? '0'}
        fullwidth={true}
        disabled={true}
      />
      <FloatInput
        label={'Start trading when the asset price is less or equal to'}
        id={'price_for_start'}
        register={register('grid_strategy_details.price_for_start', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
          max: selectedCurrencyPairPrice?.last
            ? {
                value: selectedCurrencyPairPrice?.last ?? 0,
                message: 'Value can not be higher than current asset price',
              }
            : undefined,
        })}
        defaultValue={selectedCurrencyPairPrice?.low_24h}
        currencyLabel={'USDT'}
        fullwidth={true}
        error={errors?.grid_strategy_details?.price_for_start?.message}
      />
      <FloatInput
        label={'Lower price threshold'}
        id={'lower_price_threshold'}
        register={register('grid_strategy_details.lower_price_threshold', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
        })}
        placeholder={'1'}
        currencyLabel={'USDT'}
        fullwidth={true}
        error={errors?.grid_strategy_details?.lower_price_threshold?.message}
      />
      <FloatInput
        label={'Sell all once an asset price hits'}
        id={'price_for_finish'}
        register={register('grid_strategy_details.price_for_finish', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
        })}
        placeholder={'1'}
        currencyLabel={'USDT'}
        fullwidth={true}
        error={errors?.grid_strategy_details?.price_for_finish?.message}
      />
      <FloatInput
        label={'Step percentage'}
        id={'step_percentage'}
        register={register('grid_strategy_details.step_percentage', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
        })}
        placeholder={'1'}
        maxValue={'100'}
        currencyLabel={'%'}
        fullwidth={true}
        error={errors?.grid_strategy_details?.step_percentage?.message}
      />
      <Typography variant={'inherit'} className={styles.trading__pair__label}>
        {'Tranche'}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FloatInput
            register={register('grid_strategy_details.tranche_value', requiredNumberField)}
            id={'tranche_value'}
            disabled={true}
            fullwidth={true}
            error={errors.grid_strategy_details?.tranche_value?.message}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoCompleteInput
            id={'grid_strategy_details.tranche_type_id'}
            disabled={true}
            placeholder={'Select tranche type...'}
            control={control}
            options={trancheTypesOptions}
            rules={{ required: { value: true, message: 'This field is required' } }}
            error={errors.grid_strategy_details?.tranche_type_id?.message}
          />
        </Grid>
      </Grid>
      <Typography variant={'inherit'} className={styles.stop__loss__label}>
        {'Stop loss'}
      </Typography>
      <Box className={styles.stop__loss__container}>
        {stopStrategies.map(
          (strategy, index): ReactElement => (
            <Grid key={index} container sx={{ maxHeight: '70px' }}>
              <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomCheckBox
                  checked={stopLossStrategy === strategy.id}
                  onChange={(): void => setStopLossStrategy(strategy.id)}
                  value={strategy.id}
                />
                <Typography
                  className={[
                    styles.stop__loss__strategy__label,
                    stopLossStrategy !== strategy.id ? styles.disabled : '',
                  ].join(' ')}
                >
                  {stopStrategiesNames.find(stopStrategy => stopStrategy.name === strategy.name)?.description}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <FloatInput
                  disabled={stopLossStrategy ? stopLossStrategy !== strategy.id : true}
                  id={'stop_strategy_value'}
                  placeholder={'99'}
                  fullwidth={true}
                  currencyLabel={'%'}
                  error={
                    stopLossStrategy === strategy.id
                      ? errors.grid_strategy_details?.stop_strategy_value?.message
                      : undefined
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setStopLossValue(e.target.value)
                  }}
                  value={stopLossStrategy === strategy.id ? stopLossValue : '0'}
                />
              </Grid>
            </Grid>
          )
        )}
      </Box>
      {stopStrategyError ? (
        <Typography className={styles.error__message} variant={'inherit'} color={'error'}>
          Please, select any stop strategy.
        </Typography>
      ) : null}
    </>
  )
}

export default GridConfigurationFields
