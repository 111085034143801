import { useMemo } from 'react'
import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { UserDetail } from 'api/generated'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { authActions } from 'features/auth/redux/auth/auth.slice'
import authThunks from 'features/auth/redux/auth/auth.thunks'

export const useAuth = () => {
  const userState = (state: RootState): UserDetail | null => state.auth.user
  const isLoadingState = (state: RootState): boolean => state.auth.isLoading
  const errorState = (state: RootState): string => state.auth.error
  const isAuth = useAppSelector(createSelector(userState, (user): boolean => user !== null))
  const user = useAppSelector(userState)
  const error = useAppSelector(errorState)
  const isLoading = useAppSelector(isLoadingState)
  const dispatch = useAppDispatch()
  return useMemo(() => {
    const actions = {
      ...authActions,
      ...authThunks,
    }
    return {
      ...bindActionCreators(actions, dispatch),
      isAuth,
      user,
      isLoading,
      error,
    }
  }, [isAuth, user, isLoading, error, dispatch])
}

export default useAuth
