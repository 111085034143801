import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { UpdateUserRequest } from 'api/generated'
import useAuth from 'features/auth/hooks/useAuth'
import { toast } from 'react-toastify'
import PasswordInput from 'shared/components/FormComponents/PasswordInput'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import Loader from 'shared/components/Loader'
import styles from './styles.module.scss'

const UpdateUserForm = () => {
  const { updateUser, isLoading } = useAuth()

  const {
    register,
    handleSubmit: handleSubmitPassword,
    setError,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm<UpdateUserRequest & { confirm_password: string }>()

  const onPasswordUpdate = (data: UpdateUserRequest & { confirm_password: string }): void => {
    if (data.new_password !== data.confirm_password) {
      return setError('confirm_password', {
        message: 'Confirm password should be equal to new password',
      })
    }
    updateUser(data)
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      toast.success('User password successfully changed!')
      reset()
    }
  }, [isSubmitSuccessful])

  return (
    <>
      <Box className={[styles.content, styles.content__margin].join(' ')}>
        <form
          id={'form_password'}
          key={1}
          onSubmit={handleSubmitPassword(onPasswordUpdate)}
          className={styles.content__container}
        >
          <Typography variant={'inherit'} className={styles.content__header}>
            Change password
          </Typography>
          <PasswordInput
            register={register('current_password', {
              required: { value: true, message: 'This field is required' },
              minLength: { value: 8, message: 'Password length should be more than 8.' },
            })}
            fullwidth={true}
            label={'Password'}
            error={errors.current_password?.message}
          />
          <PasswordInput
            register={register('new_password', {
              required: { value: true, message: 'This field is required' },
              minLength: { value: 8, message: 'Password length should be more than 8.' },
            })}
            fullwidth={true}
            label={'New password'}
            error={errors.new_password?.message}
          />

          <PasswordInput
            register={register('confirm_password', {
              required: { value: true, message: 'This field is required' },
            })}
            fullwidth={true}
            label={'Confirm password'}
            error={errors.confirm_password?.message}
          />
          {isLoading ? <Loader /> : <SubmitButton text={'Set new password'} />}
        </form>
      </Box>
    </>
  )
}

export default UpdateUserForm
