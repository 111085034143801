import { bindActionCreators } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import credentialsThunks from 'features/credentials/redux/credentials/credentials.thunks'

const useServerIps = (disabled = false) => {
  const { ips, isLoadingIPs } = useAppSelector((store: RootState) => store.credentials)
  const dispatch = useAppDispatch()

  const actionsDispatch = bindActionCreators(credentialsThunks, dispatch)
  const { getServerIPs } = actionsDispatch

  useEffect(() => {
    if (!ips && !isLoadingIPs && !disabled) {
      getServerIPs()
    }
  }, [ips, isLoadingIPs, disabled])

  return {
    ips,
    isLoadingIPs,
  }
}

export default useServerIps
