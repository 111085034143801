import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import useCredentials from 'features/credentials/hooks/useCredentials'
import { useNavigate } from 'react-router-dom'
import Loader from 'shared/components/Loader'
import styles from './styles.module.scss'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { ReactComponent as AlertErrorSmall } from 'common/icons/alert_error_small.svg'

const CredentialsBalance = () => {
  const { credentials, isLoading } = useCredentials()
  const navigate = useNavigate()

  const handleGoToSettingCredentials = () => {
    navigate('/profile')
  }

  return (
    <>
      {isLoading ? (
        <Box className={styles.balance__loader__container}>
          <Loader />
        </Box>
      ) : (
        credentials?.map(cred => (
          <Box className={styles.balance__item} key={cred.trading_platform_id}>
            <Box className={styles.container__title}>
              <img src={cred.trading_platform_image_url} alt={cred.trading_platform_name} className={styles.icon} />
              <Typography variant={'inherit'} className={styles.platform_name}>
                {cred.trading_platform_name}
              </Typography>
            </Box>
            {cred?.is_broken && cred.error_message ? (
              <Box className={styles.blockError} onClick={handleGoToSettingCredentials}>
                <Grid container>
                  <Grid item xs={1.5} marginTop={'2px'}>
                    <AlertErrorSmall />
                  </Grid>
                  <Grid item container xs={8.5}>
                    <Grid item xs={12}>
                      <Typography color={'#D63031'}>Credential error</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={styles.error_small_text}>Click to see more</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} display={'flex'} alignItems={'center'}>
                    <ArrowForwardOutlinedIcon />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className={styles.balance__currency__container}>
                <Box className={styles.balance__total__container}>
                  <Typography className={styles.balance__name} variant={'inherit'}>
                    Total balance USDT
                  </Typography>
                  <Typography className={styles.balance__amount} variant={'inherit'}>
                    {cred.balances.total_balance.toFixed(2) ?? 0}
                  </Typography>
                </Box>
                <Box className={styles.balance__available__container}>
                  <Typography className={styles.balance__name} variant={'inherit'}>
                    Available USDT
                  </Typography>
                  <Typography variant={'inherit'} className={styles.balance__amount}>
                    {cred.balances.available.toFixed(2) ?? 0}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        ))
      )}
    </>
  )
}

export default CredentialsBalance
