import { createAsyncThunk } from '@reduxjs/toolkit'
import { CreateUserCredentialRequest, CredentialDetail, CredentialWithBalanceDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getUserCredentialsById = createAsyncThunk<CredentialWithBalanceDetail, number>(
  'credentials/getUserCredentialsById',
  async (id, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getUserCredentialV1TradingClientCredentialsCredentialIdBalanceGet(id)
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const getUserCredentials = createAsyncThunk<CredentialWithBalanceDetail[]>(
  'credentials/getUserCredentials',
  async (_, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getUserCredentialsV1TradingClientCredentialsBalancesGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const setUserCredentials = createAsyncThunk<CredentialDetail, CreateUserCredentialRequest>(
  'credentials/setUserCredentials',
  async (requestData, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.createUserCredentialV1TradingClientCredentialsPost(requestData)
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const deleteUserCredentials = createAsyncThunk<number, number>(
  'credentials/deleteUserCredentials',
  async (id, thunkAPI) => {
    try {
      await generatedApi.v1.deleteUserCredentialV1TradingClientCredentialsCredentialIdDelete(id)
      return id
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const getServerIPs = createAsyncThunk<string[]>('credentials/getServerIPs', async (_, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.getServerIpsV1TradingPlatformsClientServerIpsGet()
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const credentialsThunks = {
  deleteUserCredentials,
  setUserCredentials,
  getUserCredentials,
  getUserCredentialsById,
  getServerIPs,
}

export default credentialsThunks
