import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { ConfigurationProfitChartResponse } from 'api/generated'
import tradingProfitsThunks from './tradingProfits.thunks'
const { getConfigurationProfitChart, getUserProfitChart } = tradingProfitsThunks

interface ProfitsInitialState {
  totalProfit: ConfigurationProfitChartResponse | null
  totalProfitIsLoading: boolean
  configurationProfit: ConfigurationProfitChartResponse | null
  configurationProfitIsLoading: boolean
}

const initialState: ProfitsInitialState = {
  totalProfit: null,
  totalProfitIsLoading: false,
  configurationProfit: null,
  configurationProfitIsLoading: false,
}

const tradingProfitsSlice = createSlice({
  name: 'tradingProfits',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isPending(getConfigurationProfitChart), state => {
        state.configurationProfitIsLoading = true
      })
      .addMatcher(isFulfilled(getConfigurationProfitChart), (state, action) => {
        state.configurationProfit = action.payload
        state.configurationProfitIsLoading = false
      })
      .addMatcher(isRejected(getConfigurationProfitChart), state => {
        state.configurationProfitIsLoading = false
      })
      .addMatcher(isPending(getUserProfitChart), state => {
        state.totalProfitIsLoading = true
      })
      .addMatcher(isFulfilled(getUserProfitChart), (state, action) => {
        state.totalProfit = action.payload
        state.totalProfitIsLoading = false
      })
      .addMatcher(isRejected(getUserProfitChart), state => {
        state.totalProfitIsLoading = false
      })
  },
})

export const tradingProfitsActions = tradingProfitsSlice.actions

export const { reducer: tradingProfitsReducer } = tradingProfitsSlice

export default tradingProfitsReducer
