/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiConsumerEvent {
  /** Id */
  id?: string

  /** An enumeration. */
  type: ApiConsumerEventsEnum

  /** Data */
  data:
    | OrderDetail
    | MasterOrderDetail
    | ConfigurationDetail
    | WithdrawalSuccededApiConsumerEventData
    | (OrderDetail & MasterOrderDetail & ConfigurationDetail & WithdrawalSuccededApiConsumerEventData)

  /** Consumer Id */
  consumer_id?: number

  /** Time */
  time?: string
}

/**
 * An enumeration.
 */
export enum ApiConsumerEventsEnum {
  OrderCreated = 'OrderCreated',
  OrderUpdated = 'OrderUpdated',
  TradingConfigurationStatusUpdated = 'TradingConfigurationStatusUpdated',
  WithdrawalSucceeded = 'WithdrawalSucceeded',
  AutomaticMasterOrderCreated = 'AutomaticMasterOrderCreated',
}

export interface BreakCredentialRequest {
  /** Error Message */
  error_message?: string
}

export interface ChangeCopyTradingPortfolioRequest {
  /** Cash */
  cash: number
}

export interface ConfigurationBalanceAssetDetail {
  /** Id */
  id: number

  /** Name */
  name: string

  /** Symbol */
  symbol: string

  /** External Id */
  external_id: string

  /** External Image Url */
  external_image_url: string

  /** Balance */
  balance?: number

  /** Locked */
  locked?: number

  /** Available */
  available: number

  /** Currency Asset Price */
  currency_asset_price?: number

  /** Balance As Cash */
  balance_as_cash: number

  /** Pair Quote */
  pair_quote: string

  /** Pair Base */
  pair_base: string

  /** Currency Pair Id */
  currency_pair_id: number
}

export interface ConfigurationBalanceDetail {
  /** Deposited */
  deposited: number

  /** Total */
  total: number

  /** Cash Balance */
  cash_balance: number

  /** Withdraw Profit */
  withdraw_profit: number

  /** Cash Locked */
  cash_locked: number

  /** Cash Available */
  cash_available: number

  /** Base Balance As Cash */
  base_balance_as_cash: number

  /** Assets */
  assets: ConfigurationBalanceAssetDetail[]
}

export interface ConfigurationDetail {
  /** Id */
  id: number

  /** Configuration Owner Id */
  configuration_owner_id: number

  /** Status */
  status: string

  /** Message */
  message?: string

  /** Name */
  name: string

  /** An enumeration. */
  strategy: TradingConfigurationStrategiesEnum
  profit: ConfigurationProfitDetail
  balances: ConfigurationBalanceDetail
  grid_strategy_configuration?: GridStrategyDetail
  cross_strategy_configuration?: CrossStrategyDetail
  copy_trading_strategy_configuration?: CopyTradingStrategyDetail
  trading_platform: TradingPlatformDetail

  /** Count Open Orders */
  count_open_orders?: number

  /**
   * Created At
   * @format date-time
   */
  created_at: string

  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string
}

export interface ConfigurationOwnerDetail {
  /** Id */
  id: number

  /** External Id */
  external_id: string

  /** Created By Api Consumer Id */
  created_by_api_consumer_id: number

  /**
   * Created At
   * @format date-time
   */
  created_at: string

  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string
}

export interface ConfigurationProfitChartResponse {
  /** Daily */
  daily: number

  /** Total */
  total: number

  /** Average Return */
  average_return: number

  /** Chart Data */
  chart_data: ProfitChartIndivisibleResponse[]
}

export interface ConfigurationProfitDetail {
  /** Total Cash Profit */
  total_cash_profit: number

  /** Total Percent Profit */
  total_percent_profit: number

  /** Operating Cash Profit */
  operating_cash_profit: number

  /** Operating Percent Profit */
  operating_percent_profit: number
}

export interface ConfigurationSimpleDetail {
  /** Id */
  id: number

  /** Configuration Owner Id */
  configuration_owner_id: number

  /** Status */
  status: string

  /** Name */
  name: string

  /** An enumeration. */
  strategy: TradingConfigurationStrategiesEnum
  grid_strategy_configuration?: GridStrategyDetail
  cross_strategy_configuration?: CrossStrategyDetail
  copy_trading_strategy_configuration?: CopyTradingStrategyDetail
  trading_platform: TradingPlatformDetail

  /** Count Open Orders */
  count_open_orders?: number

  /**
   * Created At
   * @format date-time
   */
  created_at: string

  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string
}

export interface ConfigurationStartEvent {
  /** Configuration Id */
  configuration_id: number
}

export interface ConfigurationStopEvent {
  /** Configuration Id */
  configuration_id: number
}

export interface CopyTradingPortfolioBalanceDetail {
  /** Cash */
  cash: number

  /** Assets */
  assets: PortfolioBalanceAssetDetail[]
}

export interface CopyTradingPortfolioDetail {
  /** Id */
  id: number

  /** Cash */
  cash: number
  trading_platform: TradingPlatformDetail

  /** Master Credential Id */
  master_credential_id?: number
  balances: CopyTradingPortfolioBalanceDetail
}

export interface CopyTradingPortfolioSimpleDetail {
  /** Id */
  id: number

  /** Cash */
  cash: number
  trading_platform: TradingPlatformDetail

  /** Master Credential Id */
  master_credential_id?: number
}

export interface CopyTradingStrategyDetail {
  /** Id */
  id: number

  /** Trading Platform Id */
  trading_platform_id: number

  /** Listened Copy Trading Master Portfolio Id */
  listened_copy_trading_master_portfolio_id: number
}

export interface CreateConfigurationOwnerCredentialRequest {
  /** Name */
  name?: string

  /** Configuration Owner Id */
  configuration_owner_id?: number

  /** Trading Platform Id */
  trading_platform_id: number

  /** Api Keys Json Data */
  api_keys_json_data: Record<string, string>
}

export interface CreateConfigurationOwnerRequest {
  /** External Id */
  external_id?: string
}

export interface CreateConfigurationRequest {
  /** Name */
  name: string

  /** An enumeration. */
  strategy: TradingConfigurationStrategiesEnum

  /** Configuration Owner Id */
  configuration_owner_id?: number
  grid_strategy_details?: CreateGridStrategyRequest
  cross_strategy_details?: CreateCrossStrategyRequest
  copy_trading_strategy_details?: CreateCopyTradingStrategyRequest
}

export interface CreateCopyTradingPortfolioCredentialRequest {
  /** Name */
  name: string

  /** Api Keys Json Data */
  api_keys_json_data: Record<string, string>
}

export interface CreateCopyTradingPortfolioRequest {
  /** Cash */
  cash: number

  /** Trading Platform Id */
  trading_platform_id: number
}

export interface CreateCopyTradingStrategyRequest {
  /** Trading Platform Id */
  trading_platform_id: number

  /** Listened Copy Trading Master Portfolio Id */
  listened_copy_trading_master_portfolio_id: number
}

export interface CreateCrossStrategyRequest {
  /** Currency Pair Id */
  currency_pair_id: number

  /** Trading Platform Id */
  trading_platform_id: number

  /** Sub Strategy Id */
  sub_strategy_id: number

  /** An enumeration. */
  interval: CrossStrategyIntervalEnum
}

export interface CreateGridStrategyRequest {
  /** Trading Platform Id */
  trading_platform_id: number

  /** Currency Pair Id */
  currency_pair_id: number

  /** Tranche Type Id */
  tranche_type_id: number

  /** Tranche Value */
  tranche_value: number

  /** Step Percentage */
  step_percentage: number

  /** Stop Strategy Id */
  stop_strategy_id: number

  /** Stop Strategy Value */
  stop_strategy_value?: number

  /** Price For Start */
  price_for_start: number

  /** Lower Price Threshold */
  lower_price_threshold: number

  /** Price For Finish */
  price_for_finish: number
}

export interface CreateMasterOrderRequest {
  /** Master Portfolio Id */
  master_portfolio_id: number

  /** External Id */
  external_id?: string

  /** Price */
  price: number

  /** Side */
  side: string

  /** Base External Id */
  base_external_id: string

  /** Amount */
  amount: number
}

export interface CreateMockOrderRequest {
  /** Price */
  price: number

  /** Amount */
  amount: number

  /** Trading Configuration Id */
  trading_configuration_id: number

  /** Currency Pair Id */
  currency_pair_id: number

  /** An enumeration. */
  side: TradingPlatformOrderSidesEnum

  /** An enumeration. */
  type: TradingPlatformOrderTypesEnum
}

export interface CreateUserConfigurationRequest {
  /** Name */
  name: string

  /** Strategy */
  strategy: string
  grid_strategy_details?: CreateGridStrategyRequest
  cross_strategy_details?: CreateCrossStrategyRequest
  copy_trading_strategy_details?: CreateCopyTradingStrategyRequest
}

export interface CreateUserCredentialRequest {
  /** Trading Platform Id */
  trading_platform_id: number

  /** Api Keys Json Data */
  api_keys_json_data: Record<string, string>
}

export interface CreateUserRequest {
  /**
   * Email
   * @format email
   */
  email: string

  /** Password */
  password: string

  /** First Name */
  first_name: string

  /** Last Name */
  last_name: string
}

export interface CredentialBalanceDetail {
  /** Total Balance */
  total_balance: number

  /** Available */
  available: number

  /** Reserved */
  reserved: number

  /** Total Cash Balance */
  total_cash_balance: number
}

export interface CredentialDetail {
  /** Id */
  id: number

  /** Trading Platform Id */
  trading_platform_id: number

  /** Configuration Owner Id */
  configuration_owner_id?: number

  /** Is Broken */
  is_broken?: boolean

  /** Error Message */
  error_message?: string
}

export interface CredentialSecretDetail {
  /** Id */
  id: number

  /** Exchange Id */
  exchange_id: string

  /** Owner Id */
  owner_id?: number

  /** Api Key */
  api_key?: string

  /** Secret Key */
  secret_key?: string

  /** Is Broken */
  is_broken?: boolean

  /** Error Message */
  error_message?: string
}

export interface CredentialWithBalanceDetail {
  /** Id */
  id: number

  /** Trading Platform Id */
  trading_platform_id: number

  /** Trading Platform Name */
  trading_platform_name: string

  /** Trading Platform Image Url */
  trading_platform_image_url: string

  /** Configuration Owner Id */
  configuration_owner_id: number
  balances: CredentialBalanceDetail

  /** Is Broken */
  is_broken?: boolean

  /** Error Message */
  error_message?: string
}

export interface CrossStrategyDetail {
  /** Id */
  id: number
  currency_pair: CurrencyPairDetail

  /** Interval */
  interval: string
  sub_strategy: CrossSubStrategyDetail
}

/**
 * An enumeration.
 */
export enum CrossStrategyIntervalEnum {
  Type1M = '1m',
  Type15M = '15m',
  Type1H = '1h',
  Type4H = '4h',
  Type1D = '1d',
}

export interface CrossSubStrategyDetail {
  /** Id */
  id: number

  /** Name */
  name: string

  /** Symbol */
  symbol: string
}

/**
 * An enumeration.
 */
export enum CrossSubStrategyEnum {
  MA = 'MA',
  HA = 'HA',
  MACD = 'MACD',
  SAR = 'SAR',
}

export interface CurrencyPairDetail {
  /** Id */
  id: number

  /** Base Id */
  base_id: number

  /** Base */
  base: string

  /** Quote Id */
  quote_id: number

  /** Quote */
  quote: string

  /** Base External Id */
  base_external_id: string

  /** Quote External Id */
  quote_external_id: string

  /** Base Nosis External Id */
  base_nosis_external_id: number

  /** Quote Nosis External Id */
  quote_nosis_external_id: number
}

export interface DepositWithdrawClientRequest {
  /** Amount */
  amount: number
}

export interface DepositWithdrawExternalRequest {
  /** Amount */
  amount: number

  /** Configuration Owner Id */
  configuration_owner_id: number
}

export interface GenerateTradingViewAlertRequest {
  /** Base */
  base: string

  /** An enumeration. */
  interval: CrossStrategyIntervalEnum

  /** An enumeration. */
  strategy: CrossSubStrategyEnum

  /** An enumeration. */
  event: TradingAlertEventEnum
}

/**
 * An enumeration.
 */
export enum GridStopStrategyEnum {
  LOSS_PERCENTAGE = 'LOSS_PERCENTAGE',
  QUOTE_LOSS_PERCENTAGE = 'QUOTE_LOSS_PERCENTAGE',
}

export interface GridStrategyDetail {
  /** Id */
  id: number
  currency_pair: CurrencyPairDetail

  /** An enumeration. */
  tranche_type: GridTrancheTypeEnum

  /** Tranche Value */
  tranche_value: number

  /** Step Percentage */
  step_percentage: number

  /** An enumeration. */
  stop_strategy: GridStopStrategyEnum

  /** Stop Strategy Value */
  stop_strategy_value: number

  /** Price For Start */
  price_for_start: number

  /** Lower Price Threshold */
  lower_price_threshold: number

  /** Price For Finish */
  price_for_finish: number
}

/**
 * An enumeration.
 */
export enum GridTrancheTypeEnum {
  BASE_CURRENCY = 'BASE_CURRENCY',
  QUOTE_CURRENCY = 'QUOTE_CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

export interface LoginRequest {
  /**
   * Email
   * @format email
   */
  email: string

  /** Password */
  password: string
}

export interface MasterOrderDetail {
  /** Id */
  id: number

  /** Copy Trading Master Portfolio Id */
  copy_trading_master_portfolio_id: number

  /** Status Id */
  status_id: number

  /** Status */
  status: string

  /** Price */
  price: number

  /** Side Id */
  side_id: number

  /** Side */
  side: string
  currency_pair: CurrencyPairDetail

  /** Amount */
  amount: number

  /** External Id */
  external_id?: string

  /**
   * Created At
   * @format date-time
   */
  created_at: string

  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string
}

/**
 * An enumeration.
 */
export enum MessageOrderSide {
  Sell = 'sell',
  Buy = 'buy',
}

export interface OrderDetail {
  /** Id */
  id: number

  /** Status Id */
  status_id: number

  /** An enumeration. */
  status: OrderStatusesEnum

  /** Fee */
  fee?: number

  /** Price */
  price: number

  /** Amount */
  amount: number

  /** Side Id */
  side_id: number

  /** An enumeration. */
  side: OrderSidesEnum

  /** Type Id */
  type_id: number

  /** An enumeration. */
  type: OrderTypesEnum
  currency_pair: CurrencyPairDetail

  /** External Id */
  external_id?: string

  /**
   * Created At
   * @format date-time
   */
  created_at?: string

  /**
   * Updated At
   * @format date-time
   */
  updated_at?: string

  /**
   * Cancellable Until
   * @format date-time
   */
  cancellable_until?: string

  /** Trading Configuration Id */
  trading_configuration_id: number

  /** Copy Trading Master Portfolio Id */
  copy_trading_master_portfolio_id?: number

  /** Master Order Id */
  master_order_id?: number

  /** Master Order External Id */
  master_order_external_id?: string

  /** Fail Reason */
  fail_reason?: string

  /** Trading Platform Id */
  trading_platform_id?: number
}

export interface OrderFinishEvent {
  /** Configuration Owner Id */
  configuration_owner_id: number

  /** Platform External Id */
  platform_external_id: string

  /** Id */
  id: string

  /** Text */
  text: string

  /** Status */
  status: string

  /** An enumeration. */
  type: TradingPlatformOrderTypesEnum

  /** Currency Pair */
  currency_pair: string

  /** An enumeration. */
  side: MessageOrderSide

  /** Amount */
  amount: string

  /** Price */
  price: string

  /** Fee */
  fee: string
}

export interface OrderPutEvent {
  /** Credential Id */
  credential_id: number

  /** Id */
  id: string

  /** Text */
  text: string

  /** Status */
  status: string

  /** An enumeration. */
  type: TradingPlatformOrderTypesEnum

  /** Currency Pair */
  currency_pair: string

  /** An enumeration. */
  side: MessageOrderSide

  /** Amount */
  amount: string

  /** Quote Amount */
  quote_amount: string

  /** Price */
  price: string

  /** Fee */
  fee: string
}

/**
 * An enumeration.
 */
export enum OrderSidesEnum {
  BUY = 'BUY',
  SELL = 'SELL',
}

/**
 * An enumeration.
 */
export enum OrderStatusesEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

/**
 * An enumeration.
 */
export enum OrderTypesEnum {
  Limit = 'limit',
  Market = 'market',
}

/**
 * An enumeration.
 */
export enum OrderingEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface PageCurrencyPairDetail {
  /** Total */
  total?: number

  /** Items */
  items: CurrencyPairDetail[]

  /** Page */
  page: number

  /** Size */
  size: number
}

export interface PageOrderDetail {
  /** Total */
  total?: number

  /** Items */
  items: OrderDetail[]

  /** Page */
  page: number

  /** Size */
  size: number
}

export interface PortfolioBalanceAssetDetail {
  /** Id */
  id: number

  /** Name */
  name: string

  /** Symbol */
  symbol: string

  /** External Id */
  external_id: string

  /** External Image Url */
  external_image_url: string

  /** Balance */
  balance?: number

  /** Locked */
  locked?: number

  /** Available */
  available: number

  /** Currency Asset Price */
  currency_asset_price?: number

  /** Balance As Cash */
  balance_as_cash?: number

  /** Pair Quote */
  pair_quote: string

  /** Pair Base */
  pair_base: string

  /** Currency Pair Id */
  currency_pair_id: number
}

export interface ProfitChartIndivisibleResponse {
  /** Time */
  time: number

  /** Value */
  value: number

  /** Profit Cash */
  profit_cash: number

  /** Profit Cash Period */
  profit_cash_period: number

  /** Profit Percent */
  profit_percent: number

  /** Profit Percent Period */
  profit_percent_period: number

  /** Time Period */
  time_period: string
}

export interface RefreshUserSessionResponse {
  /** Access Token */
  access_token: string

  /** Refresh Token */
  refresh_token: string
}

export interface ResetPasswordLinkRequest {
  /**
   * Email
   * @format email
   */
  email: string
}

export interface ResetPasswordRequest {
  /** Reset Token */
  reset_token: string

  /** Password */
  password: string
}

export interface SetTradingConfigurationStateRequest {
  /** An enumeration. */
  status: TradingConfigurationState

  /** Message */
  message?: string
}

export interface StopStrategyDetail {
  /** Id */
  id: number

  /** Name */
  name: string
}

export interface StrategyDetail {
  /** Id */
  id: number

  /** Name */
  name: string

  /** Is Active */
  is_active: boolean
}

export interface TradingAlertEvent {
  /** An enumeration. */
  event: TradingAlertEventEnum

  /** Currency Pair */
  currency_pair: string

  /** An enumeration. */
  trading_platform: TradingPlatformsAlertEnum

  /** Interval */
  interval: string

  /** An enumeration. */
  strategy: CrossSubStrategyEnum
}

/**
 * An enumeration.
 */
export enum TradingAlertEventEnum {
  GoldenCross = 'Golden cross',
  DeathCross = 'Death cross',
}

/**
 * An enumeration.
 */
export enum TradingConfigurationState {
  Start = 'start',
  Stop = 'stop',
}

/**
 * An enumeration.
 */
export enum TradingConfigurationStrategiesEnum {
  CROSS = 'CROSS',
  GRID = 'GRID',
  COPYTRADING = 'COPYTRADING',
}

export interface TradingPlatformDetail {
  /** Id */
  id: number

  /** Name */
  name: string

  /** External Id */
  external_id: string

  /** Url */
  url: string

  /** Default Quote Id */
  default_quote_id: number

  /** Default Quote External Id */
  default_quote_external_id?: number

  /** Default Quote Nosis External Id */
  default_quote_nosis_external_id?: string

  /** Default Quote Symbol */
  default_quote_symbol?: string

  /** External Image Url */
  external_image_url: string

  /** Connection User Guide Text */
  connection_user_guide_text?: string
}

/**
 * An enumeration.
 */
export enum TradingPlatformOrderSidesEnum {
  Buy = 'buy',
  Sell = 'sell',
}

/**
 * An enumeration.
 */
export enum TradingPlatformOrderTypesEnum {
  Limit = 'limit',
  Market = 'market',
}

/**
 * An enumeration.
 */
export enum TradingPlatformsAlertEnum {
  GateIo = 'Gate.io',
}

export interface TrancheTypeDetail {
  /** Id */
  id: number

  /** Name */
  name: string
}

export interface UpdateConfigurationRequest {
  /** Name */
  name?: string
  grid_strategy_details?: UpdateGridStrategyRequest
  cross_strategy_details?: UpdateCrossStrategyRequest
}

export interface UpdateCrossStrategyRequest {
  /** An enumeration. */
  interval?: CrossStrategyIntervalEnum

  /** Sub Strategy Id */
  sub_strategy_id?: number
}

export interface UpdateGridStrategyRequest {
  /** Tranche Type Id */
  tranche_type_id?: number

  /** Tranche Value */
  tranche_value?: number

  /** Step Percentage */
  step_percentage?: number

  /** Stop Strategy Id */
  stop_strategy_id?: number

  /** Stop Strategy Value */
  stop_strategy_value?: number

  /** Price For Start */
  price_for_start?: number

  /** Lower Price Threshold */
  lower_price_threshold?: number

  /** Price For Finish */
  price_for_finish?: number
}

export interface UpdateUserRequest {
  /** Current Password */
  current_password?: string

  /** New Password */
  new_password?: string

  /** First Name */
  first_name?: string

  /** Last Name */
  last_name?: string
}

export interface UserDetail {
  /** Id */
  id: number

  /** Email */
  email: string

  /** Configuration Owner Id */
  configuration_owner_id: number

  /** First Name */
  first_name: string

  /** Last Name */
  last_name: string
}

export interface UserSessionResponse {
  user: UserDetail

  /** Access Token */
  access_token: string

  /** Refresh Token */
  refresh_token: string
}

export interface ValidationError {
  /** Location */
  loc: string[]

  /** Message */
  msg: string

  /** Error Type */
  type: string
}

export interface WithdrawalSuccededApiConsumerEventData {
  /** Trading Configuration Id */
  trading_configuration_id: number

  /** Amount */
  amount: number

  /** Withdraw Transaction Id */
  withdraw_transaction_id: number
}

export interface GetConfigurationOwnerCredentialsV1TradingExternalCredentialsBalancesGetParams {
  /** Owner Id */
  owner_id: number
}

export interface GetConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdBalanceGetParams {
  /** Owner Id */
  owner_id: number

  /** Credential Id */
  credentialId: number
}

export interface DeleteConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdDeleteParams {
  /** Owner Id */
  owner_id: number

  /** Credential Id */
  credentialId: number
}

export interface S2SGetConfigurationOwnerExchangeCredentialV1TradingS2SCredentialsSecretsGetParams {
  /** Owner Id */
  owner_id: number

  /** Trading Platform External Id */
  trading_platform_external_id: string
}

export interface GetPaginatedOrdersV1TradingClientOrdersGetParams {
  /**
   * Page
   * @min 1
   */
  page?: number

  /**
   * Size
   * @min 1
   * @max 100
   */
  size?: number

  /** Statuses */
  statuses?: string

  /** Configuration Id */
  configuration_id?: number
  ordering?: OrderingEnum
}

export interface S2SGetConfigurationsV1TradingS2STradingConfigurationsGetParams {
  /** Status */
  status: string
}

export interface GetCurrencyPairsV1AssetsClientCurrencyPairsGetParams {
  /**
   * Page
   * @min 1
   */
  page?: number

  /**
   * Size
   * @min 1
   * @max 100
   */
  size?: number

  /** Sub Strategy Id */
  sub_strategy_id?: number

  /** An enumeration. */
  interval?: CrossStrategyIntervalEnum

  /** Platform Id */
  platform_id: number

  /** Base Search */
  base_search?: string
}

export interface GetConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGetParams {
  /**
   * Page
   * @min 1
   */
  page?: number

  /**
   * Size
   * @min 1
   * @max 100
   */
  size?: number

  /** Date From */
  date_from?: number

  /** Date To */
  date_to?: number

  /** Configuration Id */
  configurationId: number
}

export interface GetUserProfitChartV1ChartsClientProfitChartsUserProfitsGetParams {
  /**
   * Page
   * @min 1
   */
  page?: number

  /**
   * Size
   * @min 1
   * @max 100
   */
  size?: number

  /** Date From */
  date_from?: number

  /** Date To */
  date_to?: number
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (securityData: SecurityDataType | null) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title The Bot
 * @version v1
 *
 *             Bot staging API.
 *
 *             Interface notes:
 *             Date format: ISO 8601
 *             Route prefixes:
 *             /client - API exposed to frontend
 *             /external - API intended to be used by external services
 *             /s2s - internal API
 *             Route format:
 *             /version/module/auth_type/resource
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * @description Login a User
     *
     * @tags User Sessions: Auth Client
     * @name CreateSessionV1AuthClientUserSessionsPost
     * @summary Create Session
     * @request POST:/v1/auth/client/user-sessions
     */
    createSessionV1AuthClientUserSessionsPost: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<UserSessionResponse, HTTPValidationError>({
        path: `/v1/auth/client/user-sessions`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Sessions: Auth Client
     * @name RefreshSessionV1AuthClientUserSessionsRefreshPost
     * @summary Refresh Session
     * @request POST:/v1/auth/client/user-sessions/refresh
     */
    refreshSessionV1AuthClientUserSessionsRefreshPost: (params: RequestParams = {}) =>
      this.request<RefreshUserSessionResponse, void>({
        path: `/v1/auth/client/user-sessions/refresh`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Sessions: Auth Client
     * @name SendResetPasswordLetterV1AuthClientUserSessionsPasswordResetLettersPost
     * @summary Send Reset Password Letter
     * @request POST:/v1/auth/client/user-sessions/password-reset-letters
     */
    sendResetPasswordLetterV1AuthClientUserSessionsPasswordResetLettersPost: (
      data: ResetPasswordLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<UserSessionResponse, HTTPValidationError>({
        path: `/v1/auth/client/user-sessions/password-reset-letters`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Sessions: Auth Client
     * @name ResetPasswordV1AuthClientUserSessionsPasswordsPost
     * @summary Reset Password
     * @request POST:/v1/auth/client/user-sessions/passwords
     */
    resetPasswordV1AuthClientUserSessionsPasswordsPost: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<UserSessionResponse, HTTPValidationError>({
        path: `/v1/auth/client/user-sessions/passwords`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users: Users Client
     * @name CreateUserV1UsersClientUsersPost
     * @summary Create User
     * @request POST:/v1/users/client/users
     */
    createUserV1UsersClientUsersPost: (data: CreateUserRequest, params: RequestParams = {}) =>
      this.request<UserSessionResponse, HTTPValidationError>({
        path: `/v1/users/client/users`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users: Users Client
     * @name GetCurrentUserV1UsersClientUsersCurrentGet
     * @summary [User Auth] Get Current User
     * @request GET:/v1/users/client/users/current
     */
    getCurrentUserV1UsersClientUsersCurrentGet: (params: RequestParams = {}) =>
      this.request<UserDetail, any>({
        path: `/v1/users/client/users/current`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users: Users Client
     * @name UpdateCurrentUserV1UsersClientUsersCurrentPatch
     * @summary [User Auth] Update Current User
     * @request PATCH:/v1/users/client/users/current
     */
    updateCurrentUserV1UsersClientUsersCurrentPatch: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<UserDetail, HTTPValidationError>({
        path: `/v1/users/client/users/current`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create ConfigurationOwner
     *
     * @tags Configuration Owners: Trading External
     * @name CreateConfigurationOwnerV1TradingExternalConfigurationOwnersPost
     * @summary [API Key Auth] Create Configuration Owner
     * @request POST:/v1/trading/external/configuration-owners
     */
    createConfigurationOwnerV1TradingExternalConfigurationOwnersPost: (
      data: CreateConfigurationOwnerRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationOwnerDetail, HTTPValidationError>({
        path: `/v1/trading/external/configuration-owners`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Credential for User
     *
     * @tags Credentials: Trading Client
     * @name CreateUserCredentialV1TradingClientCredentialsPost
     * @summary [User Auth] Create User Credential
     * @request POST:/v1/trading/client/credentials
     */
    createUserCredentialV1TradingClientCredentialsPost: (data: CreateUserCredentialRequest, params: RequestParams = {}) =>
      this.request<CredentialDetail, HTTPValidationError>({
        path: `/v1/trading/client/credentials`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Credentials with Balances
     *
     * @tags Credentials: Trading Client
     * @name GetUserCredentialsV1TradingClientCredentialsBalancesGet
     * @summary [User Auth] Get User Credentials
     * @request GET:/v1/trading/client/credentials/balances
     */
    getUserCredentialsV1TradingClientCredentialsBalancesGet: (params: RequestParams = {}) =>
      this.request<CredentialWithBalanceDetail[], any>({
        path: `/v1/trading/client/credentials/balances`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Credential with Balances
     *
     * @tags Credentials: Trading Client
     * @name GetUserCredentialV1TradingClientCredentialsCredentialIdBalanceGet
     * @summary [User Auth] Get User Credential
     * @request GET:/v1/trading/client/credentials/{credential_id}/balance
     */
    getUserCredentialV1TradingClientCredentialsCredentialIdBalanceGet: (credentialId: number, params: RequestParams = {}) =>
      this.request<CredentialWithBalanceDetail, HTTPValidationError>({
        path: `/v1/trading/client/credentials/${credentialId}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Credential
     *
     * @tags Credentials: Trading Client
     * @name DeleteUserCredentialV1TradingClientCredentialsCredentialIdDelete
     * @summary [User Auth] Delete User Credential
     * @request DELETE:/v1/trading/client/credentials/{credential_id}
     */
    deleteUserCredentialV1TradingClientCredentialsCredentialIdDelete: (credentialId: number, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/client/credentials/${credentialId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create Credential for ConfigurationOwner
     *
     * @tags Credentials: Trading External
     * @name CreateConfigurationOwnerCredentialV1TradingExternalCredentialsPost
     * @summary [API Key Auth] Create Configuration Owner Credential
     * @request POST:/v1/trading/external/credentials
     */
    createConfigurationOwnerCredentialV1TradingExternalCredentialsPost: (
      data: CreateConfigurationOwnerCredentialRequest,
      params: RequestParams = {}
    ) =>
      this.request<CredentialDetail, HTTPValidationError>({
        path: `/v1/trading/external/credentials`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Credential with Balances
     *
     * @tags Credentials: Trading External
     * @name GetConfigurationOwnerCredentialsV1TradingExternalCredentialsBalancesGet
     * @summary [API Key Auth] Get Configuration Owner Credentials
     * @request GET:/v1/trading/external/credentials/balances
     */
    getConfigurationOwnerCredentialsV1TradingExternalCredentialsBalancesGet: (
      query: GetConfigurationOwnerCredentialsV1TradingExternalCredentialsBalancesGetParams,
      params: RequestParams = {}
    ) =>
      this.request<CredentialWithBalanceDetail[], HTTPValidationError>({
        path: `/v1/trading/external/credentials/balances`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Credential with Balances
     *
     * @tags Credentials: Trading External
     * @name GetConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdBalanceGet
     * @summary [API Key Auth] Get Configuration Owner Credential
     * @request GET:/v1/trading/external/credentials/{credential_id}/balance
     */
    getConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdBalanceGet: (
      { credentialId, ...query }: GetConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdBalanceGetParams,
      params: RequestParams = {}
    ) =>
      this.request<CredentialWithBalanceDetail, HTTPValidationError>({
        path: `/v1/trading/external/credentials/${credentialId}/balance`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete Credential
     *
     * @tags Credentials: Trading External
     * @name DeleteConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdDelete
     * @summary [API Key Auth] Delete Configuration Owner Credential
     * @request DELETE:/v1/trading/external/credentials/{credential_id}
     */
    deleteConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdDelete: (
      { credentialId, ...query }: DeleteConfigurationOwnerCredentialV1TradingExternalCredentialsCredentialIdDeleteParams,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/external/credentials/${credentialId}`,
        method: 'DELETE',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description S2S Get Credential for exchange_id
     *
     * @tags Credentials: Trading S2S
     * @name S2SGetConfigurationOwnerExchangeCredentialV1TradingS2SCredentialsSecretsGet
     * @summary [S2S Auth] S2S Get Configuration Owner Exchange Credential
     * @request GET:/v1/trading/s2s/credentials/secrets
     */
    s2SGetConfigurationOwnerExchangeCredentialV1TradingS2SCredentialsSecretsGet: (
      query: S2SGetConfigurationOwnerExchangeCredentialV1TradingS2SCredentialsSecretsGetParams,
      params: RequestParams = {}
    ) =>
      this.request<CredentialSecretDetail, HTTPValidationError>({
        path: `/v1/trading/s2s/credentials/secrets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description S2S Get Credential for portfolio
     *
     * @tags Credentials: Trading S2S
     * @name S2SGetPortfolioCredentialV1TradingS2SCredentialsPortfolioPortfolioIdSecretsGet
     * @summary [S2S Auth] S2S Get Portfolio Credential
     * @request GET:/v1/trading/s2s/credentials/portfolio/{portfolio_id}/secrets
     */
    s2SGetPortfolioCredentialV1TradingS2SCredentialsPortfolioPortfolioIdSecretsGet: (
      portfolioId: number,
      params: RequestParams = {}
    ) =>
      this.request<CredentialSecretDetail, HTTPValidationError>({
        path: `/v1/trading/s2s/credentials/portfolio/${portfolioId}/secrets`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description S2S Break Credential
     *
     * @tags Credentials: Trading S2S
     * @name S2SBreakCredentialV1TradingS2SCredentialsCredentialIdBreakPut
     * @summary [S2S Auth] S2S Break Credential
     * @request PUT:/v1/trading/s2s/credentials/{credential_id}/break
     */
    s2SBreakCredentialV1TradingS2SCredentialsCredentialIdBreakPut: (
      credentialId: number,
      data: BreakCredentialRequest,
      params: RequestParams = {}
    ) =>
      this.request<CredentialDetail, HTTPValidationError>({
        path: `/v1/trading/s2s/credentials/${credentialId}/break`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name CreateTradingConfigurationV1TradingExternalTradingConfigurationsPost
     * @summary [API Key Auth] Create Trading Configuration
     * @request POST:/v1/trading/external/trading-configurations
     */
    createTradingConfigurationV1TradingExternalTradingConfigurationsPost: (
      data: CreateConfigurationRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name GetTradingConfigurationV1TradingExternalTradingConfigurationsConfigurationIdGet
     * @summary [API Key Auth] Get Trading Configuration
     * @request GET:/v1/trading/external/trading-configurations/{configuration_id}
     */
    getTradingConfigurationV1TradingExternalTradingConfigurationsConfigurationIdGet: (
      configurationId: number,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations/${configurationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name DeleteTradingConfigurationV1TradingExternalTradingConfigurationsConfigurationIdDelete
     * @summary [API Key Auth] Delete Trading Configuration
     * @request DELETE:/v1/trading/external/trading-configurations/{configuration_id}
     */
    deleteTradingConfigurationV1TradingExternalTradingConfigurationsConfigurationIdDelete: (
      configurationId: number,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations/${configurationId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Set status for TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name SetTradingConfigurationStatusV1TradingExternalTradingConfigurationsConfigurationIdStatusPut
     * @summary [API Key Auth] Set Trading Configuration Status
     * @request PUT:/v1/trading/external/trading-configurations/{configuration_id}/status
     */
    setTradingConfigurationStatusV1TradingExternalTradingConfigurationsConfigurationIdStatusPut: (
      configurationId: number,
      data: SetTradingConfigurationStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations/${configurationId}/status`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Invest Cash to TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name TradingConfigurationInvestV1TradingExternalTradingConfigurationsConfigurationIdDepositPost
     * @summary [API Key Auth] Trading Configuration Invest
     * @request POST:/v1/trading/external/trading-configurations/{configuration_id}/deposit
     */
    tradingConfigurationInvestV1TradingExternalTradingConfigurationsConfigurationIdDepositPost: (
      configurationId: number,
      data: DepositWithdrawExternalRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations/${configurationId}/deposit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Withdraw Cash from TradingConfiguration
     *
     * @tags Trading Configurations: Trading External
     * @name TradingConfigurationWithdrawV1TradingExternalTradingConfigurationsConfigurationIdWithdrawPost
     * @summary [API Key Auth] Trading Configuration Withdraw
     * @request POST:/v1/trading/external/trading-configurations/{configuration_id}/withdraw
     */
    tradingConfigurationWithdrawV1TradingExternalTradingConfigurationsConfigurationIdWithdrawPost: (
      configurationId: number,
      data: DepositWithdrawExternalRequest,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/external/trading-configurations/${configurationId}/withdraw`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trading Strategies: Trading Client
     * @name GetStrategiesAvailableToClientV1TradingClientStrategiesGet
     * @summary [User Auth] Get Strategies Available To Client
     * @request GET:/v1/trading/client/strategies
     */
    getStrategiesAvailableToClientV1TradingClientStrategiesGet: (params: RequestParams = {}) =>
      this.request<StrategyDetail[], any>({
        path: `/v1/trading/client/strategies`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get user's trading configurations
     *
     * @tags Trading Configurations: Trading Client
     * @name GetUserTradingConfigurationsV1TradingClientTradingConfigurationsGet
     * @summary [User Auth] Get User Trading Configurations
     * @request GET:/v1/trading/client/trading-configurations
     */
    getUserTradingConfigurationsV1TradingClientTradingConfigurationsGet: (params: RequestParams = {}) =>
      this.request<ConfigurationDetail[], any>({
        path: `/v1/trading/client/trading-configurations`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create TradingConfiguration
     *
     * @tags Trading Configurations: Trading Client
     * @name CreateTradingConfigurationV1TradingClientTradingConfigurationsPost
     * @summary [User Auth] Create Trading Configuration
     * @request POST:/v1/trading/client/trading-configurations
     */
    createTradingConfigurationV1TradingClientTradingConfigurationsPost: (
      data: CreateUserConfigurationRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get trading configuration
     *
     * @tags Trading Configurations: Trading Client
     * @name GetUserTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdGet
     * @summary [User Auth] Get User Trading Configuration
     * @request GET:/v1/trading/client/trading-configurations/{configuration_id}
     */
    getUserTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdGet: (
      configurationId: number,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete TradingConfiguration
     *
     * @tags Trading Configurations: Trading Client
     * @name DeleteTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdDelete
     * @summary [User Auth] Delete Trading Configuration
     * @request DELETE:/v1/trading/client/trading-configurations/{configuration_id}
     */
    deleteTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdDelete: (
      configurationId: number,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update configuration
     *
     * @tags Trading Configurations: Trading Client
     * @name UpdateConfigurationV1TradingClientTradingConfigurationsConfigurationIdPatch
     * @summary [User Auth] Update Configuration
     * @request PATCH:/v1/trading/client/trading-configurations/{configuration_id}
     */
    updateConfigurationV1TradingClientTradingConfigurationsConfigurationIdPatch: (
      configurationId: number,
      data: UpdateConfigurationRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Set status for TradingConfiguration
     *
     * @tags Trading Configurations: Trading Client
     * @name SetTradingConfigurationStatusV1TradingClientTradingConfigurationsConfigurationIdStatusPut
     * @summary [User Auth] Set Trading Configuration Status
     * @request PUT:/v1/trading/client/trading-configurations/{configuration_id}/status
     */
    setTradingConfigurationStatusV1TradingClientTradingConfigurationsConfigurationIdStatusPut: (
      configurationId: number,
      data: SetTradingConfigurationStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}/status`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deposit Cash to TradingConfiguration
     *
     * @tags Trading Configurations: Trading Client
     * @name TradingConfigurationDepositV1TradingClientTradingConfigurationsConfigurationIdDepositPost
     * @summary [User Auth] Trading Configuration Deposit
     * @request POST:/v1/trading/client/trading-configurations/{configuration_id}/deposit
     */
    tradingConfigurationDepositV1TradingClientTradingConfigurationsConfigurationIdDepositPost: (
      configurationId: number,
      data: DepositWithdrawClientRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}/deposit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Withdraw Cash from TradingConfiguration
     *
     * @tags Trading Configurations: Trading Client
     * @name TradingConfigurationWithdrawV1TradingClientTradingConfigurationsConfigurationIdWithdrawPost
     * @summary [User Auth] Trading Configuration Withdraw
     * @request POST:/v1/trading/client/trading-configurations/{configuration_id}/withdraw
     */
    tradingConfigurationWithdrawV1TradingClientTradingConfigurationsConfigurationIdWithdrawPost: (
      configurationId: number,
      data: DepositWithdrawClientRequest,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/client/trading-configurations/${configurationId}/withdraw`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders: Trading Client
     * @name GetPaginatedOrdersV1TradingClientOrdersGet
     * @summary [User Auth] Get Paginated Orders
     * @request GET:/v1/trading/client/orders
     */
    getPaginatedOrdersV1TradingClientOrdersGet: (
      query: GetPaginatedOrdersV1TradingClientOrdersGetParams,
      params: RequestParams = {}
    ) =>
      this.request<PageOrderDetail, HTTPValidationError>({
        path: `/v1/trading/client/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Endpoint used for UI test porpuses. Creates a mocked fake order in specifeid configuration, updates client by websockets.              After 10 seconds closes this order. For each state change emits websocket events
     *
     * @tags Orders: Trading Client
     * @name CreateOrderV1TradingClientOrdersMockedPost
     * @summary [User Auth] Create Order
     * @request POST:/v1/trading/client/orders/mocked
     */
    createOrderV1TradingClientOrdersMockedPost: (data: CreateMockOrderRequest, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/client/orders/mocked`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order Events: Trading S2S
     * @name HandleOrderFinishEventV1TradingS2SOrderEventsFinishPost
     * @summary [S2S Auth] Handle Order Finish Event
     * @request POST:/v1/trading/s2s/order-events/finish
     */
    handleOrderFinishEventV1TradingS2SOrderEventsFinishPost: (data: OrderFinishEvent, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/s2s/order-events/finish`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order Events: Trading S2S
     * @name HandleOrderPutEventV1TradingS2SOrderEventsPutPost
     * @summary [S2S Auth] Handle Order Put Event
     * @request POST:/v1/trading/s2s/order-events/put
     */
    handleOrderPutEventV1TradingS2SOrderEventsPutPost: (data: OrderPutEvent, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/s2s/order-events/put`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update configuration status(start)
     *
     * @tags Configurations: Trading S2S
     * @name S2SUpdateStatusV1TradingS2STradingConfigurationsConfigurationIdStatusPut
     * @summary [S2S Auth] S2S Update Status
     * @request PUT:/v1/trading/s2s/trading-configurations/{configuration_id}/status
     */
    s2SUpdateStatusV1TradingS2STradingConfigurationsConfigurationIdStatusPut: (
      configurationId: number,
      data: SetTradingConfigurationStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/s2s/trading-configurations/${configurationId}/status`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get trading configurations
     *
     * @tags Configurations: Trading S2S
     * @name S2SGetConfigurationsV1TradingS2STradingConfigurationsGet
     * @summary [S2S Auth] S2S Get Configurations
     * @request GET:/v1/trading/s2s/trading-configurations
     */
    s2SGetConfigurationsV1TradingS2STradingConfigurationsGet: (
      query: S2SGetConfigurationsV1TradingS2STradingConfigurationsGetParams,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationSimpleDetail[], HTTPValidationError>({
        path: `/v1/trading/s2s/trading-configurations`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get trading configurations by id
     *
     * @tags Configurations: Trading S2S
     * @name S2SGetConfigurationByOrderV1TradingS2STradingConfigurationsConfigurationIdGet
     * @summary [S2S Auth] S2S Get Configuration By Order
     * @request GET:/v1/trading/s2s/trading-configurations/{configuration_id}
     */
    s2SGetConfigurationByOrderV1TradingS2STradingConfigurationsConfigurationIdGet: (
      configurationId: number,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationDetail, HTTPValidationError>({
        path: `/v1/trading/s2s/trading-configurations/${configurationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Maintenance: Trading S2S
     * @name StartMaintenanceV1TradingS2SMaintenanceStartPost
     * @summary [S2S Auth] Start Maintenance
     * @request POST:/v1/trading/s2s/maintenance/start
     */
    startMaintenanceV1TradingS2SMaintenanceStartPost: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/v1/trading/s2s/maintenance/start`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Maintenance: Trading S2S
     * @name StopMaintenanceV1TradingS2SMaintenanceStopPost
     * @summary [S2S Auth] Stop Maintenance
     * @request POST:/v1/trading/s2s/maintenance/stop
     */
    stopMaintenanceV1TradingS2SMaintenanceStopPost: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/v1/trading/s2s/maintenance/stop`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Configuration Events: Trading S2S
     * @name HandleConfigurationStartEventV1TradingS2SConfigurationEventsStartPost
     * @summary [S2S Auth] Handle Configuration Start Event
     * @request POST:/v1/trading/s2s/configuration-events/start
     */
    handleConfigurationStartEventV1TradingS2SConfigurationEventsStartPost: (
      data: ConfigurationStartEvent,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/s2s/configuration-events/start`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Configuration Events: Trading S2S
     * @name HandleConfigurationStopEventV1TradingS2SConfigurationEventsStopPost
     * @summary [S2S Auth] Handle Configuration Stop Event
     * @request POST:/v1/trading/s2s/configuration-events/stop
     */
    handleConfigurationStopEventV1TradingS2SConfigurationEventsStopPost: (
      data: ConfigurationStopEvent,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/trading/s2s/configuration-events/stop`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get trading platforms supported by the Nosis Bot
     *
     * @tags Trading Platforms: Trading Platforms External
     * @name GetTradingPlatformsV1TradingPlatformsExternalTradingPlatformsGet
     * @summary [API Key Auth] Get Trading Platforms
     * @request GET:/v1/trading-platforms/external/trading-platforms
     */
    getTradingPlatformsV1TradingPlatformsExternalTradingPlatformsGet: (params: RequestParams = {}) =>
      this.request<TradingPlatformDetail[], any>({
        path: `/v1/trading-platforms/external/trading-platforms`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns IPs which can be used to bind it to trading platform API keys
     *
     * @tags Trading Platforms: Server IPs External
     * @name GetServerIpsV1TradingPlatformsExternalServerIpsGet
     * @summary [API Key Auth] Get Server Ips
     * @request GET:/v1/trading-platforms/external/server-ips
     */
    getServerIpsV1TradingPlatformsExternalServerIpsGet: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/v1/trading-platforms/external/server-ips`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get trading platforms supported by the Nosis Bot
     *
     * @tags Trading Platforms: Trading Platforms Client
     * @name GetTradingPlatformsV1TradingPlatformsClientTradingPlatformsGet
     * @summary [User Auth] Get Trading Platforms
     * @request GET:/v1/trading-platforms/client/trading-platforms
     */
    getTradingPlatformsV1TradingPlatformsClientTradingPlatformsGet: (params: RequestParams = {}) =>
      this.request<TradingPlatformDetail[], any>({
        path: `/v1/trading-platforms/client/trading-platforms`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns IP which can be used to bind it to trading platform API keys
     *
     * @tags Trading Platforms: Server IPs Client
     * @name GetServerIpsV1TradingPlatformsClientServerIpsGet
     * @summary [User Auth] Get Server Ips
     * @request GET:/v1/trading-platforms/client/server-ips
     */
    getServerIpsV1TradingPlatformsClientServerIpsGet: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/v1/trading-platforms/client/server-ips`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Currency Pairs: Assets Client
     * @name GetCurrencyPairsV1AssetsClientCurrencyPairsGet
     * @summary [User Auth] Get Currency Pairs
     * @request GET:/v1/assets/client/currency-pairs/
     */
    getCurrencyPairsV1AssetsClientCurrencyPairsGet: (
      query: GetCurrencyPairsV1AssetsClientCurrencyPairsGetParams,
      params: RequestParams = {}
    ) =>
      this.request<PageCurrencyPairDetail, HTTPValidationError>({
        path: `/v1/assets/client/currency-pairs/`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create CopyTrading Portfolios
     *
     * @tags Master Portfolios: Copy Trading External
     * @name CreateCopyTradingPortfolioV1CopyTradingExternalMasterPortfoliosPost
     * @summary [API Key Auth] Create Copy Trading Portfolio
     * @request POST:/v1/copy-trading/external/master-portfolios
     */
    createCopyTradingPortfolioV1CopyTradingExternalMasterPortfoliosPost: (
      data: CreateCopyTradingPortfolioRequest,
      params: RequestParams = {}
    ) =>
      this.request<CopyTradingPortfolioDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create CopyTrading Portfolio Credential
     *
     * @tags Master Portfolios: Copy Trading External
     * @name CreateCopyTradingPortfolioCredentialV1CopyTradingExternalMasterPortfoliosPortfolioIdCredentialPost
     * @summary [API Key Auth] Create Copy Trading Portfolio Credential
     * @request POST:/v1/copy-trading/external/master-portfolios/{portfolio_id}/credential
     */
    createCopyTradingPortfolioCredentialV1CopyTradingExternalMasterPortfoliosPortfolioIdCredentialPost: (
      portfolioId: any,
      data: CreateCopyTradingPortfolioCredentialRequest,
      params: RequestParams = {}
    ) =>
      this.request<CopyTradingPortfolioDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios/${portfolioId}/credential`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete CopyTrading Portfolio Credential
     *
     * @tags Master Portfolios: Copy Trading External
     * @name DeleteMasterPortfolioCredentialV1CopyTradingExternalMasterPortfoliosPortfolioIdCredentialDelete
     * @summary [API Key Auth] Delete Master Portfolio Credential
     * @request DELETE:/v1/copy-trading/external/master-portfolios/{portfolio_id}/credential
     */
    deleteMasterPortfolioCredentialV1CopyTradingExternalMasterPortfoliosPortfolioIdCredentialDelete: (
      portfolioId: number,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios/${portfolioId}/credential`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get CopyTrading Portfolios
     *
     * @tags Master Portfolios: Copy Trading External
     * @name GetMasterPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdGet
     * @summary [API Key Auth] Get Master Portfolio
     * @request GET:/v1/copy-trading/external/master-portfolios/{portfolio_id}
     */
    getMasterPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdGet: (
      portfolioId: number,
      params: RequestParams = {}
    ) =>
      this.request<CopyTradingPortfolioDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios/${portfolioId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get CopyTrading Portfolios
     *
     * @tags Master Portfolios: Copy Trading External
     * @name DeleteMasterPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdDelete
     * @summary [API Key Auth] Delete Master Portfolio
     * @request DELETE:/v1/copy-trading/external/master-portfolios/{portfolio_id}
     */
    deleteMasterPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdDelete: (
      portfolioId: number,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios/${portfolioId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create CopyTrading Portfolios
     *
     * @tags Master Portfolios: Copy Trading External
     * @name ChangeCopyTradingPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdPatch
     * @summary [API Key Auth] Change Copy Trading Portfolio
     * @request PATCH:/v1/copy-trading/external/master-portfolios/{portfolio_id}
     */
    changeCopyTradingPortfolioV1CopyTradingExternalMasterPortfoliosPortfolioIdPatch: (
      portfolioId: number,
      data: ChangeCopyTradingPortfolioRequest,
      params: RequestParams = {}
    ) =>
      this.request<CopyTradingPortfolioDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-portfolios/${portfolioId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create CopyTradingMaster Orders
     *
     * @tags Master Orders: Copy Trading External
     * @name CreateMasterOrderV1CopyTradingExternalMasterOrdersPost
     * @summary [API Key Auth] Create Master Order
     * @request POST:/v1/copy-trading/external/master-orders
     */
    createMasterOrderV1CopyTradingExternalMasterOrdersPost: (data: CreateMasterOrderRequest, params: RequestParams = {}) =>
      this.request<MasterOrderDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/master-orders`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Order
     *
     * @tags Copy Orders: Copy Trading External
     * @name GetOrderV1CopyTradingExternalCopyOrdersOrderIdGet
     * @summary [API Key Auth] Get Order
     * @request GET:/v1/copy-trading/external/copy-orders/{order_id}
     */
    getOrderV1CopyTradingExternalCopyOrdersOrderIdGet: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/copy-orders/${orderId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Cancel Orders
     *
     * @tags Copy Orders: Copy Trading External
     * @name CancelOrderV1CopyTradingExternalCopyOrdersOrderIdDelete
     * @summary [API Key Auth] Cancel Order
     * @request DELETE:/v1/copy-trading/external/copy-orders/{order_id}
     */
    cancelOrderV1CopyTradingExternalCopyOrdersOrderIdDelete: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderDetail, HTTPValidationError>({
        path: `/v1/copy-trading/external/copy-orders/${orderId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get CopyTrading Portfolios
     *
     * @tags Master Portfolios: Copy Trading External S2S
     * @name GetMasterPortfoliosV1CopyTradingS2SMasterPortfoliosGet
     * @summary [S2S Auth] Get Master Portfolios
     * @request GET:/v1/copy-trading/s2s/master-portfolios
     */
    getMasterPortfoliosV1CopyTradingS2SMasterPortfoliosGet: (params: RequestParams = {}) =>
      this.request<CopyTradingPortfolioSimpleDetail[], any>({
        path: `/v1/copy-trading/s2s/master-portfolios`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Api Consumer Events: Api Consumers External
     * @name GetConsumerEventV1ApiConsumersExternalEventsEventIdGet
     * @summary [API Key Auth] Get Consumer Event
     * @request GET:/v1/api-consumers/external/events/{event_id}
     */
    getConsumerEventV1ApiConsumersExternalEventsEventIdGet: (eventId: string, params: RequestParams = {}) =>
      this.request<ApiConsumerEvent, HTTPValidationError>({
        path: `/v1/api-consumers/external/events/${eventId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stop Strategies: Grid Client
     * @name GetStopStrategiesV1GridStrategyClientStopStrategiesGet
     * @summary [User Auth] Get Stop Strategies
     * @request GET:/v1/grid-strategy/client/stop-strategies
     */
    getStopStrategiesV1GridStrategyClientStopStrategiesGet: (params: RequestParams = {}) =>
      this.request<StopStrategyDetail[], any>({
        path: `/v1/grid-strategy/client/stop-strategies`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tranche Types: Grid Client
     * @name GetTrancheTypesV1GridStrategyClientTrancheTypesGet
     * @summary [User Auth] [User Auth] Get Tranche Types
     * @request GET:/v1/grid-strategy/client/tranche-types
     */
    getTrancheTypesV1GridStrategyClientTrancheTypesGet: (params: RequestParams = {}) =>
      this.request<TrancheTypeDetail[], any>({
        path: `/v1/grid-strategy/client/tranche-types`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all CrossSubStrategies
     *
     * @tags SubStrategy: Cross Client
     * @name GetSubStrategiesV1CrossClientSubStrategyGet
     * @summary Get Sub Strategies
     * @request GET:/v1/cross/client/sub-strategy/
     */
    getSubStrategiesV1CrossClientSubStrategyGet: (params: RequestParams = {}) =>
      this.request<CrossSubStrategyDetail[], any>({
        path: `/v1/cross/client/sub-strategy/`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate trading alert
     *
     * @tags TradingView Generate: Cross External
     * @name AlertGeneratorV1CrossExternalTradingViewGeneratePost
     * @summary Alert Generator
     * @request POST:/v1/cross/external/trading-view/generate
     */
    alertGeneratorV1CrossExternalTradingViewGeneratePost: (
      data: GenerateTradingViewAlertRequest,
      params: RequestParams = {}
    ) =>
      this.request<TradingAlertEvent, HTTPValidationError>({
        path: `/v1/cross/external/trading-view/generate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profit Charts: Charts Client
     * @name GetConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGet
     * @summary [User Auth] Get Configuration Profit Chart
     * @request GET:/v1/charts/client/profit-charts/configuration-profits/{configuration_id}
     */
    getConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGet: (
      {
        configurationId,
        ...query
      }: GetConfigurationProfitChartV1ChartsClientProfitChartsConfigurationProfitsConfigurationIdGetParams,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationProfitChartResponse, HTTPValidationError>({
        path: `/v1/charts/client/profit-charts/configuration-profits/${configurationId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profit Charts: Charts Client
     * @name GetUserProfitChartV1ChartsClientProfitChartsUserProfitsGet
     * @summary [User Auth] Get User Profit Chart
     * @request GET:/v1/charts/client/profit-charts/user-profits
     */
    getUserProfitChartV1ChartsClientProfitChartsUserProfitsGet: (
      query: GetUserProfitChartV1ChartsClientProfitChartsUserProfitsGetParams,
      params: RequestParams = {}
    ) =>
      this.request<ConfigurationProfitChartResponse, HTTPValidationError>({
        path: `/v1/charts/client/profit-charts/user-profits`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
}
