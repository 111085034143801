import React from 'react'
import CredentialsSetting from 'features/credentials/components/CredentialsSetting'
import UpdateUserForm from 'features/auth/components/UpdateUserForm'

const ProfileSettings = () => {
  return (
    <>
      <UpdateUserForm />
      <CredentialsSetting />
    </>
  )
}

export default ProfileSettings
