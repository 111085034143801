import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import styles from 'pages/ResetPasswordPage/styles.module.scss';
import Header from 'shared/components/Header';
import ResetPasswordForm from 'features/auth/components/ResetPasswordForm';

const ResetPasswordPage = (): ReactElement => {
  return (
    <Grid container className={styles.reset__page}>
      <Header logoName={'NOSIS TRADING BOT'} />
      <Grid container className={styles.reset__page__container}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Grid container className={styles.reset__page__sign_in__form__container}>
            <ResetPasswordForm />
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
