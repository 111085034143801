import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FloatInput from 'shared/components/FormComponents/FloatInput'
import { Box, Grid, Typography } from '@mui/material'
import styles from 'features/trading/components/ConfigurationWithdrawModal/WithdrawConfigurationForm/styles.module.scss'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import Loader from 'shared/components/Loader'
import { ConfigurationDetail, DepositWithdrawClientRequest, TradingConfigurationStrategiesEnum } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'

const WithdrawConfigurationForm = ({
  configuration,
  onSuccess,
}: {
  configuration: ConfigurationDetail
  onSuccess: () => void
}) => {
  const { withdrawCashFromTradingConfiguration, isUpdated } = useTradingConfigurations(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<DepositWithdrawClientRequest>()

  const configurationStrategyData =
    configuration.strategy === TradingConfigurationStrategiesEnum.GRID
      ? configuration.grid_strategy_configuration
      : configuration.cross_strategy_configuration

  const onSubmit = (data: DepositWithdrawClientRequest) => {
    if (data.amount) {
      setIsLoading(true)
      withdrawCashFromTradingConfiguration({ ...data, configurationId: configuration.id })
    } else {
      setError('amount', { message: 'Can not withdraw 0 USDT.' })
    }
  }

  useEffect(() => {
    if (isUpdated) {
      setIsLoading(false)
      onSuccess()
    }
  }, [isUpdated])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant={'inherit'} className={styles.trading__pair__label}>
            Amount
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'inherit'} className={styles.available__funds__label}>
            Available:{' '}
            {`${configuration?.balances?.total} ${configuration?.trading_platform?.default_quote_symbol ?? 'USDT'}`}
          </Typography>
        </Grid>
      </Grid>
      <FloatInput
        register={register('amount', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
          max: {
            value: configuration?.balances?.total ?? 0,
            message: 'Amount can not be higher than available funds.',
          },
        })}
        defaultValue={String(configuration?.balances?.total)}
        currencyLabel={'USDT'}
        fullwidth={true}
        placeholder={'100'}
        error={errors.amount?.message}
      />
      <Typography variant={'inherit'} className={styles.content__text}>
        {`By clicking Withdraw button all
        ${configurationStrategyData?.currency_pair.base} tokens within this
          trading configuration will be converted to USDT and specified USDT amount will be withdrawn
          from the trading configuration and added to your Available cash balance. This operation
          takes time and your configuration will not be available until it is done`}
      </Typography>
      {isLoading ? (
        <Box className={styles.loader__container}>
          <Loader />
        </Box>
      ) : (
        <SubmitButton text={'Withdraw'} fullWidth={true} />
      )}
    </form>
  )
}

export default WithdrawConfigurationForm
