import React, { ReactElement } from 'react';
import styles from 'shared/components/NavPageContainer/styles.module.scss';
import { Grid } from '@mui/material';
import Navbar from 'shared/components/Navbar';

type NavPageContainerParams = {
  tabIndex: number;
  children: ReactElement;
};

const NavPageContainer = ({ tabIndex, children }: NavPageContainerParams): ReactElement => {
  return (
    <Grid container className={styles.page}>
      <Grid item width={'300px'} xs={2.5}>
        <Navbar tabIndex={tabIndex} />
      </Grid>
      <Grid item className={styles.page__content} xs={9.5}>
        {children}
      </Grid>
    </Grid>
  );
};

export default NavPageContainer;
