import React from 'react'
import styles from 'shared/components/MissingCredentialsNotification/styles.module.scss'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useCredentials from 'features/credentials/hooks/useCredentials'

const MissingCredentialsNotification = () => {
  const { hasCredentials, isLoading } = useCredentials(false)
  const navigate = useNavigate()
  const navigateSettings = () => {
    navigate('/profile')
  }
  return (
    <div className={styles.container}>
      {!hasCredentials && !isLoading && (
        <div className={styles.navigate__section}>
          <p>Please provide your trading platform credentials</p>
          <div className={styles.navigate__button__container}>
            <Button className={styles.navigate__button} onClick={navigateSettings}>
              Provide
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MissingCredentialsNotification
