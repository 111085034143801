import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useTradingPlatforms from 'features/trading/hooks/useTradingPlatforms'
import useCredentials from 'features/credentials/hooks/useCredentials'
import Loader from 'shared/components/Loader'
import { CredentialWithBalanceDetail } from 'api/generated'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ModalPopup from 'shared/components/ModalPopup'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import CredentialsConnectForm from '../CredentialsConnectForm'
import styles from './styles.module.scss'
import { ReactComponent as AlertError } from 'common/icons/alert_error.svg'

const CredentialsView = () => {
  const { tradingPlatforms, isLoading: isLoadingTrading } = useTradingPlatforms()
  const {
    credentials,
    deleteUserCredentials,
    isLoading: isLoadingCredentials,
    isLoadingEdit,
    isUpdated,
  } = useCredentials(false)
  const { updateTradingInfo } = useTradingConfigurations(false)
  const [credentialsAll, setCredentialsAll] = useState<CredentialWithBalanceDetail[]>([])
  const isLoading = isLoadingTrading || isLoadingCredentials || isLoadingEdit
  const [showModalConnect, setShowModalConnect] = useState(false)
  const [credentialCurrent, setCredentialCurrent] = useState<
    CredentialWithBalanceDetail & { connection_user_guide_text?: string }
  >({} as CredentialWithBalanceDetail)

  useEffect(() => {
    if (tradingPlatforms && credentials) {
      setCredentialsAll(
        tradingPlatforms
          .map(i => {
            const cred = credentials.filter(c => c.trading_platform_id === i.id)[0]
            return {
              trading_platform_id: i.id,
              trading_platform_image_url: i.external_image_url,
              trading_platform_name: i.name,
              balances: cred?.balances,
              id: cred?.id,
              is_broken: cred?.is_broken,
              error_message: cred?.error_message,
              configuration_owner_id: cred?.configuration_owner_id,
              connection_user_guide_text: i.connection_user_guide_text,
            } as CredentialWithBalanceDetail & { connection_user_guide_text?: string }
          })
          .sort(
            (f, s) => (f.balances ? 10 / f.trading_platform_id : 100) - (s.balances ? 10 / s.trading_platform_id : 100)
          )
      )
    }
  }, [tradingPlatforms, credentials])

  const onDisconnect = (id: number, title: string) => (e: React.MouseEvent) => {
    const result = confirm('Are you sure you want to disconnect - ' + title + '?')
    if (result) {
      e.stopPropagation()
      deleteUserCredentials(id)
    }
  }

  useEffect(() => {
    if (isUpdated) {
      updateTradingInfo()
    }
  }, [isUpdated])

  const onOpenModalConnect = (cred: CredentialWithBalanceDetail) => (e: React.MouseEvent) => {
    e.stopPropagation()
    setCredentialCurrent(cred)
    setShowModalConnect(true)
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        credentialsAll.map(item => (
          <Box key={item.trading_platform_id} className={styles.content}>
            <Grid container spacing={2}>
              <Grid container className={styles.blockTradingPlatform}>
                {/* name trading */}
                <Grid item xs={10} className={styles.container__title}>
                  <img src={item.trading_platform_image_url} alt={item.trading_platform_name} className={styles.icon} />
                  <Typography variant={'inherit'} className={styles.platform_name}>
                    {item.trading_platform_name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {item.balances ? (
                    <Button
                      onClick={onDisconnect(item.id, item.trading_platform_name)}
                      variant={'text'}
                      sx={{ color: '#D63031', textTransform: 'none' }}
                    >
                      Disconnect
                    </Button>
                  ) : (
                    <Button
                      onClick={onOpenModalConnect(item)}
                      variant={'text'}
                      sx={{ color: '#0984E3', textTransform: 'none' }}
                    >
                      Connect <ArrowRightAltIcon />
                    </Button>
                  )}
                </Grid>
              </Grid>
              {item.is_broken && item.error_message ? (
                <Grid container className={styles.blockErrorMessage}>
                  <Grid item sx={{ paddingRight: '16px' }}>
                    <AlertError />
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Typography variant={'body2'} color={'#D63031'}>
                        Credential error: {item.error_message}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Typography className={styles.errorDescription} color={'#636E72'}>
                        Please disconnect this api, create a new one and reconnect to resume trading.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                item.balances && (
                  <Grid container className={styles.blockBalance}>
                    {/* balance */}
                    <Grid item xs={3}>
                      <Grid container>
                        <Grid item xs={12} className={styles.balance_name}>
                          Total balance
                        </Grid>
                        <Grid item xs={12} className={styles.balance_amount}>
                          {item.balances.total_balance.toFixed(2)}
                        </Grid>
                      </Grid>
                      {/*    */}
                    </Grid>
                    <Grid item xs={3}>
                      {/*  */}
                      <Grid container>
                        <Grid item xs={12} className={styles.balance_name}>
                          Available cash
                        </Grid>
                        <Grid item xs={12} className={styles.balance_amount}>
                          {item.balances.available.toFixed(2)}
                        </Grid>
                      </Grid>
                      {/*   */}
                    </Grid>
                    <Grid item xs={3}>
                      {/*  */}
                      <Grid container>
                        <Grid item xs={12} className={styles.balance_name}>
                          Reserved cash
                        </Grid>
                        <Grid item xs={12} className={styles.balance_amount}>
                          {item.balances.reserved.toFixed(2)}
                        </Grid>
                      </Grid>
                      {/*   */}
                    </Grid>
                    <Grid item xs={3}>
                      {/*  */}
                      <Grid container>
                        <Grid item xs={12} className={styles.balance_name}>
                          USDT balance
                        </Grid>
                        <Grid item xs={12} className={styles.balance_amount}>
                          {item?.balances?.total_cash_balance.toFixed(2)}
                        </Grid>
                      </Grid>
                      {/*   */}
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        ))
      )}
      <ModalPopup
        open={showModalConnect}
        title={`Connect ${credentialCurrent.trading_platform_name}`}
        setOpen={state => setShowModalConnect(state)}
        content={
          <CredentialsConnectForm credentialCurrent={credentialCurrent} onBack={() => setShowModalConnect(false)} />
        }
        iconTitle={
          <img
            src={credentialCurrent.trading_platform_image_url}
            alt={`Connect ${credentialCurrent.trading_platform_name}`}
            style={{ width: '32px', height: '32px' }}
          />
        }
        titleAlign={'left'}
      />
    </>
  )
}

export default CredentialsView
