import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import AppRoutes from 'routes'
import styles from './app.module.scss'
import useWebsockets from "features/trading/hooks/useWebsockets";

function App() {
  const { ws } = useWebsockets()

  return (
    <div className={styles.main}>
      <AppRoutes ws={ws} />
      <ToastContainer limit={3} position={toast.POSITION.BOTTOM_LEFT} className={'toast'} />
    </div>
  )
}

export default App
