export const configurationStatuses = {
  active: 'RUNNING',
  inActive: 'STOPPED',
  withdraw: 'WITHDRAW',
  maintenance: 'MAINTENANCE',
}
export const crossStrategyIntervals = {
  minutes15: '15m',
  hour: '1h',
  fourHours: '4h',
}
