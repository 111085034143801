import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import PrivateRoute from 'routes/PrivateRoute';
import NotFoundPage from 'pages/NotFoundPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ProfilePage from 'pages/ProfilePage';
import InfoPage from 'pages/InfoPage';
import ConfigurationsPage from 'pages/ConfigurationsPage';
import ConfigurationDetailPage from 'pages/ConfigurationDetailPage';
import { ROUTES } from 'constants/routes';
import { Socket } from 'socket.io-client';

type RouteType = {
  path: string;
  element: ReactElement;
};

const AppRoutes = ({ ws }: { ws?: Socket }): ReactElement => {
  const routes = {
    publicRoutes: [
      { path: ROUTES.login, element: <LoginPage /> },
      { path: ROUTES.forgotPassword, element: <ForgotPasswordPage /> },
      { path: ROUTES.resetPassword, element: <ResetPasswordPage /> },
    ],
    privateRoutes: [
      { path: ROUTES.homePage.path, element: <ConfigurationsPage tabIndex={1} ws={ws} /> },
      { path: ROUTES.profilePage.path, element: <ProfilePage tabIndex={2} /> },
      { path: ROUTES.infoPage.path, element: <InfoPage tabIndex={3} /> },
      {
        path: ROUTES.configurationDetail.path,
        element: <ConfigurationDetailPage tabIndex={0} ws={ws} />,
      },
    ],
  };

  return (
    <BrowserRouter>
      <Routes>
        {Object(routes.publicRoutes).map((route: RouteType, index: number) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {Object(routes.privateRoutes).map((route: RouteType, index: number) => (
          <Route key={index} path={route.path} element={<PrivateRoute />}>
            <Route path={route.path} element={route.element} />
          </Route>
        ))}

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
