import React, { ReactElement, useRef } from 'react';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Break from 'shared/components/Break';
import styles from 'shared/components/ModalConfirmPopup/styles.module.scss';

type ModalConfirmPopupParams = {
  open: boolean;
  setOpen: (state: boolean) => void;
  onConfirm: () => void;
  title: string;
  subTitle?: string;
};

const ModalConfirmPopup = ({
  open,
  setOpen,
  onConfirm,
  title,
  subTitle,
}: ModalConfirmPopupParams): ReactElement => {
  const ref = useRef();

  useOnClickOutside(ref, () => setOpen(false));

  const handleConfirm = (): void => {
    onConfirm();
    setOpen(false);
  };

  return (
    <Modal keepMounted={true} className={styles.modal__base} open={open}>
      <Grid container height={'100vh'} width={'100%'}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Grid container className={styles.modal__container}>
            <Box ref={ref} className={styles.modal__inner__container}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={8} className={styles.header__container}>
                  <Typography variant={'inherit'} className={styles.header__label}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Grid container className={styles.modal__container}>
                    <Grid item>
                      <CloseIcon
                        onClick={(): void => setOpen(false)}
                        className={styles.close__button}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Break margin={'5%'} />
              <Box className={styles.content__container}>
                <Typography className={styles.content__subtitle}>{subTitle}</Typography>
                <Box className={styles.button__container}>
                  <Button onClick={handleConfirm} className={styles.button}>
                    <Typography className={styles.button__text}>Confirm</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Modal>
  );
};

export default ModalConfirmPopup;
