import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { TrancheTypeDetail } from 'api/generated'
import { tradingTrancheTypesActions } from '../redux/tradingTrancheTypes/tradingTrancheTypes.slice'
import tradingTrancheTypesThunks from '../redux/tradingTrancheTypes/tradingTrancheTypes.thunks'

const useTradingTrancheTypes = () => {
  const trancheTypesState = (store: RootState): TrancheTypeDetail[] => store.tradingTrancheTypes.types
  const isLoadingState = (state: RootState): boolean => state.tradingTrancheTypes.isLoading
  const errorState = (state: RootState): string => state.tradingTrancheTypes.error
  const hasTrancheTypes = useAppSelector(
    createSelector(trancheTypesState, (item): boolean => !!item && item.length > 0)
  )
  const trancheTypes = useAppSelector(trancheTypesState)
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()

  const actions = {
    ...tradingTrancheTypesActions,
    ...tradingTrancheTypesThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getTrancheTypes } = actionsDispatch

  useEffect(() => {
    if (!hasTrancheTypes && !isLoading) getTrancheTypes()
  }, [hasTrancheTypes])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasTrancheTypes,
      trancheTypes,
      isLoading,
      error,
    }
  }, [hasTrancheTypes, trancheTypes, isLoading, error, dispatch])
}

export default useTradingTrancheTypes
