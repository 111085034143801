import React, { FC, ReactElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styles from 'shared/components/Header/styles.module.scss'

type HeaderProps = {
  links?: Array<FC>
  logoName: string
}

const Header = ({ logoName }: HeaderProps): ReactElement => {
  return (
    <Box className={styles.header__container}>
      <Grid container spacing={2} sx={{ verticalAlign: 'middle' }}>
        <Grid item>
          <Typography className={styles.header__logo} variant={'inherit'}>
            {logoName}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
