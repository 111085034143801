import { Stack, Typography } from '@mui/material';
import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type StatisticVariant = 'small' | 'medium';

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
  suffix?: React.ReactNode;

  spacing?: number;
  variant?: StatisticVariant;
  className?: string;
}

const defaultSpacing: Record<StatisticVariant, number> = {
  small: 0,
  medium: 1,
};

export const Statistic: React.FC<Props> = ({
  label,
  value,
  variant = 'medium',
  spacing = defaultSpacing[variant],
  suffix,
  className,
}) => {
  return (
    <Stack spacing={spacing} className={clsx(styles.wrapper, styles[variant], className)}>
      <Typography className={styles.label}>{label}</Typography>
      <Typography className={styles.value}>
        {value}
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </Typography>
    </Stack>
  );
};
