import React, { ReactElement, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';
import styles from 'shared/components/FormComponents/TextInput/styles.module.scss';
import passwordInputStyles from 'shared/components/FormComponents/PasswordInput/styles.module.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

type PasswordInputProps = {
  error?: string;
  id?: string;
  label: string;
  defaultValue?: string;
  register?: UseFormRegisterReturn;
  fullwidth?: boolean;
  disabled?: boolean;
  forgotMessage?: boolean;
  placeholder?: string;
  autoComplete?: string;
};

const PasswordInput = ({
  error = '',
  id,
  label,
  defaultValue,
  register,
  fullwidth = false,
  disabled = false,
  forgotMessage = false,
  placeholder = label,
  autoComplete = 'off',
}: PasswordInputProps): ReactElement => {
  const [hiddenPassword, setHiddenPassword] = useState(true);
  return (
    <Box className={styles.input__container}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant={'inherit'} className={styles.input__label}>
            {label}
          </Typography>
        </Grid>
        {forgotMessage && (
          <Grid item xs={6} className={passwordInputStyles.forgot__password__block}>
            <Link className={passwordInputStyles.forgot__password__text} to={ROUTES.forgotPassword}>
              Forgot password?
            </Link>
          </Grid>
        )}
      </Grid>
      <Box className={passwordInputStyles.input__container}>
        <TextField
          className={error ? passwordInputStyles.input__error : passwordInputStyles.input}
          id={id}
          InputProps={{ disableUnderline: true, sx: { paddingLeft: '10px' }, ...register }}
          required={true}
          placeholder={placeholder}
          variant={'standard'}
          name={label}
          defaultValue={defaultValue}
          disabled={disabled}
          fullWidth={fullwidth}
          color={'primary'}
          type={hiddenPassword ? 'password' : 'text'}
          autoComplete={autoComplete}
        />
        {hiddenPassword ? (
          <Box className={passwordInputStyles.password__visibility__container}>
            <VisibilityIcon
              className={passwordInputStyles.password__visibility__button}
              onClick={(): void => setHiddenPassword(!hiddenPassword)}
            />
          </Box>
        ) : (
          <Box className={passwordInputStyles.password__visibility__container}>
            <VisibilityOffIcon
              className={passwordInputStyles.password__visibility__button}
              onClick={(): void => setHiddenPassword(!hiddenPassword)}
            />
          </Box>
        )}
      </Box>
      {error && (
        <Typography className={styles.input__error_message} variant={'inherit'} color={'error'}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default PasswordInput;
