import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import { CurrencyPairDetail } from 'api/generated'
import tradingCurrencyPairsThunks from './tradingCurrencyPairs.thunks'
const { getCurrencyPairs } = tradingCurrencyPairsThunks
const { logout } = authThunks

export interface StrategiesInitialState {
  isLoading: boolean
  pairs: CurrencyPairDetail[]
  error: string
  total: number
  page: number
  size: number
}

const initialState: StrategiesInitialState = {
  isLoading: false,
  pairs: [],
  error: '',
  page: 1,
  size: 10,
  total: 0,
}

const tradingCurrencyPairsSlice = createSlice({
  name: 'tradingCurrencyPairs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(isPending(getCurrencyPairs), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isFulfilled(getCurrencyPairs), (state, action) => {
        state.pairs = action.payload.items
        state.total = action.payload.total ?? 0
        state.page = action.payload.page
        state.size = action.payload.size
        state.isLoading = false
      })
      .addMatcher(isRejected(getCurrencyPairs), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const tradingCurrencyPairsActions = tradingCurrencyPairsSlice.actions

export const { reducer: tradingCurrencyPairsReducer } = tradingCurrencyPairsSlice

export default tradingCurrencyPairsReducer
