import React, { ReactElement } from 'react';
import NavPageContainer from 'shared/components/NavPageContainer';
import PageContainer from 'shared/components/PageContainer';

const InfoPage = ({ tabIndex }: { tabIndex: number }): ReactElement => {
  return (
    <NavPageContainer tabIndex={tabIndex}>
      <PageContainer label={'About the BOT'}>
        <></>
      </PageContainer>
    </NavPageContainer>
  );
};

export default InfoPage;
