/**
 * When one refresh-token call is in progress, all other attempts will be waiting the first to be resolved
 * to reuse it's response
 */
import { api } from 'api';
import { AxiosResponse } from 'axios';

export class RefreshWaitingQueue {
  isRefreshing = false;
  refreshWaitingQueue: Array<{
    resolve: (value: unknown) => void;
    reject: (value: unknown) => void;
  }> = [];

  push(request: AxiosResponse) {
    localStorage.setItem('token_refreshed', 'false');
    return new Promise((resolve, reject) => {
      this.refreshWaitingQueue.push({ resolve, reject });
    })
      .then(token => {
        request.headers['Authorization'] = 'Bearer ' + token;
        return api(request);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  resolveQueued(accessToken: string) {
    for (const promise of this.refreshWaitingQueue) {
      promise.resolve(accessToken);
    }
    this.refreshWaitingQueue = [];
    localStorage.setItem('token_refreshed', 'true');
  }
}
