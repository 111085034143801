import React, { ReactElement } from 'react';
import styles from 'shared/components/FormComponents/CustomCheckBox/styles.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form';

type CustomCheckBoxParams = {
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  value: string | number;
  register?: UseFormRegisterReturn;
};

const CustomCheckBox = ({
  checked = false,
  onChange,
  disabled = false,
  value,
  register,
}: CustomCheckBoxParams): ReactElement => {
  return (
    <input
      {...register}
      type="checkbox"
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={styles.checkbox}
    />
  );
};

export default CustomCheckBox;
