import React, { ReactElement } from 'react'
import styles from 'features/trading/components/BlurredOrderTable/styles.module.scss'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

const BlurredOrderTable = ({
  headers,
  statusName = '',
}: {
  headers: Array<{
    label: string
    accessor: string
    styles?: (data?: unknown) => Record<string, string>
  }>
  statusName?: string
}): ReactElement => {
  const tableBlurredRows = [
    {
      key: 1,
    },
    {
      key: 2,
    },
    {
      key: 3,
    },
    {
      key: 4,
    },
    {
      key: 5,
    },
    {
      key: 6,
    },
  ]
  return (
    <>
      <Table className={styles.table}>
        <TableHead className={styles.table__heading}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell className={styles.table__header__cell} key={index}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.table__body}>
          {tableBlurredRows.map(row => (
            <TableRow key={row.key}>
              <TableCell>
                <Box
                  className={[
                    styles.blurred__type,
                    row.key % 2 === 0 ? styles.blurred__sell : styles.blurred__buy,
                  ].join(' ')}
                ></Box>
              </TableCell>
              <TableCell>
                <Box
                  className={row.key % 2 === 0 ? styles.blurred__configuration : styles.blurred__configuration__long}
                ></Box>
              </TableCell>
              <TableCell>
                <Box className={styles.blurred__asset}></Box>
              </TableCell>
              <TableCell>
                <Box className={styles.blurred__amount}></Box>
              </TableCell>
              <TableCell>
                <Box className={styles.blurred__price}></Box>
              </TableCell>
              {!(statusName === 'OPEN') && (
                <TableCell>
                  <Box className={styles.blurred__status}></Box>
                </TableCell>
              )}
              <TableCell>
                <Box className={styles.blurred__datetime}></Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box className={styles.no__data__container}>
        <Typography variant={'inherit'} className={styles.no__data__label}>
          {statusName === 'OPEN' ? 'There are no open orders at the moment' : 'There are no completed orders yet'}
        </Typography>
      </Box>
    </>
  )
}

export default BlurredOrderTable
