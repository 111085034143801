import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import CarouselCard from 'shared/components/Carousel/CarouselCard';
import styles from 'shared/components/Carousel/styles.module.scss';

type CarouselParams = {
  index: number;
  children: ReactElement[];
  activeCardsAmount: number;
};

const Carousel = ({ index, children, activeCardsAmount }: CarouselParams): ReactElement => {
  const cardSize = 93 / activeCardsAmount;
  return (
    <Box className={styles.carousel}>
      <Box
        className={styles.card__container}
        sx={{ transform: `translateX(-${index * cardSize}%)` }}
      >
        {children.map((childNode, nodeIndex) => (
          <CarouselCard size={cardSize} key={nodeIndex}>
            {childNode}
          </CarouselCard>
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
