import React, { ReactElement } from 'react'
import styles from 'pages/ProfilePage/styles.module.scss'
import { Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import PageContainer from 'shared/components/PageContainer'
import NavPageContainer from 'shared/components/NavPageContainer'
import ProfileSettings from 'features/auth/components/ProfileSettings'
import useAuth from 'features/auth/hooks/useAuth'

const ProfilePage = ({ tabIndex }: { tabIndex: number }): ReactElement => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleLogout = (): void => {
    logout()
    navigate('/login')
  }

  return (
    <NavPageContainer tabIndex={tabIndex}>
      <PageContainer
        label={'Profile settings'}
        tooltip={
          <Button onClick={handleLogout} className={styles.logout__button}>
            <LogoutIcon className={styles.logout__icon} /> Logout
          </Button>
        }
      >
        <ProfileSettings />
      </PageContainer>
    </NavPageContainer>
  )
}

export default ProfilePage
