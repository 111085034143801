import {
  LoginRequest,
  ResetPasswordLinkRequest,
  ResetPasswordRequest,
  UpdateUserRequest,
  UserDetail,
  UserSessionResponse,
} from 'api/generated'
import { generatedApi } from 'api/v1'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const login = createAsyncThunk<UserSessionResponse, LoginRequest>('auth/login', async (requestData, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.createSessionV1AuthClientUserSessionsPost(requestData)
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

// const refreshToken = createAsyncThunk<RefreshUserSessionResponse>('auth/refreshToken', async (_, thunkAPI) => {
//   try {
//     const { data } = await generatedApi.v1.refreshSessionV1AuthClientUserSessionsRefreshPost()
//     return data
//   } catch (e) {
//     return handleErrors(e as ApiError, thunkAPI)
//   }
// })

const updateUser = createAsyncThunk<UserDetail, UpdateUserRequest>('auth/updateUser', async (requestData, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.updateCurrentUserV1UsersClientUsersCurrentPatch(requestData)
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const resetPasswordLink = createAsyncThunk<UserSessionResponse, ResetPasswordLinkRequest>(
  'auth/resetPasswordLink',
  async (requestData, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.sendResetPasswordLetterV1AuthClientUserSessionsPasswordResetLettersPost(
        requestData
      )
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const resetPassword = createAsyncThunk<UserSessionResponse, ResetPasswordRequest>(
  'auth/resetPassword',
  async (requestData, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.resetPasswordV1AuthClientUserSessionsPasswordsPost(requestData)
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const logout = createAction<void>('auth/logout')

const authThunks = { logout, resetPassword, resetPasswordLink, updateUser, login }

export default authThunks
