import React, { ReactElement } from 'react'
import { Button, Typography } from '@mui/material'
import styles from 'shared/components/FormComponents/SubmitButton/styles.module.scss'

type SubmitButtonProps = {
  text: string
  fullWidth?: boolean
  disabled?: boolean
}

const SubmitButton = ({ text, fullWidth = false, disabled = false }: SubmitButtonProps): ReactElement => {
  return (
    <Button
      variant={'contained'}
      color={'primary'}
      type={'submit'}
      fullWidth={fullWidth}
      className={styles.button}
      disabled={disabled}
    >
      <Typography className={styles.button__text} variant={'inherit'}>
        {text}
      </Typography>
    </Button>
  )
}

export default SubmitButton
