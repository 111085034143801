import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { StrategyDetail } from 'api/generated'
import { tradingStrategiesActions } from '../redux/tradingStrategies/tradingStrategies.slice'
import tradingStrategiesThunks from '../redux/tradingStrategies/tradingStrategies.thunks'

const useTradingStrategies = () => {
  const strategiesState = (state: RootState): StrategyDetail[] => state.tradingStrategies.strategies
  const isLoadingState = (state: RootState): boolean => state.tradingStrategies.isLoading
  const errorState = (state: RootState): string => state.tradingStrategies.error

  const hasStrategies = useAppSelector(createSelector(strategiesState, (item): boolean => !!item && item.length > 0))
  const strategies = useAppSelector(strategiesState)
  const strategyIdByName = useCallback(
    (name: string) => strategies?.filter(i => i.name === name)?.map(i => i.id)[0] || 0,
    [strategies]
  )
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingStrategiesActions,
    ...tradingStrategiesThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getStrategies } = actionsDispatch

  useEffect(() => {
    if (!hasStrategies && !isLoading) getStrategies()
  }, [hasStrategies])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasStrategies,
      strategies,
      isLoading,
      error,
      strategyIdByName,
    }
  }, [hasStrategies, strategies, isLoading, error, dispatch, strategyIdByName])
}

export default useTradingStrategies
