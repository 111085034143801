import React from 'react';

const blockedKeyCodes = ['Minus', 'Equal'];

const preventKeys = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if (blockedKeyCodes.includes(e.code)) {
    e.preventDefault();
  }
};

export default preventKeys;
