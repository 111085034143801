import React, { ReactElement, useState, MouseEvent } from 'react'
import { Box, Button, Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { ConfigurationDetail, TradingConfigurationState, TradingConfigurationStrategiesEnum } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import styles from './styles.module.scss'
import ConfigurationActivationButton, { ButtonMode } from './ConfigurationActivationButton'
import { configurationStatuses } from 'constants/tradingConfigurations'
import Break from 'shared/components/Break'
import trancheTypeNames from 'constants/trancheType'
import stopStrategiesNames from 'constants/stopStrategies'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useNavigate } from 'react-router-dom'
import ModalConfirmPopup from 'shared/components/ModalConfirmPopup'
import ConfigurationDepositModal from 'features/trading/components/ConfigurationDepositModal'
import DeleteConfigurationModal from 'features/trading/components/DeleteConfigurationModal'
import ToolTip from 'shared/components/ToolTip'
import CreateUpdateConfigurationModal from 'features/trading/components/CreateUpdateConfigurationModal'
import CircleIcon from '@mui/icons-material/Circle'
import ConfigurationWithdrawModal from 'features/trading/components/ConfigurationWithdrawModal'

function createData(
  fieldName: string,
  fieldValue: number | string,
  fieldImage?: string
): { fieldName: string; fieldValue: number | string; fieldImage?: string } {
  return { fieldName, fieldValue, fieldImage }
}

const ConfigurationCard = ({
  configuration,
  setIndex,
}: {
  configuration: ConfigurationDetail
  setIndex: () => void
}): ReactElement => {
  const { changeStatusTradingConfiguration } = useTradingConfigurations(false)
  const [activationModalOpen, setActivationModalOpen] = useState(false)
  const [depositModalOpen, setDepositModalOpen] = useState(false)
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const navigate = useNavigate()
  const gridDetails = configuration.grid_strategy_configuration
  const crossDetails = configuration.cross_strategy_configuration

  const trancheLabel =
    gridDetails?.tranche_type === trancheTypeNames.baseCurrency
      ? gridDetails?.currency_pair?.base
      : gridDetails?.tranche_type === trancheTypeNames.quoteCurrency
      ? gridDetails?.currency_pair?.quote
      : '%'

  const generateRows = () => {
    const configurationStrategy =
      configuration.strategy === TradingConfigurationStrategiesEnum.CROSS
        ? configuration.cross_strategy_configuration
        : configuration.grid_strategy_configuration
    const rows = [
      createData(
        'Strategy',
        `${
          configuration.strategy === TradingConfigurationStrategiesEnum.CROSS
            ? configuration.cross_strategy_configuration?.sub_strategy.name
            : configuration.strategy
        }`
      ),
      createData(
        'Deposited',
        `${configuration.balances.deposited} ${
          configuration.trading_platform.default_quote_symbol?.toUpperCase() ?? 'USDT'
        }`
      ),
    ]
    if (configurationStrategy?.currency_pair?.base) {
      rows.push(
        createData(
          'Pair',
          `${configurationStrategy?.currency_pair?.base} - ${configurationStrategy?.currency_pair?.quote}`
        )
      )
    }
    switch (configuration.strategy) {
      case TradingConfigurationStrategiesEnum.CROSS:
        {
          rows.push(
            createData(
              'Platform',
              `${configuration.trading_platform.name ?? '---'}`,
              configuration.trading_platform.external_image_url
            ),
            createData('Interval', `${crossDetails?.interval}`)
          )
        }
        break
      default:
        {
          if (gridDetails?.tranche_value)
            rows.push(createData('Tranche', `${gridDetails?.tranche_value} ${trancheLabel}`))
          if (gridDetails?.step_percentage) rows.push(createData('Step', `${gridDetails?.step_percentage} %`))
          if (configuration.trading_platform.name)
            rows.push(
              createData(
                'Platform',
                `${configuration.trading_platform.name ?? '---'}`,
                configuration.trading_platform.external_image_url
              )
            )
          if (gridDetails?.stop_strategy)
            rows.push(
              createData(
                `${
                  stopStrategiesNames.find(stopStrategy => stopStrategy.name === gridDetails?.stop_strategy)
                    ?.description
                }`,
                `${gridDetails?.stop_strategy_value}`
              )
            )
        }
        break
    }
    return rows
  }

  const rows = generateRows()
  const balance = configuration?.balances?.total
  const profit = `${
    configuration?.profit?.total_percent_profit >= 0 ? '+' : ''
  }${configuration?.profit?.total_percent_profit?.toFixed(2)}%`

  const navigateToDetailPage = (): void => {
    navigate(`/configuration/${configuration.id}`)
  }

  const handleActivation = async (): Promise<void> => {
    changeStatusTradingConfiguration({
      configurationId: configuration.id,
      status: (configuration.status === configurationStatuses.active ? 'stop' : 'start') as TradingConfigurationState,
    })
  }

  const tooltipOptions = [
    {
      title: 'Edit',
      onClick: () => handleEdit(),
    },
    {
      title: 'Delete',
      onClick: () => handleDelete(),
    },
  ]

  const handleDelete = () => {
    setDeleteModalOpen(true)
  }

  const handleEdit = () => {
    setIsUpdating(true)
  }

  const handleDeposit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setDepositModalOpen(true)
  }

  const handleWithdraw = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setWithdrawModalOpen(true)
  }

  const handleActivateConfiguration = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setActivationModalOpen(true)
  }

  const configurationStopMessage = configuration?.message || configuration?.status

  return (
    <Box className={styles.card__container}>
      <Grid container className={styles.card__header} onClick={navigateToDetailPage}>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant={'inherit'} className={styles.configuration__name}>
                {configuration.name}
              </Typography>
            </Grid>
            {configuration.status === configurationStatuses.active && (
              <Grid item>
                <Typography className={styles.active}>
                  <CircleIcon className={styles.status__icon} fontSize={'small'} />
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item className={styles.detail__icon__container} onClick={e => e.stopPropagation()}>
          <ToolTip options={tooltipOptions} disabled={configuration.status === configurationStatuses.maintenance}>
            <MoreHorizIcon fontSize={'small'} className={styles.detail__icon} />
          </ToolTip>
        </Grid>
      </Grid>
      <Box className={styles.card__padding}>
        <Box className={styles.balance__amount__container} onClick={navigateToDetailPage}>
          <Typography variant={'inherit'} className={styles.balance__amount}>
            {balance.toLocaleString('en')}
          </Typography>
          <Box className={styles.balance__profit__container}>
            <Typography variant={'inherit'} className={styles.balance__currency}>
              USDT
            </Typography>
            <Typography
              variant={'inherit'}
              className={
                configuration?.profit?.total_percent_profit >= 0
                  ? styles.balance__profit__positive
                  : styles.balance__profit__negative
              }
            >
              {profit}
            </Typography>
          </Box>
        </Box>
        <Grid container className={styles.deposit__container}>
          <Grid item>
            <Button
              disabled={configuration.status === configurationStatuses.maintenance}
              className={styles.deposit__button}
              onClick={handleDeposit}
            >
              Deposit
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={styles.deposit__button}
              onClick={handleWithdraw}
              disabled={balance === 0 || configuration.status === configurationStatuses.maintenance}
            >
              Withdraw
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Break margin={'12px'} />
      <Box className={styles.card__table__padding} onClick={navigateToDetailPage}>
        <Table>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} className={styles.table__row}>
                <TableCell className={styles.table__field__name}>{row.fieldName}</TableCell>
                <TableCell className={styles.table__field__value}>
                  {row.fieldImage && (
                    <img className={styles.table__field__image} src={row.fieldImage} alt={String(row.fieldValue)} />
                  )}
                  {row.fieldValue}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Tooltip
          title={configurationStopMessage.length >= 60 ? configurationStopMessage : ''}
          placement={'right-start'}
        >
          <Box className={styles.stop__message__container}>{configurationStopMessage}</Box>
        </Tooltip>
      </Box>
      <Box className={styles.button__container}>
        <ConfigurationActivationButton
          disabled={configuration.status === configurationStatuses.maintenance}
          mode={configuration.status === configurationStatuses.active ? ('stop' as ButtonMode) : ('run' as ButtonMode)}
          onClick={handleActivateConfiguration}
        />
      </Box>
      {activationModalOpen && (
        <ModalConfirmPopup
          open={activationModalOpen}
          setOpen={setActivationModalOpen}
          onConfirm={handleActivation}
          subTitle={
            configuration.status === configurationStatuses.active ? 'All open orders will be cancelled.' : undefined
          }
          title={`Are you sure you want to ${
            configuration.status === configurationStatuses.active ? 'stop' : 'run'
          } this trading configuration?`}
        />
      )}
      {depositModalOpen && (
        <ConfigurationDepositModal
          open={depositModalOpen}
          setOpen={setDepositModalOpen}
          configuration={configuration}
        />
      )}
      {withdrawModalOpen && (
        <ConfigurationWithdrawModal
          open={withdrawModalOpen}
          setOpen={setWithdrawModalOpen}
          configuration={configuration}
        />
      )}
      {deleteModalOpen && (
        <DeleteConfigurationModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          configuration={configuration}
          onSuccess={setIndex}
        />
      )}
      <CreateUpdateConfigurationModal open={isUpdating} setOpen={setIsUpdating} configuration={configuration} />
    </Box>
  )
}

export default ConfigurationCard
