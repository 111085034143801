import { Options, serialize } from 'object-to-formdata'

const options: Options = {
  indices: false,
  nullsAsUndefineds: false,
  booleansAsIntegers: false,
  allowEmptyArrays: false,
  noFilesWithArrayNotation: false,
  dotsForObjectNotation: false,
}

export const createFormData = (object: unknown) => {
  return serialize(object, options)
}
