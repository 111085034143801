import React, { ReactElement } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from 'shared/components/PageContainer/styles.module.scss';
import ScrollToTopButton from 'shared/components/ScrollToTopButton';

type PageContainerParams = {
  label?: string;
  labelBlock?: ReactElement;
  tooltip?: ReactElement;
  children: ReactElement;
};

const PageContainer = ({
  label,
  labelBlock,
  tooltip,
  children,
}: PageContainerParams): ReactElement => {
  return (
    <>
      <Box>
        <Grid container className={styles.header__container}>
          <Grid item>
            {label ? (
              <Typography variant={'inherit'} className={styles.page__label}>
                {label}
              </Typography>
            ) : (
              labelBlock
            )}
          </Grid>
          <Grid item>{tooltip}</Grid>
        </Grid>
      </Box>
      {children}
      <ScrollToTopButton />
    </>
  );
};

export default PageContainer;
