import React, { ReactElement } from 'react'
import ModalPopup from 'shared/components/ModalPopup'
import CreateConfigurationForm from 'features/trading/components/CreateConfigurationForm'
import { ConfigurationDetail } from 'api/generated'

type CreateConfigurationParams = {
  open: boolean
  setOpen: (newState: boolean) => void
  configuration?: ConfigurationDetail
}

const CreateUpdateConfigurationModal = ({ open, setOpen, configuration }: CreateConfigurationParams): ReactElement => {
  return (
    <ModalPopup
      open={open}
      setOpen={setOpen}
      title={configuration ? `Update configuration ${configuration.name}` : 'New Configuration'}
      content={<CreateConfigurationForm onSuccess={setOpen} configuration={configuration} />}
    />
  )
}

export default CreateUpdateConfigurationModal
