import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo } from 'react'
import { ConfigurationDetail } from 'api/generated'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { tradingConfigurationActions } from '../redux/tradingConfigurations/tradingConfigurations.slice'
import tradingConfigurationsThunks from '../redux/tradingConfigurations/tradingConfigurations.thunks'

const useTradingConfigurations = (load = true) => {
  const configurationsState = (store: RootState): ConfigurationDetail[] => store.tradingConfigurations.configurations
  const currConfigurationState = (store: RootState): ConfigurationDetail | null =>
    store.tradingConfigurations.currentConfiguration
  const isLoadingState = (state: RootState): boolean => state.tradingConfigurations.isLoading
  const isUpdatedState = (state: RootState): boolean => state.tradingConfigurations.isUpdated
  const errorState = (state: RootState): string => state.tradingConfigurations.error
  const hasConfiguration = useAppSelector(
    createSelector(configurationsState, (item): boolean => !!item && item.length > 0)
  )
  const hasCurrentConfiguration = useAppSelector(createSelector(currConfigurationState, (item): boolean => !!item))
  const configurations = useAppSelector(configurationsState)
  const currentConfiguration = useAppSelector(currConfigurationState)
  const configurationsById = useCallback((id: number) => configurations?.filter(i => i.id === id)[0], [configurations])

  const isLoading = useAppSelector(isLoadingState)
  const isUpdated = useAppSelector(isUpdatedState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingConfigurationActions,
    ...tradingConfigurationsThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getTradingConfigurations } = actionsDispatch

  useEffect(() => {
    if (!isLoading && !hasConfiguration && load) getTradingConfigurations()
  }, [hasConfiguration])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasConfiguration,
      hasCurrentConfiguration,
      configurations,
      currentConfiguration,
      configurationsById,
      isLoading,
      isUpdated,
      error,
    }
  }, [
    hasConfiguration,
    hasCurrentConfiguration,
    configurations,
    currentConfiguration,
    configurationsById,
    isLoading,
    isUpdated,
    error,
    dispatch,
  ])
}

export default useTradingConfigurations
