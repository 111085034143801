import React, { ReactElement, MouseEvent } from 'react'
import { Button, Grid } from '@mui/material'
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined'
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import styles from './styles.module.scss'

export type ButtonMode = 'run' | 'stop'

type ConfigurationButtonParams = {
  mode: ButtonMode
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const ConfigurationActivationButton = ({
  mode,
  onClick,
  disabled = false,
}: ConfigurationButtonParams): ReactElement => {
  const buttonIcons = {
    run: <PlayArrowOutlinedIcon className={styles.button__icon} />,
    stop: <PauseCircleOutlineOutlinedIcon className={styles.button__icon} />,
  }
  return (
    <Button
      className={[styles.button, mode === 'run' ? styles.button__run : styles.button__stop].join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      <Grid container spacing={1}>
        <Grid className={styles.button__content} item>
          {buttonIcons[mode]}
        </Grid>
        <Grid item>{mode}</Grid>
      </Grid>
    </Button>
  )
}

export default ConfigurationActivationButton
