import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'
import { ConfigurationDetail } from 'api/generated'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import { toast } from 'react-toastify'
import { configurationStatuses } from 'constants/tradingConfigurations'
import tradingConfigurationsThunks from './tradingConfigurations.thunks'
const { logout } = authThunks

const {
  changeStatusTradingConfiguration,
  createTradingConfiguration,
  deleteTradingConfiguration,
  depositCashToTradingConfiguration,
  getTradingConfigurationById,
  getTradingConfigurations,
  withdrawCashFromTradingConfiguration,
  updateTradingConfiguration,
  updateTradingInfo,
} = tradingConfigurationsThunks

export interface TradingConfigurationSliceState {
  configurations: ConfigurationDetail[]
  error: string
  isLoading: boolean
  isUpdated: boolean
  currentConfiguration: ConfigurationDetail | null
}

const initialState: TradingConfigurationSliceState = {
  isLoading: false,
  isUpdated: false,
  configurations: [],
  currentConfiguration: null,
  error: '',
}

export const tradingConfigurationsSlice = createSlice({
  name: 'tradingConfigurations',
  initialState,
  reducers: {
    toggleIsUpdated: state => {
      state.isUpdated = !state.isUpdated
    },
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addCase(updateTradingInfo, () => initialState)
      .addMatcher(
        isPending(
          changeStatusTradingConfiguration,
          createTradingConfiguration,
          deleteTradingConfiguration,
          depositCashToTradingConfiguration,
          getTradingConfigurationById,
          getTradingConfigurations,
          withdrawCashFromTradingConfiguration,
          updateTradingConfiguration
        ),
        state => {
          state.error = ''
        }
      )
      .addMatcher(
        isPending(
          createTradingConfiguration,
          getTradingConfigurationById,
          getTradingConfigurations,
          changeStatusTradingConfiguration
        ),
        state => {
          state.isLoading = true
          state.isUpdated = false
        }
      )
      .addMatcher(isPending(depositCashToTradingConfiguration, withdrawCashFromTradingConfiguration), state => {
        state.isUpdated = false
      })
      .addMatcher(
        isRejected(
          createTradingConfiguration,
          getTradingConfigurationById,
          getTradingConfigurations,
          deleteTradingConfiguration,
          changeStatusTradingConfiguration,
          depositCashToTradingConfiguration,
          withdrawCashFromTradingConfiguration,
          updateTradingConfiguration
        ),
        (state, action) => {
          state.isLoading = false
          state.error = action.payload as string
          state.isUpdated = false
        }
      )
      .addMatcher(isFulfilled(getTradingConfigurationById), (state, action: PayloadAction<ConfigurationDetail>) => {
        state.configurations = state.configurations.map(configuration =>
          configuration.id == action.payload.id ? action.payload : configuration
        )
        state.currentConfiguration = action.payload
        state.isLoading = false
      })
      .addMatcher(isFulfilled(createTradingConfiguration), (state, action: PayloadAction<ConfigurationDetail>) => {
        state.configurations = [...state.configurations, action.payload]
        state.isLoading = false
      })
      .addMatcher(isFulfilled(deleteTradingConfiguration), (state, action) => {
        const prevConf = state.configurations?.length ? state.configurations : []
        state.configurations = prevConf?.filter(configuration => configuration?.id !== action.payload)
        state.currentConfiguration = state.currentConfiguration?.id === action.payload ? null : state.currentConfiguration
        state.isLoading = false
        state.isUpdated = true
        toast.success('Configuration has been successfully deleted.')
      })
      .addMatcher(
        isFulfilled(changeStatusTradingConfiguration, depositCashToTradingConfiguration, updateTradingConfiguration),
        (state, action) => {
          const prevConf = state.configurations?.length ? state.configurations : []
          state.configurations = prevConf?.map(content => (content?.id === action.payload?.id ? action.payload : content))
          state.currentConfiguration =
            state.currentConfiguration?.id === action.payload.id ? action.payload : state.currentConfiguration
          state.isLoading = false
        }
      )
      .addMatcher(isFulfilled(updateTradingConfiguration), state => {
        toast.success('Configuration has been successfully updated.')
        state.isUpdated = true
      })
      .addMatcher(isFulfilled(createTradingConfiguration), state => {
        toast.success('Configuration has been successfully created.')
        state.isUpdated = true
      })
      .addMatcher(isFulfilled(depositCashToTradingConfiguration), state => {
        toast.success('Cash has been deposited.')
        state.isUpdated = true
      })
      .addMatcher(isFulfilled(withdrawCashFromTradingConfiguration), (state, { payload }) => {
        const { configurationId, amount } = payload
        const prevConf = state.configurations?.length ? state.configurations : []
        state.configurations = prevConf?.map(content =>
          content?.id === configurationId
            ? {
                ...content,
                balances: { ...content.balances, cash_balance: content.balances.cash_balance - amount },
                status: configurationStatuses.withdraw,
              }
            : content
        )
        state.currentConfiguration =
          state.currentConfiguration?.id === configurationId
            ? {
                ...state.currentConfiguration,
                balances: {
                  ...state.currentConfiguration.balances,
                  cash_balance: state.currentConfiguration.balances.cash_balance - amount,
                },
              }
            : state.currentConfiguration
        state.isLoading = false
        toast.success('Withdrawal successfully initiated.')
        state.isUpdated = true
      })
      .addMatcher(isFulfilled(getTradingConfigurations), (state, action) => {
        state.configurations = action.payload
        state.isLoading = false
      })
  },
})

export const tradingConfigurationActions = tradingConfigurationsSlice.actions

export const { reducer: tradingConfigurationReducer } = tradingConfigurationsSlice

export default tradingConfigurationReducer
