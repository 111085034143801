import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import { TrancheTypeDetail } from 'api/generated'
import tradingTrancheTypesThunks from './tradingTrancheTypes.thunks'
const { getTrancheTypes } = tradingTrancheTypesThunks
const { logout } = authThunks

export interface TradingTrancheTypesInitialState {
  isLoading: boolean
  types: TrancheTypeDetail[]
  error: string
}

const initialState: TradingTrancheTypesInitialState = {
  isLoading: false,
  types: [],
  error: '',
}

const tradingTrancheTypesSlice = createSlice({
  name: 'tradingTrancheTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(isPending(getTrancheTypes), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isFulfilled(getTrancheTypes), (state, action) => {
        state.isLoading = false
        state.types = action.payload
      })
      .addMatcher(isRejected(getTrancheTypes), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const tradingTrancheTypesActions = tradingTrancheTypesSlice.actions

export const { reducer: tradingTrancheTypesReducer } = tradingTrancheTypesSlice

export default tradingTrancheTypesReducer
