import React, { ReactElement } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import styles from 'shared/components/FormComponents/TextInput/styles.module.scss';
import floatFieldStyles from 'shared/components/FormComponents/FloatInput/styles.module.scss';
import { UseFormRegisterReturn } from 'react-hook-form';
import preventKeys from 'shared/utils/preventKeys';

type FloatInputProps = {
  error?: string;
  id?: string;
  label?: string;
  defaultValue?: string;
  value?: string;
  register?: UseFormRegisterReturn;
  fullwidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
  currencyLabel?: string;
  onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  maxValue?: string;
  minValue?: string;
};

const FloatInput = ({
  error = '',
  id,
  label,
  defaultValue,
  register,
  fullwidth = false,
  disabled = false,
  placeholder = label,
  currencyLabel,
  onChange,
  value,
  maxValue,
  minValue,
}: FloatInputProps): ReactElement => {
  return (
    <Box className={label ? styles.input__container : styles.input__container__no__label}>
      <Typography variant={'inherit'} className={styles.input__label}>
        {label}
      </Typography>
      <Box className={floatFieldStyles.input__container}>
        <TextField
          id={id}
          className={
            error
              ? [
                  floatFieldStyles.input__error,
                  currencyLabel ? floatFieldStyles.input__currency : '',
                ].join(' ')
              : [
                  floatFieldStyles.input,
                  currencyLabel ? floatFieldStyles.input__currency : '',
                ].join(' ')
          }
          inputProps={{
            min: minValue ?? '0',
            max: maxValue,
            step: 'any',
            sx: { paddingLeft: '10px' },
            ...register,
          }}
          InputProps={{ disableUnderline: true }}
          required={true}
          type={'number'}
          placeholder={placeholder}
          variant={'standard'}
          name={label}
          defaultValue={defaultValue}
          disabled={disabled}
          fullWidth={fullwidth}
          color={'primary'}
          onKeyDown={preventKeys}
          onChange={onChange}
          value={value}
        />
        {currencyLabel && (
          <Box
            className={[
              floatFieldStyles.currency__label__container,
              error ? floatFieldStyles.currency__label__container__error : '',
            ].join(' ')}
          >
            <Typography className={floatFieldStyles.currency__label}>{currencyLabel}</Typography>
          </Box>
        )}
      </Box>
      {error && (
        <Typography className={styles.input__error_message} variant={'inherit'} color={'error'}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default FloatInput;
