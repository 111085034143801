import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import styles from 'shared/components/ScrollToTopButton/styles.module.scss';

const ScrollToTopButton = (): ReactElement => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = (): void => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => window.addEventListener('scroll', toggleVisible), []);

  return (
    <div className={styles.container}>
      <Button
        className={visible ? styles.scroll__top__button : styles.hidden}
        onClick={scrollToTop}
      >
        <NavigationIcon />
      </Button>
    </div>
  );
};

export default ScrollToTopButton;
