export interface IDateRange {
  date_from: number;
  date_to: number;
}

export enum DateRangePeriodsEnum {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}
