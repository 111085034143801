import { UserDetail } from 'api/generated'
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import authThunks from './auth.thunks'
const { login, logout, resetPassword, resetPasswordLink, updateUser } = authThunks

export interface AuthInitialState {
  isLoading: boolean
  user: UserDetail | null
  error: string
}

const initialState: AuthInitialState = {
  isLoading: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '{}') : null,
  error: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => {
        localStorage.clear()
        localStorage.removeItem('access_token')
        return { ...initialState, user: null }
      })
      .addMatcher(isFulfilled(login, resetPasswordLink, resetPassword), (state, { payload }) => {
        if (payload && payload.user) {
          localStorage.setItem('access_token', payload.access_token)
          localStorage.setItem('refresh_token', payload.refresh_token)
          state.user = { ...state.user, ...payload.user }
          localStorage.setItem('user', JSON.stringify(state.user))
        }
        state.isLoading = false
      })
      .addMatcher(isPending(login, resetPasswordLink, resetPassword, updateUser), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isRejected(login, resetPasswordLink, resetPassword, updateUser), (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addMatcher(isFulfilled(updateUser), (state, { payload }) => {
        if (payload) {
          state.user = { ...state.user, ...payload }
        }
        state.isLoading = false
      })
  },
})

export const authActions = authSlice.actions

export const { reducer: authReducer } = authSlice

export default authReducer
