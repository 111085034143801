import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Box, Grid, Typography } from '@mui/material'
import { OrderDetail, OrderingEnum, OrderSidesEnum, OrderStatusesEnum } from 'api/generated'
import BlurredOrderTable from 'features/trading/components/BlurredOrderTable'
import styles from 'features/trading/components/OrderTable/styles.module.scss'
import useTradingOrders from 'features/trading/hooks/useTradingOrders'
import React, { ReactElement, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'shared/components/Loader'
import SortableTable from 'shared/components/SortableTable'
import SortSelect from 'shared/components/SortSelect'

interface IOrderTableParams {
  label: string
  statusName?: string
  orders: OrderDetail[]
  fetchData?: (page: number, status: string, ordering: string, configuration_id?: number) => void
  hasMore?: boolean
  configuration_id?: number
}

const OrderTable = ({
  label,
  statusName = '',
  orders = [],
  fetchData = (): void => {
    return
  },
  hasMore = false,
  configuration_id,
}: IOrderTableParams): ReactElement => {
  const { getPaginatedOrders, paginate, isLoading } = useTradingOrders()
  const pagination = paginate[configuration_id ?? 0]
  const tableHeaders = useMemo(() => {
    return statusName === 'OPEN'
      ? [
          {
            label: 'Order side',
            accessor: 'side',
            sortable: false,
            styles: (order: unknown): Record<string, string> => {
              const tradingOrder = order as OrderDetail
              return {
                color: tradingOrder.side.toLowerCase() === 'sell' ? '#D63031 !important' : '#00B894 !important',
              }
            },
          },
          { label: 'Configuration ID', accessor: 'configuration' },
          { label: 'Asset', accessor: 'asset' },
          { label: 'Amount', accessor: 'amount' },
          { label: 'Total USDT', accessor: 'total' },
          { label: 'Price', accessor: 'price' },
          { label: 'Date/Time', accessor: 'created_at' },
        ]
      : [
          {
            label: 'Order side',
            accessor: 'side',
            sortable: false,
            styles: (order: unknown): Record<string, string> => {
              const tradingOrder = order as OrderDetail
              return {
                color: tradingOrder.side.toLowerCase() === 'sell' ? '#D63031 !important' : '#00B894 !important',
              }
            },
          },
          { label: 'Configuration ID', accessor: 'configuration' },
          { label: 'Asset', accessor: 'asset' },
          { label: 'Amount', accessor: 'amount' },
          { label: 'Total USDT', accessor: 'total' },
          { label: 'Price', accessor: 'price' },
          {
            label: 'Status',
            accessor: 'status',
            styles: (order: unknown): Record<string, string> => {
              const tradingOrder = order as OrderDetail
              const getColor = (status: OrderStatusesEnum): string => {
                switch (status) {
                  case OrderStatusesEnum.OPEN:
                    return '#43C6E0 !important'
                  case OrderStatusesEnum.CANCELLED:
                    return 'rgba(248, 126, 136, 1) !important'
                  case OrderStatusesEnum.CLOSED:
                    return '#99d355 !important'
                  case OrderStatusesEnum.FAILED:
                    return '#FF7559 !important'
                  case OrderStatusesEnum.PENDING:
                    return '#7F66FF !important'
                }
              }
              return {
                color: getColor(tradingOrder.status),
              }
            },
          },
          { label: 'Date/Time', accessor: 'created_at' },
        ]
  }, [statusName, orders])

  const handleOrderChange = (order: string) => {
    getPaginatedOrders({
      configuration_id,
      page: 1,
      size: 6,
      statuses: 'CANCELLED,CLOSED,PENDING,FAILED',
      ordering: order === 'DESC' ? OrderingEnum.DESC : OrderingEnum.ASC,
      sort: true,
    })
  }

  const addZero = (x: number, lessThan = 10) => (x < lessThan ? '0' : '')

  const tableRows = orders.map(order => {
    const d = new Date(order?.created_at || '')
    const date =
      d.getFullYear() +
      '.' +
      addZero(d.getMonth() + 1) +
      (d.getMonth() + 1) +
      '.' +
      d.getDate() +
      ' ' +
      addZero(d.getHours()) +
      d.getHours() +
      ':' +
      addZero(d.getMinutes()) +
      d.getMinutes() +
      ':' +
      addZero(d.getSeconds()) +
      d.getSeconds()
    return {
      side: order?.side,
      configuration: order?.trading_configuration_id,
      asset: order?.currency_pair.base,
      amount: order?.amount,
      price: order?.price, 
      total: order.side == OrderSidesEnum.BUY ? 
          (order.amount - (order.fee ?? 0)) * order.price : (order.price - (order.fee ?? 0)) * order.amount,
      status: order?.status,
      failedReason: order?.fail_reason as string,
      created_at: date,
    }
  })

  const handleGetNextPage = () => {
    if (isLoading || !tableRows) return
    fetchData(pagination?.page + 1, statusName, pagination?.ordering ?? 'DESC', configuration_id)
  }

  return (
    <Box className={styles.table__container}>
      <Grid container className={styles.table__label__container}>
        <Grid item>
          <Typography variant={'inherit'} className={styles.table__label}>
            {label}
          </Typography>
        </Grid>
        <Grid item>
          {!(statusName === 'OPEN') ? (
            <Grid container>
              <Grid item className={styles.filters__text__container}>
                <Typography variant={'inherit'} className={styles.filters__text}>
                  Sort by Date/Time:
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <SortSelect
                      onChange={handleOrderChange}
                      options={[
                        { label: 'ASCENDING', value: 'ASC' },
                        { label: 'DESCENDING', value: 'DESC' },
                      ]}
                      value={pagination?.ordering}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={styles.filters__filter__icon__container}>
                <FilterAltOutlinedIcon />
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <div>
        {tableRows?.length > 0 ? (
          <InfiniteScroll
            next={handleGetNextPage}
            hasMore={hasMore}
            loader={<Loader />}
            dataLength={tableRows?.length ?? 0}
            className={styles.infinite__scroll}
          >
            <SortableTable headers={tableHeaders} data={tableRows} />
          </InfiniteScroll>
        ) : (
          <BlurredOrderTable headers={tableHeaders} statusName={statusName} />
        )}
      </div>
    </Box>
  )
}

export default OrderTable
