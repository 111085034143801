import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { Button, Grid } from '@mui/material'
import React from 'react'
import useServerIps from 'features/credentials/hooks/useServerIps'
import copyTextToClipBoard from 'shared/utils/clip-board'
import styles from './styles.module.scss'

interface ServerIpsProps {
  tradingPlatformName: string
}

const ServerIps = ({ tradingPlatformName }: ServerIpsProps) => {
  const { isLoadingIPs, ips } = useServerIps()

  const delimiter = () => {
    switch (tradingPlatformName) {
      case 'Gate.io':
        return ','
      case 'Bybit':
        return ','
      case 'Binance':
        return ' '
      default:
        return ' '
    }
  }

  const ipsList = ips?.join(delimiter()) || ''

  const handleCopyIPs = async () => {
    if (ips && !isLoadingIPs) {
      await copyTextToClipBoard(ipsList)
    }
  }

  return (
    <Grid container className={styles.block_ips}>
      <Grid item xs={10}>
        <Grid item className={styles.ips_text_title}>
          IP for Binding
        </Grid>
        <Grid item className={styles.ips_text}>
          {isLoadingIPs ? 'Loading...' : ipsList || 'Empty'}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant={'text'}
          startIcon={<ContentCopyOutlinedIcon />}
          className={styles.copy_ips}
          onClick={handleCopyIPs}
          disabled={isLoadingIPs || !ips}
        >
          Copy
        </Button>
      </Grid>
    </Grid>
  )
}

export default ServerIps
