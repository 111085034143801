import React, { ReactElement } from 'react'
import ModalPopup from 'shared/components/ModalPopup'
import { Box, Typography } from '@mui/material'
import styles from 'features/trading/components/ConfigurationWithdrawModal/styles.module.scss'
import { configurationStatuses } from 'constants/tradingConfigurations'
import ConfigurationActivationButton, {
  ButtonMode,
} from 'features/trading/components/ConfigurationList/ConfigurationCard/ConfigurationActivationButton'
import WithdrawConfigurationForm from 'features/trading/components/ConfigurationWithdrawModal/WithdrawConfigurationForm'
import { ConfigurationDetail, TradingConfigurationState } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'

type ConfigurationWithdrawModalParams = {
  configuration: ConfigurationDetail
  open: boolean
  setOpen: (newState: boolean) => void
}

const ConfigurationWithdrawModal = ({
  configuration,
  open,
  setOpen,
}: ConfigurationWithdrawModalParams): ReactElement => {
  const { changeStatusTradingConfiguration, updateTradingInfo } = useTradingConfigurations(false)
  const title = `Withdraw from ${configuration.name}`

  const handleWithdraw = () => {
    setOpen(false)
    updateTradingInfo()
  }

  const handleActivateConfiguration = async () => {
    setOpen(false)
    changeStatusTradingConfiguration({
      configurationId: configuration.id,
      status: (configuration.status === configurationStatuses.active ? 'stop' : 'start') as TradingConfigurationState,
    })
  }

  const runningConfigurationContent = (
    <Box className={styles.content}>
      <Typography variant={'inherit'} className={styles.content__text}>
        You should stop trading configuration in order to withdraw the funds.
      </Typography>
      <Box className={styles.button__container}>
        <ConfigurationActivationButton
          disabled={false}
          mode={'stop' as ButtonMode}
          onClick={handleActivateConfiguration}
        />
      </Box>
    </Box>
  )

  return (
    <ModalPopup
      open={open}
      setOpen={setOpen}
      title={title}
      content={
        configuration.status === configurationStatuses.active ? (
          runningConfigurationContent
        ) : (
          <WithdrawConfigurationForm configuration={configuration} onSuccess={handleWithdraw} />
        )
      }
    />
  )
}

export default ConfigurationWithdrawModal
