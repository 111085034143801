export const ROUTES = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  homePage: { path: '/', tabIndex: 1 },
  profilePage: { path: '/profile', tabIndex: 2 },
  infoPage: { path: '/about', tabIndex: 3 },
  configurationDetail: { path: '/configuration/:id', tabIndex: 0 },
};
export const APIROUTES = {
  trading: '/trading',
  auth: '/auth',
  transactions: '/transactions',
  charts: '/profit_chart',
};
