import React, { ReactElement } from 'react';
import { Box, Grid } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import styles from 'shared/components/Navbar/NavLink/styles.module.scss';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export type NavLinkIcons = 'dashboard' | 'notifications' | 'settings' | 'about';

type NavLinkParams = {
  icon: NavLinkIcons;
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
};

const NavLink = ({ icon, title, isSelected = false, onClick }: NavLinkParams): ReactElement => {
  const iconsList = {
    dashboard: <GridViewIcon />,
    notifications: <NotificationsNoneIcon />,
    settings: <PermIdentityOutlinedIcon />,
    about: <InfoOutlinedIcon />,
  };
  return (
    <>
      <Box
        className={
          isSelected
            ? [styles.nav__container, styles.nav__container__active].join(' ')
            : styles.nav__container
        }
        onClick={onClick}
      >
        <Grid container>
          <Grid item xs={2} className={styles.icon}>
            {iconsList[icon]}
          </Grid>
          <Grid item xs={10} className={'disable__text__selection'}>
            {title}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NavLink;
