import { RefreshUserSessionResponse } from 'api/generated'
import { api } from 'api/v1'
import { RefreshWaitingQueue } from 'api/v1/RefreshWaitingQueue'
// import store from 'store';
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
// import { logout } from 'features/auth/redux/authThunks';

const { REACT_APP_API_URL } = process.env
const refreshQueue = new RefreshWaitingQueue()

export const refreshTokenSuccess = (data: RefreshUserSessionResponse) => {
  localStorage.setItem('access_token', data.access_token)
  localStorage.setItem('refresh_token', data.refresh_token)
}

export const responseErrorInterceptor = async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig & { _retry: undefined | boolean }

  if (!error.response || !error.response.status) {
    return Promise.reject(error)
  }

  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')
  // handle refreshing token
  if (accessToken && error.response.status === 401 && !originalRequest._retry) {
    if (refreshQueue.isRefreshing) {
      return await refreshQueue.push(originalRequest)
    }

    refreshQueue.isRefreshing = true
    originalRequest._retry = true
    // eslint-disable-next-line
    console.log('JWT: Refreshing token...')
    try {
      const { data } = await axios.post<RefreshUserSessionResponse>(
        REACT_APP_API_URL + `/v1/auth/client/user-sessions/refresh`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + refreshToken,
          },
        }
      )

      refreshTokenSuccess(data)
      // eslint-disable-next-line
      console.log(`JWT: Token refreshed, resolving ${refreshQueue.pendingCount + 1} pending requests`)
      refreshQueue.isRefreshing = false
      refreshQueue.resolveQueued(data.access_token)

      return await api(originalRequest)
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 406) {
        handleRefreshTokenError(error as AxiosError)
      }
    }
  } else if (error.response.status === 401 || error.response.status === 406) {
    handleRefreshTokenError(error)
  }
  return Promise.reject(error)
}

const handleRefreshTokenError = (error: AxiosError) => {
  // eslint-disable-next-line
  console.log('JWT: Error refreshing token: ', error.response)
  localStorage.removeItem('user')
  const newWindow = window.open('/login', '_self', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
  refreshQueue.rejectQueued(error)
  refreshQueue.isRefreshing = false
}
