import { combineReducers } from 'redux'
import authReducer from 'features/auth/redux/auth/auth.slice'
import credentialsReducer from 'features/credentials/redux/credentials/credentials.slice'
import tradingPlatformsReducer from 'features/trading/redux/tradingPlatforms/tradingPlatforms.slice'
import tradingConfigurationsReducer from 'features/trading/redux/tradingConfigurations/tradingConfigurations.slice'
import tradingStrategiesReducer from 'features/trading/redux/tradingStrategies/tradingStrategies.slice'
import tradingSubStrategiesReducer from 'features/trading/redux/tradingSubStrategies/tradingSubStrategies.slice'
import tradingStopStrategiesReducer from 'features/trading/redux/tradingStopStrategies/tradingStopStrategies.slice'
import tradingCurrencyPairsReducer from 'features/trading/redux/tradingCurrencyPairs/tradingCurrencyPairs.slice'
import tradingTrancheTypesReducer from 'features/trading/redux/tradingTrancheTypes/tradingTrancheTypes.slice'
import tradingOrdersReducer from 'features/trading/redux/tradingOrders/tradingOrders.slice'
import tradingProfitsReducer from 'features/trading/redux/tradingProfits/tradingProfits.slice'

export default combineReducers({
  auth: authReducer,
  credentials: credentialsReducer,
  tradingPlatforms: tradingPlatformsReducer,
  tradingConfigurations: tradingConfigurationsReducer,
  tradingStrategies: tradingStrategiesReducer,
  tradingSubStrategies: tradingSubStrategiesReducer,
  tradingStopStrategies: tradingStopStrategiesReducer,
  tradingCurrencyPairs: tradingCurrencyPairsReducer,
  tradingTrancheTypes: tradingTrancheTypesReducer,
  tradingOrders: tradingOrdersReducer,
  tradingProfits: tradingProfitsReducer,
})
