import { LoginRequest } from 'api/generated'
import React, { useEffect } from 'react'
import styles from 'features/auth/components/LoginForm/styles.module.scss'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import TextInput from 'shared/components/FormComponents/TextInput'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import { useLocation, useNavigate } from 'react-router-dom'
import PasswordInput from 'shared/components/FormComponents/PasswordInput'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import useAuth from 'features/auth/hooks/useAuth'
import Loader from 'shared/components/Loader'

type LocationState = {
  from: {
    path: string
  }
  isPasswordReset?: boolean
}

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>()

  const location = useLocation()
  const navigate = useNavigate()
  const isPasswordReset = (location.state as LocationState)?.isPasswordReset
  const { login, isAuth, isLoading } = useAuth()

  const onSubmit = (data: LoginRequest) => {
    login(data)
  }

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [navigate, isAuth])

  return (
    <Box className={styles.login__form__container}>
      <Typography variant={'inherit'} className={styles.login__form__name}>
        Sign in
      </Typography>
      <Typography variant={'inherit'} className={styles.login__form__help__text}>
        Enter your details below
      </Typography>
      {isPasswordReset ? (
        <Box className={styles.login__form__password__reset__text}>
          <CheckCircleOutlineIcon className={styles.login__form__password__reset__icon} /> If you have an account,
          we&apos;ll email you a reset link.
        </Box>
      ) : (
        <></>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={isLoading ? styles.login__form__loading : null}>
        <TextInput
          id={'email'}
          register={register('email', {
            required: { value: true, message: 'This field is required' },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          label={'Email Address'}
          placeholder={'Email'}
          fullwidth={true}
          error={errors.email?.message}
          autoComplete={'email'}
        />
        <PasswordInput
          id={'password'}
          register={register('password', {
            required: { value: true, message: 'This field is required' },
            minLength: { value: 8, message: 'Password length should be more than 8.' },
          })}
          label={'Password'}
          fullwidth={true}
          error={errors.password?.message}
          forgotMessage={true}
          autoComplete={'current-password'}
        />

        {isLoading ? <Loader /> : <SubmitButton text={'Sign in'} fullWidth={true} />}
      </form>
    </Box>
  )
}

export default LoginForm
