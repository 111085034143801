import { Box, Typography } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect, { GroupBase, components } from 'react-select'
import { useAsyncPaginate } from 'react-select-async-paginate'

// import styles from './styles.module.scss'
import styles from 'shared/components/FormComponents/SelectInput/styles.module.scss'
import { ISelectOption, SelectPaginateProps } from './types'

const defaultAdditional = {
  page: 1,
}

const { ValueContainer, Placeholder } = components

const StandardValueContainer = (props: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(props.children, child => (child && child.key !== 'placeholder' ? child : null))}
    </ValueContainer>
  )
}

export const SelectPaginate = <T extends any = string, Multiple extends boolean = false>({
  isMulti,
  id,
  formatOptionLabel,
  closeMenuOnSelect,
  placeholder,
  error,
  variant = 'standard',
  defaultValue,
  disabled,
  helperText,
  onAsync,
  control,
  label,
  rules,
  isLoading,
  onChange,
  value,
}: SelectPaginateProps<T, Multiple>) => {
  const [focused, setFocused] = useState(false)

  const loadOptions = async (
    searchQuery: string,
    _: GroupBase<ISelectOption<T, ReactNode>>,
    additional: { page: number } | undefined
  ) => {
    const page = additional?.page as number
    const options = await onAsync(page, searchQuery)

    return {
      options,
      hasMore: Array.isArray(options) ? options.length >= 1 : false,
      additional: {
        page: page + 1,
      },
    }
  }

  const asyncPaginateProps = useAsyncPaginate({
    loadOptions: loadOptions as any,
    additional: defaultAdditional,
  })

  const handleMenuScrollToBottom = (): void => {
    asyncPaginateProps.handleScrolledToBottom()
  }

  const customSelectStyles =
    variant === 'standard'
      ? {
          control: () => {
            return { display: 'flex', height: '100%' }
          },
          placeholder: (base: any, state: any) => {
            return {
              ...base,
              position: 'absolute',
              top: (state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused) && '-20px',
              fontSize: (state.hasValue || state.selectProps.inputValue || state.selectProps.isFocused) && '12px',
              color: (!state.hasValue && '#AAAAAA !important') || (state.selectProps.isFocused && '#7393FF !important'),
            }
          },
        }
      : undefined

  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field: { onChange: onChange2, value: value2, ref } }) => (
        <Box className={label ? styles.input__container : styles.input__container__no__label}>
          <Typography variant={'inherit'} className={styles.input__label}>
            {label}
          </Typography>
          <ReactSelect
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isFocused={focused}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isLoading={isLoading}
            ref={ref}
            components={
              variant === 'standard'
                ? {
                    ValueContainer: StandardValueContainer,
                  }
                : undefined
            }
            onChange={onChange ? (onChange as any) : onChange2} //onChange ? (onChange as any) : onChange2
            value={value2 || value}
            defaultValue={defaultValue}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholder}
            isMulti={isMulti}
            // classNamePrefix='react-select'
            className={styles.input}
            closeMenuOnSelect={closeMenuOnSelect}
            formatOptionLabel={formatOptionLabel}
            isDisabled={disabled}
            styles={customSelectStyles}
            {...asyncPaginateProps}
            isSearchable
          />
          {error && (
            <Typography className={styles.input__error_message} variant={'inherit'} color={'error'}>
              {error}
            </Typography>
          )}
          {helperText && !error && (
            <Typography className={styles.helperText} variant={'inherit'} color={'blue'}>
              {helperText}
            </Typography>
          )}
        </Box>
      )}
    />
  )
}
