import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { TradingPlatformDetail } from 'api/generated'
import { tradingPlatformsActions } from '../redux/tradingPlatforms/tradingPlatforms.slice'
import tradingPlatformsThunks from '../redux/tradingPlatforms/tradingPlatforms.thunks'

const useTradingPlatforms = () => {
  const tradingPlatformsState = (store: RootState): TradingPlatformDetail[] => store.tradingPlatforms.platforms
  const isLoadingState = (state: RootState): boolean => state.tradingPlatforms.isLoading
  const errorState = (state: RootState): string => state.tradingPlatforms.error
  const hasTradingPlatforms = useAppSelector(
    createSelector(tradingPlatformsState, (item): boolean => !!item && item.length > 0)
  )
  const tradingPlatforms = useAppSelector(tradingPlatformsState)
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingPlatformsActions,
    ...tradingPlatformsThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getTradingPlatforms } = actionsDispatch

  useEffect(() => {
    if (!hasTradingPlatforms && !isLoading) getTradingPlatforms()
  }, [hasTradingPlatforms])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasTradingPlatforms,
      tradingPlatforms,
      isLoading,
      error,
    }
  }, [hasTradingPlatforms, tradingPlatforms, isLoading, error, dispatch])
}

export default useTradingPlatforms
