import React, { ReactElement } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import styles from 'shared/components/FormComponents/TextInput/styles.module.scss'
import { UseFormRegisterReturn } from 'react-hook-form'

type InputFieldProps = {
  error?: string
  id?: string
  label?: string
  defaultValue?: string
  register?: UseFormRegisterReturn
  fullwidth?: boolean
  disabled?: boolean
  placeholder?: string
  autoComplete?: string
  marginTop?: string
}

const TextInput = ({
  error = '',
  id,
  label,
  defaultValue,
  register,
  fullwidth = false,
  disabled = false,
  placeholder = label,
  autoComplete = 'off',
  marginTop = '28px',
}: InputFieldProps): ReactElement => {
  return (
    <Box className={styles.input__container} sx={{ marginTop }}>
      <Typography variant={'inherit'} className={styles.input__label}>
        {label}
      </Typography>
      <TextField
        id={id}
        className={error ? styles.input__error : styles.input}
        InputProps={{ disableUnderline: true, sx: { paddingLeft: '10px' }, ...register }}
        required={true}
        type={'text'}
        placeholder={placeholder}
        variant={'standard'}
        name={label}
        defaultValue={defaultValue}
        disabled={disabled}
        fullWidth={fullwidth}
        color={'primary'}
        autoComplete={autoComplete}
      />
      {error && (
        <Typography className={styles.input__error_message} variant={'inherit'} color={'error'}>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default TextInput
