import { createAsyncThunk } from '@reduxjs/toolkit'
import { CrossSubStrategyDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getSubStrategies = createAsyncThunk<CrossSubStrategyDetail[]>(
  'tradingStrategies/getSubStrategies',
  async (params, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getSubStrategiesV1CrossClientSubStrategyGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const tradingSubStrategiesThunks = {
  getSubStrategies: getSubStrategies,
}

export default tradingSubStrategiesThunks
