import { useAppSelector } from 'store'
import { useEffect } from 'react'
import useTradingOrders from 'features/trading/hooks/useTradingOrders'

export const useOpenOrders = (id?: number) => {
  const { getOpenOrders } = useTradingOrders()
  const { getOpenOrdersState, isLoading } = useAppSelector(state => state.tradingOrders)
  useEffect(() => {
    if (!getOpenOrdersState[id || 0] && !isLoading) {
      getOpenOrders({
        configuration_id: id,
      })
    }
  }, [id])

  return {
    openOrders: getOpenOrdersState[id ? (id as number) : 0],
    isLoading: isLoading,
  }
}
