import { createAsyncThunk } from '@reduxjs/toolkit'
import { TradingPlatformDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getTradingPlatforms = createAsyncThunk<TradingPlatformDetail[]>(
  'tradingPlatforms/getTradingPlatforms',
  async (_, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getTradingPlatformsV1TradingPlatformsClientTradingPlatformsGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const tradingPlatformsThunks = {
  getTradingPlatforms,
}

export default tradingPlatformsThunks
