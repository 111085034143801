import React, { MouseEvent, ReactElement, useRef, useState } from 'react';
import styles from 'shared/components/ToolTip/styles.module.scss';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import { Box } from '@mui/material';

type ToolTipParams = {
  children: ReactElement;
  options: Array<{ title: string; icon?: ReactElement; onClick: () => void }>;
  disabled?: boolean;
};

const ToolTip = ({ children, options, disabled }: ToolTipParams) => {
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  const handleOnClick = (e: MouseEvent<Element>) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <Box
      ref={ref}
      className={[styles.tooltip, disabled && styles.disabled].join(' ')}
      onClick={handleOnClick}
    >
      {children}
      <span
        className={[
          styles.tooltip__text,
          isActive ? styles.tooltip__active : styles.tooltip__inactive,
        ].join(' ')}
      >
        {options.map((option, index) => (
          <Box className={styles.tooltip__option} key={index} onClick={option.onClick}>
            {option.title}
            {option.icon}
          </Box>
        ))}
      </span>
    </Box>
  );
};

export default ToolTip;
