import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  ConfigurationDetail,
  CreateUserConfigurationRequest,
  DepositWithdrawClientRequest,
  SetTradingConfigurationStateRequest,
  UpdateConfigurationRequest,
} from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const createTradingConfiguration = createAsyncThunk<ConfigurationDetail, CreateUserConfigurationRequest>(
  'tradingConfigurations/createTradingConfiguration',
  async (body, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.createTradingConfigurationV1TradingClientTradingConfigurationsPost(body)
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const updateTradingConfiguration = createAsyncThunk<
  ConfigurationDetail,
  { configurationId: number; body: UpdateConfigurationRequest }
>('tradingConfigurations/updateTradingConfiguration', async ({ configurationId, body }, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.updateConfigurationV1TradingClientTradingConfigurationsConfigurationIdPatch(
      configurationId,
      body
    )
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const getTradingConfigurations = createAsyncThunk<ConfigurationDetail[]>(
  'tradingConfigurations/getTradingConfigurations',
  async (_, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getUserTradingConfigurationsV1TradingClientTradingConfigurationsGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const getTradingConfigurationById = createAsyncThunk<ConfigurationDetail, number>(
  'tradingConfigurations/getTradingConfigurationById',
  async (configurationId, thunkAPI) => {
    try {
      const { data } =
        await generatedApi.v1.getUserTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdGet(
          configurationId
        )
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const deleteTradingConfiguration = createAsyncThunk<number, number>(
  'tradingConfigurations/deleteTradingConfiguration',
  async (configurationId, thunkAPI) => {
    try {
      await generatedApi.v1.deleteTradingConfigurationV1TradingClientTradingConfigurationsConfigurationIdDelete(
        configurationId
      )
      return configurationId
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const changeStatusTradingConfiguration = createAsyncThunk<
  ConfigurationDetail,
  SetTradingConfigurationStateRequest & { configurationId: number }
>('tradingConfigurations/changeStatusTradingConfiguration', async (body, thunkAPI) => {
  try {
    const { data } =
      await generatedApi.v1.setTradingConfigurationStatusV1TradingClientTradingConfigurationsConfigurationIdStatusPut(
        body.configurationId,
        body
      )
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const depositCashToTradingConfiguration = createAsyncThunk<
  ConfigurationDetail,
  DepositWithdrawClientRequest & { configurationId: number }
>('tradingConfigurations/depositCashToTradingConfiguration', async (body, thunkAPI) => {
  try {
    const { data } =
      await generatedApi.v1.tradingConfigurationDepositV1TradingClientTradingConfigurationsConfigurationIdDepositPost(
        body.configurationId,
        body
      )
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const withdrawCashFromTradingConfiguration = createAsyncThunk<
  DepositWithdrawClientRequest & { configurationId: number },
  DepositWithdrawClientRequest & { configurationId: number }
>('tradingConfigurations/withdrawCashFromTradingConfiguration', async (body, thunkAPI) => {
  try {
    await generatedApi.v1.tradingConfigurationWithdrawV1TradingClientTradingConfigurationsConfigurationIdWithdrawPost(
      body.configurationId,
      body
    )
    return body
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const updateTradingInfo = createAction<void>('tradingConfigurations/updateTradingInfo')

const tradingConfigurationsThunks = {
  withdrawCashFromTradingConfiguration,
  depositCashToTradingConfiguration,
  changeStatusTradingConfiguration,
  deleteTradingConfiguration,
  getTradingConfigurationById,
  getTradingConfigurations,
  createTradingConfiguration,
  updateTradingConfiguration,
  updateTradingInfo,
}

export default tradingConfigurationsThunks
