import React, { ReactElement } from 'react'
import styles from 'shared/components/Navbar/styles.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import NavLink, { NavLinkIcons } from 'shared/components/Navbar/NavLink'
import linkList from 'constants/navBarLinks'
import Break from 'shared/components/Break'
import { useNavigate } from 'react-router-dom'
import CredentialsBalance from 'features/credentials/components/CredentialsBalance'
import MissingCredentialsNotification from '../MissingCredentialsNotification'

const Navbar = ({ tabIndex }: { tabIndex?: number }): ReactElement => {
  const navigate = useNavigate()

  return (
    <>
      <Box className={styles.navbar}>
        <Typography variant={'inherit'} className={styles.logo}>
          NOSIS TRADING BOT
        </Typography>
        <Box className={styles.balance__container}>
          <Box className={styles.balance__amount__container}>
            <CredentialsBalance />
            <MissingCredentialsNotification />
          </Box>
        </Box>
      </Box>
      <Break margin={'24px'} />
      <Grid className={styles.links__container}>
        {linkList.map((link, index) => (
          <NavLink
            key={index}
            icon={link.icon as NavLinkIcons}
            title={link.title}
            isSelected={tabIndex === link.id}
            onClick={(): void => navigate(link.path)}
          />
        ))}
      </Grid>
    </>
  )
}

export default Navbar
