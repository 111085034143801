const navLinks = [
  {
    id: 1,
    icon: 'dashboard',
    title: 'Dashboard',
    path: '/',
  },
  {
    id: 2,
    icon: 'settings',
    title: 'Profile settings',
    path: '/profile',
  },
  {
    id: 3,
    icon: 'about',
    title: 'About the bot',
    path: '/about',
  },
];
export default navLinks;
