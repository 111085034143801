import { Stack } from '@mui/material'
import { FC } from 'react'
import { Statistic } from 'shared/components/Statistic'

import styles from './styles.module.scss'

interface Props {
  daily: number
  total: number
  average: number
}

export const ProfitStatistic: FC<Props> = ({ daily, total, average }) => {
  return (
    <Stack direction='row' spacing={3}>
      <Statistic label='Daily Profit' value={daily} suffix='USDT' className={styles.statisticItem} />
      <Statistic label='Total Profit' value={total} suffix='USDT' className={styles.statisticItem} />
      <Statistic label='Average return' value={average} suffix='%' className={styles.statisticItem} />
    </Stack>
  )
}
