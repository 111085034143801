import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { ErrorsFromBackendType } from 'shared/ts'

export interface ApiErrorPayload {
  detail?: ErrorsFromBackendType | string
}

export type ApiError = AxiosError<ApiErrorPayload>

type CreateAsyncThunkType = Parameters<typeof createAsyncThunk>[1]
type GetThunkAPI = Parameters<CreateAsyncThunkType>[1] & { rejectWithValue: any }

export const extractErrorMessage = (error: ApiError): string => {
  if (error?.response?.status === 401) {
    return 'Session expired. Please sign in to your account.'
  }

  let errorMessage = error?.response?.data?.detail ? '' : 'Unexpected server error.'

  if (error?.response?.data?.detail) {
    errorMessage += `${
      typeof error?.response?.data?.detail === 'string'
        ? error.response.data.detail
        : Object.values(error.response.data.detail).join(', ')
    }`
  }

  return errorMessage
}

export const handleErrors = (error: ApiError, thunkAPI: GetThunkAPI) => {
  handleApiErrorNotification(error)
  const errorMessage = extractErrorMessage(error)
  return thunkAPI.rejectWithValue(errorMessage)
}

type SetErrorType<T> = (key: keyof T, value: { message: T[keyof T] }) => void

export function handleResponseErrors<T>(
  response: PayloadAction<T, string, { arg: any; requestId: string; requestStatus: 'fulfilled' | 'rejected' }, never>,
  setError: SetErrorType<T> = () => null
) {
  if (response?.meta?.requestStatus === 'rejected') {
    const error = response?.payload as unknown as ApiErrorPayload
    if (error?.detail) {
      setError('msg' as keyof T, { message: Object.values(error.detail).join(', ') } as any)
    }
    return { isSucceeded: false }
  }

  return { isSucceeded: true }
}

export const handleApiErrorNotification = (error: ApiError) => {
  const originalRequest = error.config as AxiosRequestConfig & {
    _retry: undefined | boolean
    queueIndex: number
  }
  const isQueueRequest = Object.prototype.hasOwnProperty.call(originalRequest, 'queueIndex')

  if (error?.response?.status === 401 ? originalRequest._retry : !isQueueRequest) {
    const errorMsg = extractErrorMessage(error)
    toast.error(errorMsg)
  }
}
