import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import useTradingTotalProfit from 'features/trading/hooks/useTradingTotalProfit'
import { useState } from 'react'
import { DateChart } from 'shared/components/DateChart'
import { DateRangePeriodsEnum } from 'shared/ts/dateRanges'
import { calculateDateRangeFromPeriod } from 'shared/utils/calculateDateRange'

export const TotalProfitSection = () => {
  const initialPeriod = DateRangePeriodsEnum.Day
  const [period, setPeriod] = useState(initialPeriod)

  const { totalProfit, isLoading, setParams } = useTradingTotalProfit({
    defaultPeriod: initialPeriod,
  })

  const handleChange = (event: React.MouseEvent<HTMLElement>, period: DateRangePeriodsEnum) => {
    setParams(params => ({ ...params, ...calculateDateRangeFromPeriod(period) }))
    setPeriod(period)
  }

  return (
    <Box
      sx={{
        marginTop: '2%',
        backgroundColor: '#fff',
        padding: '1% 2% 16px',
        height: 408,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '24px',
      }}
    >
      <Grid alignItems='center' justifyContent='space-between' container sx={{ marginBottom: 3 }}>
        <Grid item>
          <Typography variant='h6' component='div'>
            Total Profit
          </Typography>
        </Grid>
        <Grid item>
          <ToggleButtonGroup disabled={isLoading} color='primary' value={period} exclusive onChange={handleChange}>
            <ToggleButton value={DateRangePeriodsEnum.Day}>Day</ToggleButton>
            <ToggleButton value={DateRangePeriodsEnum.Week}>Week</ToggleButton>
            <ToggleButton value={DateRangePeriodsEnum.Month}>Month</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <DateChart isLoading={isLoading} chartData={totalProfit?.chart_data} />
    </Box>
  )
}
