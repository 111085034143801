import React, { ReactElement } from 'react';
import { Box, Button, Typography } from '@mui/material';
import styles from 'features/auth/components/ForgotPasswordForm/styles.module.scss';
import TextInput from 'shared/components/FormComponents/TextInput';
import SubmitButton from 'shared/components/FormComponents/SubmitButton';
import { useForm } from 'react-hook-form';
import { ResetPasswordLinkRequestDTO } from 'features/auth/ts';
import { Link, useNavigate } from 'react-router-dom';
import { resetPasswordLinkAPIRequest } from 'features/auth/api';
import { ROUTES } from 'constants/routes';
import useAuth from 'features/auth/hooks/useAuth';

const ForgotPasswordForm = (): ReactElement => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordLinkRequestDTO>();
  const { isLoading } = useAuth();
  const navigate = useNavigate();

  const onSubmit = (data: ResetPasswordLinkRequestDTO): void => {
    resetPasswordLinkAPIRequest(data)
      .then(() => navigate('/login', { state: { isPasswordReset: true } }))
      .catch(error => {
        setError('email', { type: 'validate', message: error?.response.data.detail.email });
      });
  };

  return (
    <Box className={styles.forgot__form__container}>
      <Typography variant={'inherit'} className={styles.forgot__form__name}>
        Forgot your password?
      </Typography>
      <Typography variant={'inherit'} className={styles.forgot__help__text}>
        We’ll help you reset it and get back on track
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={isLoading ? styles.forgot__form__loading : null}>
        <TextInput
          id={'email'}
          register={register('email', {
            required: { value: true, message: 'This field is required' },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          label={'Email Address'}
          placeholder={'Email'}
          fullwidth={true}
          error={errors.email?.message}
        />

        <SubmitButton text={'Reset password'} fullWidth={true} disabled={isLoading} />
        <Link className={styles.forgot__form__go__back__link} to={ROUTES.login}>
          <Button className={styles.forgot__form__go__back__button}>Back</Button>
        </Link>
      </form>
    </Box>
  );
};

export default ForgotPasswordForm;
