import React, { ReactElement } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import ConfigurationCard from 'features/trading/components/ConfigurationList/ConfigurationCard'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import styles from './styles.module.scss'
import Loader from 'shared/components/Loader'
import EmptyCard from 'features/trading/components/ConfigurationList/EmptyCard'
import Carousel from 'shared/components/Carousel'

const ConfigurationList = ({
  handleAddConfiguration,
  index,
  setIndex,
}: {
  handleAddConfiguration: () => void
  index: number
  setIndex: () => void
}): ReactElement => {
  const { isLoading, configurations: configurationList } = useTradingConfigurations(false)

  return (
    <Box>
      {isLoading ? (
        <Grid container className={styles.loader__container}>
          <Loader />
        </Grid>
      ) : configurationList.length ? (
        <Box className={styles.configurations__container}>
          <Carousel activeCardsAmount={3} index={index}>
            {configurationList.map(configuration => (
              <ConfigurationCard key={configuration.id} setIndex={setIndex} configuration={configuration} />
            ))}
          </Carousel>
        </Box>
      ) : (
        <>
          <Grid container className={styles.empty__configurations__container}>
            <EmptyCard />
            <EmptyCard
              content={
                <Box className={styles.empty__card}>
                  <Box>
                    <Typography className={styles.empty__card__heading}>You don’t have any configurations</Typography>
                    <Typography className={styles.empty__card__subtitle}>Create your first configuration</Typography>
                    <Button onClick={handleAddConfiguration} className={styles.empty__card__button}>
                      Add new configuration
                    </Button>
                  </Box>
                </Box>
              }
            />
            <EmptyCard />
          </Grid>
        </>
      )}
    </Box>
  )
}

export default ConfigurationList
