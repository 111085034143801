import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import FloatInput from 'shared/components/FormComponents/FloatInput'
import { Box, Grid, Typography } from '@mui/material'
import styles from 'features/trading/components/ConfigurationDepositModal/DepositConfigurationForm/styles.module.scss'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import Loader from 'shared/components/Loader'
import useCredentials from 'features/credentials/hooks/useCredentials'
import { ConfigurationDetail, DepositWithdrawClientRequest } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'

const DepositConfigurationForm = ({
  configuration,
  onSuccess,
}: {
  configuration: ConfigurationDetail
  onSuccess: () => void
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<DepositWithdrawClientRequest>()
  const { credentials } = useCredentials(false)
  const { depositCashToTradingConfiguration, isUpdated } = useTradingConfigurations(false)

  const balance = useMemo(
    () =>
      credentials?.find(i => i?.trading_platform_id === configuration?.trading_platform?.id)?.balances?.available ?? 0,
    [credentials]
  )

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (data: DepositWithdrawClientRequest) => {
    if (data.amount) {
      setIsLoading(true)
      depositCashToTradingConfiguration({
        configurationId: configuration.id,
        amount: data.amount,
      })
    } else {
      setError('amount', { message: 'Can not deposit 0 USDT' })
    }
  }

  useEffect(() => {
    if (isUpdated) {
      setIsLoading(false)
      onSuccess()
    }
  }, [isUpdated])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant={'inherit'} className={styles.trading__pair__label}>
            {'Amount you`d like to deposit'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'inherit'} className={styles.available__funds__label}>
            Available cash: {balance} USDT
          </Typography>
        </Grid>
      </Grid>
      <FloatInput
        register={register('amount', {
          valueAsNumber: true,
          required: { value: true, message: 'This field is required!' },
          max: {
            value: balance,
            message: 'Amount can not be higher than available funds.',
          },
        })}
        currencyLabel={'USDT'}
        fullwidth={true}
        placeholder={'100'}
        error={errors.amount?.message}
      />
      {isLoading ? (
        <Box className={styles.loader__container}>
          <Loader />
        </Box>
      ) : (
        <SubmitButton text={'Deposit'} fullWidth={true} />
      )}
    </form>
  )
}

export default DepositConfigurationForm
