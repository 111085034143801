import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { ScriptableContext } from 'chart.js/auto';

import { DateRangePeriodsEnum } from 'shared/ts/dateRanges';
import { calculateDateRangeFromPeriod } from 'shared/utils/calculateDateRange';
import Loader from 'shared/components/Loader';

import { getOptions } from './lib';

interface Props {
  chartData?: { time: number; value: string | number }[];
  isLoading?: boolean;
}

export const DateChart: FC<Props> = ({ chartData = [], isLoading }) => {
  const data = useMemo(() => {
    return {
      labels: chartData.map(i => i.time),
      datasets: [
        {
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(0, 'rgba(9, 132, 227, 0.16)');
            gradient.addColorStop(1, 'rgba(9, 132, 227, 0)');
            return gradient;
          },
          borderColor: 'rgba(9, 132, 227, 1)',
          fill: 'start',
          data: chartData.map(i => i.value),
          pointBackgroundColor: 'rgba(9, 132, 227, 1)',
          pointHoverBackgroundColor: 'rgba(9, 132, 227, 1)',
        },
      ],
    };
  }, [chartData]);

  const options = useMemo(
    () =>
      getOptions(
        chartData.length > 1
          ? {
              startDate: chartData[0].time,
              endDate: chartData[chartData.length - 1].time,
            }
          : {
              startDate: calculateDateRangeFromPeriod(DateRangePeriodsEnum.Day).date_from,
              endDate: calculateDateRangeFromPeriod(DateRangePeriodsEnum.Day).date_to,
            }
      ),
    [chartData]
  );

  return (
    <Box sx={{ flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      {isLoading ? <Loader /> : <Chart type="line" options={options} data={data} />}
    </Box>
  );
};
