import React, { ReactElement, useRef } from 'react'
import { Box, Grid, Modal, Typography } from '@mui/material'
import styles from 'shared/components/ModalPopup/styles.module.scss'
import useOnClickOutside from 'shared/hooks/useOnClickOutside'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

type ModalPopupParams = {
  open: boolean
  setOpen: (state: boolean) => void
  title: string
  content: ReactElement
  iconTitle?: ReactElement
  titleAlign?: 'center' | 'left'
}

const ModalPopup = ({
  title,
  open,
  setOpen,
  content,
  iconTitle,
  titleAlign = 'center',
}: ModalPopupParams): ReactElement => {
  const ref = useRef()

  useOnClickOutside(ref, () => setOpen(false))

  return (
    <Modal className={styles.modal__base} open={open}>
      <Grid container height={'100vh'} width={'100%'}>
        <Grid item xs={3.5}></Grid>
        <Grid item xs={5}>
          <Grid container className={styles.modal__container}>
            <Box ref={ref} className={styles.modal__inner__container}>
              <Grid container className={styles.header_button}>
                <Grid item className={styles.arrow__back}>
                  <ArrowBackIcon onClick={(): void => setOpen(false)} width={24} sx={{ cursor: 'pointer' }} />
                </Grid>
                <Grid item className={styles.header__container} sx={{ textAlign: titleAlign }}>
                  {iconTitle}
                  <Typography variant={'inherit'} className={styles.header__label}>
                    {title}
                  </Typography>
                </Grid>
                <Grid item className={styles.arrow__close}>
                  <CloseIcon onClick={(): void => setOpen(false)} width={24} className={styles.close__button} />
                </Grid>
              </Grid>
              <Box className={styles.content__container}>{content}</Box>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={3.5}></Grid>
      </Grid>
    </Modal>
  )
}

export default ModalPopup
