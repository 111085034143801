import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import styles from './styles.module.scss'

type EmptyCardParams = {
  content?: ReactElement
}

const EmptyCard = ({ content }: EmptyCardParams): ReactElement => {
  return (
    <Grid item xs={'auto'} className={styles.card__container}>
      {content}
    </Grid>
  )
}

export default EmptyCard
