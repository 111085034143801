import { AxiosRequestConfig } from 'axios'

import { api } from 'api/v1'

export interface RefreshWaitingQueueItem {
  resolve: (accessToken: string) => unknown
  reject: (error: unknown) => unknown
  request: AxiosRequestConfig
}

/**
 * When one refresh-token call is in progress, all other attempts will be waiting the first to be resolved
 * to reuse it's response
 */
export class RefreshWaitingQueue {
  isRefreshing = false
  #refreshWaitingQueue: RefreshWaitingQueueItem[] = []
  
  push(request: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
      this.#refreshWaitingQueue.push({ resolve, reject, request })
    })
      .then(token => {
        request.headers['Authorization'] = 'Bearer ' + token
        return api(request)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
  
  resolveQueued(accessToken: string) {
    for (const promise of this.#refreshWaitingQueue) {
      promise.resolve(accessToken)
    }
    this.#refreshWaitingQueue = []
  }
  
  rejectQueued(error: any) {
    this.#refreshWaitingQueue.forEach((promise, index) => {
      promise.reject({ ...error, config: { ...error?.config, queueIndex: index } })
    })
    
    this.#refreshWaitingQueue = []
  }
  
  get pendingCount() {
    return this.#refreshWaitingQueue.length
  }
}