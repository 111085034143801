import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit'
import { OrderDetail } from 'api/generated'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import { uniqBy } from 'lodash'
import tradingOrdersThunks from './tradingOrders.thunks'

const { getPaginatedOrders, getOpenOrders } = tradingOrdersThunks
const { logout } = authThunks

export interface PaginationInterface {
  total: number
  page: number
  size: number
  ordering: string
}

export interface TradingOrdersInitialState {
  isLoading: boolean
  orders: OrderDetail[]
  error: string
  paginate: Record<number, PaginationInterface>
  getOpenOrdersState: Record<number, OrderDetail[]>
  historicalOrdersState: Record<number, OrderDetail[]>
}

const initialState: TradingOrdersInitialState = {
  isLoading: false,
  orders: [],
  error: '',
  paginate: {} as Record<number, PaginationInterface>,
  getOpenOrdersState: {} as Record<number, OrderDetail[]>,
  historicalOrdersState: {} as Record<number, OrderDetail[]>,
}

export const tradingOrdersSlice = createSlice({
  name: 'tradingOrders',
  initialState,
  reducers: {
    updateOpenOrders: (state, action: PayloadAction<OrderDetail>) => {
      state.getOpenOrdersState[0] = [action.payload, ...(state.getOpenOrdersState[0] ?? [])]
      state.getOpenOrdersState[action.payload.trading_configuration_id] = [
        action.payload,
        ...(state.getOpenOrdersState[action.payload.trading_configuration_id] ?? []),
      ]
    },
    deleteClosedOrdersFromOpenState: (state, action: PayloadAction<OrderDetail>) => {
      state.getOpenOrdersState[0] = state.getOpenOrdersState[0].filter(order => order.id !== action.payload.id)
      state.getOpenOrdersState[action.payload.trading_configuration_id] = state.getOpenOrdersState[
        action.payload.trading_configuration_id
      ].filter(order => order.id !== action.payload.id)
    },
    clearHistoricalOrders: state => {
      return {
        ...state,
        paginate: {},
        historicalOrdersState: {},
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(isPending(getPaginatedOrders, getOpenOrders), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isFulfilled(getOpenOrders), (state, action) => {
        state.isLoading = false
        state.getOpenOrdersState[(action.meta.arg.configuration_id as number) ?? 0] = [...(action.payload.items ?? [])]
      })
      .addMatcher(isFulfilled(getPaginatedOrders), (state, action) => {
        state.isLoading = false
        if (action.meta.arg.sort) {
          state.historicalOrdersState[(action.meta.arg.configuration_id as number) ?? 0] = action.payload.items ?? []
        } else {
          state.historicalOrdersState[(action.meta.arg.configuration_id as number) ?? 0] = uniqBy(
            [
              ...(state.historicalOrdersState[(action.meta.arg.configuration_id as number) ?? 0] ?? []),
              ...action.payload.items,
            ],
            'id'
          )
        }

        state.paginate[(action.meta.arg.configuration_id as number) ?? 0] = {
          total: action.payload?.total ?? 0,
          page: action.payload?.page ?? 1,
          size: action.meta.arg.size ?? 0,
          ordering: action.meta.arg.ordering ?? 'DESC',
        } as PaginationInterface
      })
      .addMatcher(isRejected(getOpenOrders, getPaginatedOrders), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const tradingOrdersActions = tradingOrdersSlice.actions

export const { reducer: tradingOrdersReducer } = tradingOrdersSlice

export default tradingOrdersReducer
