import { createAsyncThunk } from '@reduxjs/toolkit'
import { TrancheTypeDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getTrancheTypes = createAsyncThunk<TrancheTypeDetail[]>(
  'tradingTrancheTypes/getTrancheTypes',
  async (_, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getTrancheTypesV1GridStrategyClientTrancheTypesGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const tradingTrancheTypesThunks = {
  getTrancheTypes,
}

export default tradingTrancheTypesThunks
