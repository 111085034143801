import React from 'react'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Radio, RadioGroup, Typography } from '@mui/material'
import styles from 'features/trading/components/CreateConfigurationForm/CrossConfigurationFields/styles.module.scss'
import { crossStrategyIntervals } from 'constants/tradingConfigurations'
import { ConfigurationDetail, CrossStrategyIntervalEnum } from 'api/generated'
import { CreateUpdateConfigurationDTO } from '../index'

type CrossConfigurationFieldsParams = {
  register: UseFormRegister<CreateUpdateConfigurationDTO>
  errors: FieldErrors<CreateUpdateConfigurationDTO>
  configuration?: ConfigurationDetail
  control: Control<CreateUpdateConfigurationDTO, unknown>
}

const CrossConfigurationFields = ({ register, errors, configuration, control }: CrossConfigurationFieldsParams) => {
  const intervals = Object.values(crossStrategyIntervals)
  return (
    <>
      <Typography variant={'inherit'} className={styles.text}>
        Interval
      </Typography>
      <Controller
        name='cross_strategy_details.interval'
        control={control}
        rules={{ required: { value: true, message: 'This field is required' } }}
        render={({ field }) => (
          <RadioGroup {...field} row value={field.value ?? CrossStrategyIntervalEnum.Type15M}>
            {intervals.map((value: string, index: number) => (
              <FormControlLabel key={index} value={value} control={<Radio />} label={value} className={styles.radio} />
            ))}
          </RadioGroup>
        )}
      />
    </>
  )
}

export default CrossConfigurationFields
