import { addDays, addMonths, addWeeks } from 'date-fns';
import { DateRangePeriodsEnum, IDateRange } from 'shared/ts/dateRanges';

export const calculateDateRangeFromPeriod = (period: DateRangePeriodsEnum): IDateRange => {
  switch (period) {
    case DateRangePeriodsEnum.Day: {
      return { date_from: addDays(Date.now(), -1).getTime(), date_to: Date.now() };
    }
    case DateRangePeriodsEnum.Week: {
      return { date_from: addWeeks(Date.now(), -1).getTime(), date_to: Date.now() };
    }
    case DateRangePeriodsEnum.Month: {
      return { date_from: addMonths(Date.now(), -1).getTime(), date_to: Date.now() };
    }
  }
};
