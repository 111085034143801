import React, { ReactElement } from 'react';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

type FiltersSelectParams = {
  options: Array<{ label: string; value: string }>;
  onChange: (order: string) => void;
  value: string;
};

const SortSelect = ({ options, onChange, value }: FiltersSelectParams): ReactElement => {
  return (
    <Box>
      <select
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => onChange(e.target.value)}
        className={styles.filters__select}
        value={value}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Box>
  );
};

export default SortSelect;
