import * as _ from 'lodash'
import { ErrorsFromBackendType } from 'shared/ts'
import { DeepMap, FieldError, FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form'
import { round } from 'lodash'

export function setFormErrors(
  errorsFromServer: ErrorsFromBackendType | null,
  // eslint-disable-next-line
  setError: UseFormSetError<any>
): void {
  if (errorsFromServer) {
    Object.entries(errorsFromServer).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((el, index) => {
          if (_.isObject(el) && !Array.isArray(el)) {
            Object.entries(el).forEach(([innerKey, innerValue]) => {
              setError(`${key}[${index}].${innerKey}`, {
                type: 'server',
                message: Array.isArray(innerValue) && innerValue.length > 0 ? innerValue[0] : innerValue,
              })
            })
          } else {
            setError(key, { type: 'server', message: el })
          }
        })
      } else if (_.isPlainObject(value)) {
        Object.entries(value).forEach(([innerKey, innerValue]) => {
          if (Array.isArray(innerValue)) {
            innerValue.forEach((el: string) => {
              setError(`${key}.${innerKey}`, { type: 'server', message: el })
            })
          } else {
            setError(`${key}.${innerKey}`, { type: 'server', message: innerValue as string })
          }
        })
      } else {
        setError(key, { type: 'server', message: value })
      }
    })
  }
}

export function clearServerFormErrors(
  errors: DeepMap<FieldValues, FieldError>,
  // eslint-disable-next-line
  clearErrors: UseFormClearErrors<any>
): void {
  if (errors) {
    Object.entries(errors).forEach(([key, value]) => {
      if (value['type'] === 'server') clearErrors(key)
    })
  }
}

export function toFixed(x: number): string {
  let b = ''
  if (Math.abs(x) < 0.1) {
    const e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      b = '0.' + new Array(e).join('0') + round(x, 5).toString().substring(2)
    } else {
      return String(x)
    }
  } else {
    return String(round(x, 5))
  }
  return b
}
