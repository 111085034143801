import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { TradingPlatformDetail } from 'api/generated'
import tradingPlatformsThunks from './tradingPlatforms.thunks'
const { getTradingPlatforms } = tradingPlatformsThunks

export interface TradingPlatformsInitialState {
  isLoading: boolean
  platforms: TradingPlatformDetail[]
  error: string
}

const initialState: TradingPlatformsInitialState = {
  isLoading: false,
  platforms: [],
  error: '',
}

const tradingPlatformsSlice = createSlice({
  name: 'tradingPlatforms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(isPending(getTradingPlatforms), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isRejected(getTradingPlatforms), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
      .addMatcher(isFulfilled(getTradingPlatforms), (state, action) => {
        state.isLoading = false
        state.platforms = action.payload
      })
  },
})

export const tradingPlatformsActions = tradingPlatformsSlice.actions

export const { reducer: tradingPlatformsReducer } = tradingPlatformsSlice

export default tradingPlatformsReducer
