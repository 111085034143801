import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './styles.module.scss';

const GoBackButton = (): ReactElement => {
  const navigate = useNavigate();

  const navigateBack = (): void => navigate(-1);

  return (
    <button onClick={navigateBack} className={styles.button}>
      <ArrowBackIcon className={styles.icon} />
    </button>
  );
};

export default GoBackButton;
