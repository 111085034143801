import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import styles from 'shared/components/Carousel/CarouselCard/styles.module.scss';

type CarouselCardParams = {
  children: ReactElement;
  size: number;
};

const CarouselCard = ({ children, size }: CarouselCardParams): ReactElement => {
  return (
    <Box sx={{ flex: `1 0 ${size}%` }} className={styles.carousel__card}>
      {children}
    </Box>
  );
};

export default CarouselCard;
