import { api } from 'api';
import {
  CreateUpdateUserCredentialsDTO,
  LoginRequestDTO,
  LoginResponseDTO,
  RefreshTokenResponseDTO,
  ResetPasswordLinkRequestDTO,
  ResetPasswordRequestDTO,
  UpdateUserRequestDTO,
  UserCredentials,
  UserDTO,
} from 'features/auth/ts';
import { APIROUTES } from 'constants/routes';

export const loginAPIRequest = async (data: LoginRequestDTO): Promise<LoginResponseDTO> => {
  const response = await api.post(`${APIROUTES.auth}/login`, data);
  return response.data;
};

export const refreshTokenAPIRequest = async (): Promise<RefreshTokenResponseDTO> => {
  const response = await api.post(`${APIROUTES.auth}/refresh-access-token`);
  return response.data;
};

export const updateUserAPIRequest = async (data: UpdateUserRequestDTO): Promise<UserDTO> => {
  const response = await api.patch(`${APIROUTES.auth}/account`, data);
  return response.data;
};

export const resetPasswordLinkAPIRequest = async (
  data: ResetPasswordLinkRequestDTO
): Promise<string> => {
  const response = await api.post(`${APIROUTES.auth}/reset-password/link`, data);
  return response.data;
};

export const resetPasswordAPIRequest = async (data: ResetPasswordRequestDTO): Promise<null> => {
  const response = await api.patch(`${APIROUTES.auth}/reset-password`, data);
  return response.data;
};

export const getUserCredentialsAPIRequest = async (
  tradingPlatform: string
): Promise<UserCredentials> => {
  const response = await api.get(`${APIROUTES.trading}/credentials`, {
    params: {
      trading_platform: tradingPlatform,
    },
  });
  return response.data;
};

export const setUserCredentialsAPIRequest = async (
  data: CreateUpdateUserCredentialsDTO
): Promise<UserCredentials> => {
  const response = await api.patch(`${APIROUTES.trading}/credentials`, data);
  return response.data;
};
