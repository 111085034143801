import { createAsyncThunk } from '@reduxjs/toolkit'
import { StrategyDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getStrategies = createAsyncThunk<StrategyDetail[]>(
  'tradingStrategies/getStrategies',
  async (params, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getStrategiesAvailableToClientV1TradingClientStrategiesGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const tradingStrategiesThunks = {
  getStrategies,
}

export default tradingStrategiesThunks
