import { Alert, Box, Button, Grid, Snackbar } from '@mui/material'
import ConfigurationList from 'features/trading/components/ConfigurationList'
import CreateUpdateConfigurationModal from 'features/trading/components/CreateUpdateConfigurationModal'
import OrderTable from 'features/trading/components/OrderTable'
import { TotalProfitSection } from 'features/trading/components/TotalProfitSection'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import useTradingOrders from 'features/trading/hooks/useTradingOrders'
import useTradingPlatforms from 'features/trading/hooks/useTradingPlatforms'
import useTradingStrategies from 'features/trading/hooks/useTradingStrategies'
import styles from 'pages/ConfigurationsPage/styles.module.scss'
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NavPageContainer from 'shared/components/NavPageContainer'
import PageContainer from 'shared/components/PageContainer'
import { Socket } from 'socket.io-client'
import useTradingStopStrategies from 'features/trading/hooks/useTradingStopStrategies'
import useTradingTrancheTypes from 'features/trading/hooks/useTradingTrancheTypes'
import { useOpenOrders } from 'features/trading/hooks/useOpenOrders'
import { useHistoricalOrders } from 'features/trading/hooks/useHistoricalOrders'
import { OrderingEnum } from 'api/generated'
import useCredentials from 'features/credentials/hooks/useCredentials'

const ConfigurationsPage = ({ tabIndex }: { tabIndex: number; ws?: Socket }): ReactElement => {
  const { isLoading: isLoadingTradingPlatforms } = useTradingPlatforms()
  const { configurations, isUpdated, toggleIsUpdated } = useTradingConfigurations()
  const { isLoading: isLoadingStrategies } = useTradingStrategies()
  useTradingStopStrategies()
  useTradingTrancheTypes()
  const { credentials } = useCredentials(false)

  const hasTradingPlatforms = credentials?.length ? credentials?.length > 0 : false
  const { getPaginatedOrders } = useTradingOrders()
  const { openOrders: getAllOpenOrders } = useOpenOrders()
  const {
    historicalOrders: getAllHistoricalOrders,
    isLoading: isLoadingHistoricalOrders,
    paginate,
  } = useHistoricalOrders()

  useEffect(() => {
    if (isUpdated) {
      toggleIsUpdated()
    }
  }, [isUpdated])

  const [balanceError, setBalanceError] = useState<boolean>(false)

  const [isCreating, setIsCreating] = useState(false)
  const handleAddConfiguration = (): void => setIsCreating(!isCreating)

  const hasMoreClosedOrders = paginate?.page * paginate?.size < paginate?.total
  const [index, setIndex] = useState(0)

  const handleSlideLeft = (): void => setIndex(index - 1)
  const handleSlideRight = (): void => setIndex(index + 1)
  const handleGetNextPage = (page: number, status: string, ordering: string, configuration_id?: number): void => {
    if (!isLoadingHistoricalOrders && paginate?.page !== page) {
      getPaginatedOrders({
        page,
        size: 6,
        statuses: status,
        configuration_id,
        ordering: ordering === 'DESC' ? OrderingEnum.DESC : OrderingEnum.ASC,
      })
    }
  }

  const [missingCredentialsError, setMissingCredentialsError] = useState<boolean>(false)

  const onMissingCredentialsErrorClose = () => {
    setMissingCredentialsError(false)
  }

  const navigate = useNavigate()

  const navigateToSettings = () => {
    navigate('/profile')
  }

  const onBalanceErrorClose = () => {
    setBalanceError(false)
  }

  return (
    <NavPageContainer tabIndex={tabIndex}>
      <PageContainer
        label={'Configurations'}
        tooltip={
          <Grid container spacing={2}>
            <Grid item>
              <Button
                disabled={index < 1}
                className={styles.slide__button__left}
                classes={{ disabled: styles.slide__button__disabled }}
                onClick={handleSlideLeft}
              >
                {'<'}
              </Button>
              <Button
                className={styles.slide__button__right}
                onClick={handleSlideRight}
                classes={{ disabled: styles.slide__button__disabled }}
                disabled={index === configurations.length - 3 || configurations.length < 4}
              >
                {'>'}
              </Button>
            </Grid>

            <Grid item>
              <button
                className={styles.create__configuration__button}
                onClick={handleAddConfiguration}
                disabled={isLoadingStrategies || isLoadingTradingPlatforms || !hasTradingPlatforms}
              >
                Add new
              </button>
            </Grid>
          </Grid>
        }
      >
        <>
          <Box sx={{ marginRight: '-2.5%' }}>
            <ConfigurationList
              index={index}
              handleAddConfiguration={handleAddConfiguration}
              setIndex={() => setIndex(0)}
            />
          </Box>
          <TotalProfitSection />
          <OrderTable orders={getAllOpenOrders} hasMore={false} statusName={'OPEN'} label={'Open orders'} />
          <OrderTable
            hasMore={hasMoreClosedOrders}
            fetchData={handleGetNextPage}
            orders={getAllHistoricalOrders}
            statusName={'CANCELLED,CLOSED,PENDING,FAILED'}
            label={'Historical orders'}
          />
          <CreateUpdateConfigurationModal open={isCreating} setOpen={setIsCreating} />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={missingCredentialsError}
            message='Note archived'
          >
            <Alert onClose={onMissingCredentialsErrorClose} onSubmit={navigateToSettings} severity='error'>
              Please provide your trading platform credentials
              <button className={styles.navigate__button} onClick={navigateToSettings}>
                Provide
              </button>
            </Alert>
          </Snackbar>
          <Snackbar open={balanceError} message='Note archived'>
            <Alert onClose={onBalanceErrorClose} severity='error'>
              Seems that you have manually withdrawn your funds from the spot wallet. To make your trading
              configurations work properly please top up your USDT balance.
            </Alert>
          </Snackbar>
        </>
      </PageContainer>
    </NavPageContainer>
  )
}

export default ConfigurationsPage
