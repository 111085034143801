import { Box, Typography } from '@mui/material'
import React from 'react'
import CredentialsView from '../CredentialsView'
import styles from './styles.module.scss'

const CredentialsSetting = () => {
  return (
    <>
      <Box className={styles.content}>
        <Box className={styles.content__container}>
          <Typography className={styles.content__header}>Credentials</Typography>
        </Box>
        <CredentialsView />
      </Box>
    </>
  )
}

export default CredentialsSetting
