import { bindActionCreators } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector, useAppDispatch, RootState } from 'store'
import { calculateDateRangeFromPeriod } from 'shared/utils/calculateDateRange'
import { DateRangePeriodsEnum, IDateRange } from 'shared/ts/dateRanges'
import { ConfigurationProfitChartResponse } from 'api/generated'
import { tradingProfitsActions } from '../redux/tradingProfits/tradingProfits.slice'
import tradingProfitsThunks from '../redux/tradingProfits/tradingProfits.thunks'

const useTradingConfigurationProfit = (configurationId: number) => {
  const configurationProfitState = (state: RootState): ConfigurationProfitChartResponse | null =>
    state.tradingProfits.configurationProfit
  const isLoadingState = (state: RootState): boolean => state.tradingProfits.configurationProfitIsLoading
  const configurationProfit = useAppSelector(configurationProfitState)

  const isLoading = useAppSelector(isLoadingState)

  const [params, setParams] = useState<IDateRange>(calculateDateRangeFromPeriod(DateRangePeriodsEnum.Day))
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingProfitsActions,
    ...tradingProfitsThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getConfigurationProfitChart } = actionsDispatch

  useEffect(() => {
    getConfigurationProfitChart({ ...params, configurationId })
  }, [params, configurationId])

  return useMemo(() => {
    return {
      getConfigurationProfitChart,
      configurationProfit,
      isLoading,
      setParams,
    }
  }, [configurationProfit, isLoading])
}

export default useTradingConfigurationProfit
