import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { StrategyDetail } from 'api/generated'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import tradingStrategiesThunks from './tradingStrategies.thunks'
const { getStrategies } = tradingStrategiesThunks
const { logout } = authThunks

export interface StrategiesInitialState {
  isLoading: boolean
  strategies: StrategyDetail[]
  error: string
}

const initialState: StrategiesInitialState = {
  isLoading: false,
  strategies: [],
  error: '',
}

const tradingStrategiesSlice = createSlice({
  name: 'tradingStrategies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(isPending(getStrategies), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isFulfilled(getStrategies), (state, action) => {
        state.isLoading = false
        state.strategies = action.payload
      })
      .addMatcher(isRejected(getStrategies), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const tradingStrategiesActions = tradingStrategiesSlice.actions

export const { reducer: tradingStrategiesReducer } = tradingStrategiesSlice

export default tradingStrategiesReducer
