import React, { ReactElement } from 'react'
import styles from 'shared/components/FormComponents/SelectInput/styles.module.scss'
import { Control, Controller, ValidationRule } from 'react-hook-form'
import Select from 'react-select'
import { Box, Typography } from '@mui/material'

type AutoCompleteInputProps = {
  options: Array<{ label?: string; value?: number | string; disabled?: boolean }>
  error?: string
  id: string
  label?: string
  control?: Control<any, unknown>
  disabled?: boolean
  placeholder?: string
  rules?: Omit<Partial<{ required: { value: ValidationRule<boolean>; message: string } }>, string>
  isLoading?: boolean
}

const AutoCompleteInput = ({
  options,
  error,
  id,
  label,
  control,
  disabled,
  placeholder,
  rules,
  isLoading = false,
}: AutoCompleteInputProps): ReactElement => {
  const customSelectStyles = {
    control: () => {
      return { display: 'flex', height: '100%' }
    },
  }
  return (
    <Controller
      name={id}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <Box className={label ? styles.input__container : styles.input__container__no__label} >
          <Typography variant={'inherit'} className={styles.input__label}>
            {label}
          </Typography>
          <Select
            ref={ref}
            styles={customSelectStyles}
            className={styles.input}
            placeholder={placeholder}
            isDisabled={disabled}
            value={options.find(option => option.value === value)}
            options={options}
            onChange={val => {
              onChange(val?.value)
            }}
            isLoading={isLoading}
          />
          {error && (
            <Typography className={styles.input__error_message} variant={'inherit'} color={'error'}>
              {error}
            </Typography>
          )}
        </Box>
      )}
    />
  )
}

export default AutoCompleteInput
