import { toast } from 'react-toastify'

interface Options {
  withMessage?: boolean
}

const copyTextToClipBoard = async (text: string, options: Options = {}): Promise<void> => {
  const { withMessage = true } = options

  return navigator.clipboard.writeText(text.toString()).then(() => {
    if (withMessage) {
      toast.success('Text copied successfully')
    }
  })
}

export default copyTextToClipBoard
