import useTradingOrders from './useTradingOrders'
import useCredentials from 'features/credentials/hooks/useCredentials'
import useAuth from 'features/auth/hooks/useAuth'
import { useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import useTradingConfigurations from './useTradingConfigurations'
import { OrderDetail, OrderingEnum } from 'api/generated'

const { REACT_APP_API_URL } = process.env

type WithdrawUpdate = {
  amount: number
  trading_configuration_id: number
  withdraw_transaction_id: number
}

const useWebsockets = () => {
  const { updateOpenOrders, deleteClosedOrdersFromOpenState, getPaginatedOrders, clearHistoricalOrders } =
    useTradingOrders()

  const { credentials, getUserCredentialsById } = useCredentials(false)

  const { isAuth } = useAuth()
  const [webSocket, setWebSocket] = useState<Socket | undefined>(undefined)
  const accessToken = localStorage.getItem('access_token')

  const { configurations, getTradingConfigurationById } = useTradingConfigurations(false)

  const updateOpenOrderHandler = (data: OrderDetail) => {
    updateOpenOrders(data)
  }

  const updateOrdersHandler = (data: OrderDetail) => {
    deleteClosedOrdersFromOpenState(data)
    clearHistoricalOrders()
    const credential = credentials?.find(credential => credential.trading_platform_id === data.trading_platform_id)

    if (credential?.id) {
      getUserCredentialsById(credential?.id)
    }

    getTradingConfigurationById(data.trading_configuration_id)
    getPaginatedOrders({
      configuration_id: data.trading_configuration_id,
      page: 1,
      size: 6,
      statuses: 'CANCELLED,CLOSED,PENDING,FAILED',
      ordering: OrderingEnum.DESC,
    })
  }

  const withdrawHandler = (data: WithdrawUpdate) => {
    getTradingConfigurationById(data.trading_configuration_id)
    const trading_configuration = configurations?.find(
      configurations => configurations.id === data.trading_configuration_id
    )

    if (trading_configuration?.trading_platform.id) {
      getUserCredentialsById(trading_configuration?.trading_platform.id)
    }
  }

  const createSocketConnection = () => {
    const socketConnection = io(`${REACT_APP_API_URL}`, {
      path: '/ws/socket.io',
      transports: ['websocket'],
      auth: { token: accessToken },
    })
    socketConnection.removeAllListeners()
    setWebSocket(socketConnection)
  }

  useEffect(() => {
    if (accessToken && isAuth && !webSocket) {
      createSocketConnection()
    } else if (!accessToken && webSocket) {
      webSocket.disconnect()
      setWebSocket(undefined)
    }
  }, [accessToken, isAuth])

  useEffect(() => {
    if (isAuth) {
      webSocket?.on('ORDER_CREATED', updateOpenOrderHandler)
      webSocket?.on('ORDER_FINISHED', updateOrdersHandler)
      webSocket?.on('WITHDRAWAL_SUCCEDED', withdrawHandler)
    }
  }, [webSocket, isAuth])

  return {
    ws: webSocket,
  }
}

export default useWebsockets
