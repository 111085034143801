import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { CrossSubStrategyDetail } from 'api/generated'
import { tradingStrategiesActions } from '../redux/tradingSubStrategies/tradingSubStrategies.slice'
import tradingStrategiesThunks from '../redux/tradingSubStrategies/tradingSubStrategies.thunks'

const useTradingSubStrategies = () => {
  const strategiesState = (state: RootState): CrossSubStrategyDetail[] => state.tradingSubStrategies.subStrategies
  const isLoadingState = (state: RootState): boolean => state.tradingSubStrategies.isLoading
  const errorState = (state: RootState): string => state.tradingSubStrategies.error

  const hasStrategies = useAppSelector(createSelector(strategiesState, (item): boolean => !!item && item.length > 0))
  const subStrategies = useAppSelector(strategiesState)
  const strategyIdByName = useCallback(
    (name: string) => subStrategies?.filter(i => i.name === name)?.map(i => i.id)[0] || 0,
    [subStrategies]
  )
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingStrategiesActions,
    ...tradingStrategiesThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getSubStrategies } = actionsDispatch

  useEffect(() => {
    if (!hasStrategies && !isLoading) getSubStrategies()
  }, [hasStrategies])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasStrategies,
      subStrategies,
      isLoading,
      error,
      strategyIdByName,
    }
  }, [hasStrategies, subStrategies, isLoading, error, dispatch, strategyIdByName])
}

export default useTradingSubStrategies
