import { AxiosRequestConfig } from 'axios'

export const requestInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem('access_token');
  
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
}
