import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = (): ReactElement => {
  return (
    <div>
      Oops. This page doesn`t exist. <Link to={'/'}>Go home</Link>
    </div>
  );
};

export default NotFoundPage;
