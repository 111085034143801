import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetPaginatedOrdersV1TradingClientOrdersGetParams, PageOrderDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getOpenOrders = createAsyncThunk<PageOrderDetail, GetPaginatedOrdersV1TradingClientOrdersGetParams>(
  'tradingOrders/getOpenOrders',
  async (params, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getPaginatedOrdersV1TradingClientOrdersGet({
        statuses: 'OPEN',
        size: 100,
        ...params,
      })
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const getPaginatedOrders = createAsyncThunk<
  PageOrderDetail,
  GetPaginatedOrdersV1TradingClientOrdersGetParams & { sort?: boolean }
>('tradingOrders/getPaginatedOrders', async (params, thunkAPI) => {
  try {
    const { data } = await generatedApi.v1.getPaginatedOrdersV1TradingClientOrdersGet(params)
    return data
  } catch (e) {
    return handleErrors(e as ApiError, thunkAPI)
  }
})

const tradingOrdersThunks = {
  getPaginatedOrders,
  getOpenOrders,
}

export default tradingOrdersThunks
