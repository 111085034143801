import React, { useEffect } from 'react'
import ModalPopup from 'shared/components/ModalPopup'
import { Box, Typography } from '@mui/material'
import styles from 'features/trading/components/DeleteConfigurationModal/styles.module.scss'
import { configurationStatuses } from 'constants/tradingConfigurations'
import { ConfigurationDetail } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'

type DeleteConfigurationModalParams = {
  open: boolean
  setOpen: (flag: boolean) => void
  onSuccess?: () => void
  configuration: ConfigurationDetail
}

const DeleteConfigurationModal = ({
  open,
  setOpen,
  onSuccess = () => null,
  configuration,
}: DeleteConfigurationModalParams) => {
  const { deleteTradingConfiguration, isUpdated, updateTradingInfo } = useTradingConfigurations(false)

  const handleDelete = () => {
    deleteTradingConfiguration(configuration.id)
    onSuccess()
  }

  useEffect(() => {
    if (isUpdated) {
      setOpen(false)
      updateTradingInfo()
    }
  }, [isUpdated])

  const activeConfigurationContent = (
    <Box>
      <Typography variant={'inherit'} className={styles.content__text}>
        Please stop a trading configuration and withdraw all the funds in order to delete it
      </Typography>
      <button className={styles.button} onClick={() => setOpen(false)}>
        OK
      </button>
    </Box>
  )

  const depositedConfigurationContent = (
    <Box>
      <Typography variant={'inherit'} className={styles.content__text}>
        Please withdraw all the funds in order to delete it
      </Typography>
      <button className={styles.button} onClick={() => setOpen(false)}>
        OK
      </button>
    </Box>
  )

  const inActiveConfigurationContent = (
    <Box>
      <Typography variant={'inherit'} className={styles.content__text}>
        Are you sure you want to delete this trading configuration?
      </Typography>
      <button className={styles.delete__button} onClick={handleDelete}>
        Delete
      </button>
    </Box>
  )

  const getModalContent = () => {
    if (configuration?.balances?.cash_balance !== 0 && configuration.status === configurationStatuses.inActive) {
      return depositedConfigurationContent
    }
    switch (configuration.status) {
      case configurationStatuses.active:
        return activeConfigurationContent
      default:
        return inActiveConfigurationContent
    }
  }

  return (
    <ModalPopup
      open={open}
      setOpen={setOpen}
      title={`Delete configuration ${configuration.name}`}
      content={getModalContent()}
    />
  )
}

export default DeleteConfigurationModal
