import { bindActionCreators } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector, useAppDispatch, RootState } from 'store'
import { calculateDateRangeFromPeriod } from 'shared/utils/calculateDateRange'
import { DateRangePeriodsEnum, IDateRange } from 'shared/ts/dateRanges'
import { ConfigurationProfitChartResponse } from 'api/generated'
import { tradingProfitsActions } from '../redux/tradingProfits/tradingProfits.slice'
import tradingProfitsThunks from '../redux/tradingProfits/tradingProfits.thunks'

interface Props {
  defaultPeriod?: DateRangePeriodsEnum
}

const useTradingTotalProfit = ({ defaultPeriod = DateRangePeriodsEnum.Day }: Props = {}) => {
  const [params, setParams] = useState<IDateRange>(calculateDateRangeFromPeriod(defaultPeriod))
  const totalProfitState = (state: RootState): ConfigurationProfitChartResponse | null =>
    state.tradingProfits.totalProfit
  const isLoadingState = (state: RootState): boolean => state.tradingProfits.totalProfitIsLoading

  const totalProfit = useAppSelector(totalProfitState)
  const isLoading = useAppSelector(isLoadingState)

  const dispatch = useAppDispatch()
  const actions = {
    ...tradingProfitsActions,
    ...tradingProfitsThunks,
  }

  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getUserProfitChart } = actionsDispatch

  useEffect(() => {
    getUserProfitChart(params)
  }, [params])

  return useMemo(() => {
    return {
      getUserProfitChart,
      totalProfit,
      isLoading,
      setParams,
      params,
    }
  }, [totalProfit, isLoading, params])
}

export default useTradingTotalProfit
