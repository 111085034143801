import { createAsyncThunk } from '@reduxjs/toolkit'
import { StopStrategyDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'

const getStopStrategies = createAsyncThunk<StopStrategyDetail[]>(
  'tradingStopStrategies/getStopStrategies',
  async (params, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getStopStrategiesV1GridStrategyClientStopStrategiesGet()
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

const tradingStopStrategiesThunks = {
  getStopStrategies,
}

export default tradingStopStrategiesThunks
