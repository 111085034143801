import axios from 'axios';
import { refreshTokenAPIRequest } from 'features/auth/api';
import { RefreshWaitingQueue } from 'api/RefreshWaitingQueue';

const { REACT_APP_API_URL } = process.env;

export const api = axios.create({
  baseURL: `${REACT_APP_API_URL}`,
});

api.interceptors.request.use(
  async config => {
    const access_token = localStorage.getItem('access_token');
    if (access_token && config.headers) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

export const refreshTokenQueue = new RefreshWaitingQueue();

api.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (originalConfig.url !== '/auth/login' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        if (refreshTokenQueue.isRefreshing) {
          return await refreshTokenQueue.push(originalConfig);
        }

        refreshTokenQueue.isRefreshing = true;
        originalConfig._retry = true;
        try {
          const refreshToken = localStorage.getItem('refresh_token');

          localStorage.setItem('access_token', refreshToken ?? '');
          const rs = await refreshTokenAPIRequest();
          const accessToken = rs.access_token;
          localStorage.setItem('access_token', accessToken);

          refreshTokenQueue.isRefreshing = false;
          refreshTokenQueue.resolveQueued(accessToken);
          return api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);
