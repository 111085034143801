import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
  ConfigurationDetail,
  CreateCopyTradingStrategyRequest,
  CreateCrossStrategyRequest,
  CreateGridStrategyRequest,
  CrossStrategyIntervalEnum,
  CurrencyPairDetail,
  TradingConfigurationStrategiesEnum,
} from 'api/generated'
import { generatedApi } from 'api/v1'
import { requiredField } from 'constants/formRegisterOptions'
import trancheTypeNames from 'constants/trancheType'
import CrossConfigurationFields from 'features/trading/components/CreateConfigurationForm/CrossConfigurationFields'
import GridConfigurationFields from 'features/trading/components/CreateConfigurationForm/GridConfigurationFields'
import styles from 'features/trading/components/CreateConfigurationForm/styles.module.scss'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import useTradingStopStrategies from 'features/trading/hooks/useTradingStopStrategies'
import useTradingStrategies from 'features/trading/hooks/useTradingStrategies'
import useTradingTrancheTypes from 'features/trading/hooks/useTradingTrancheTypes'
import { getCurrencyPairPrice } from 'features/trading/redux/tradingCurrencyPairs/tradingCurrencyPairs.thunks'
import { CurrencyPairDetailsDTO } from 'features/trading/ts/currencyPairs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import AutoCompleteInput from 'shared/components/FormComponents/AutoCompleteInput'
import { SelectPaginate } from 'shared/components/FormComponents/SelectPaginate'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import TextInput from 'shared/components/FormComponents/TextInput'
import { toFixed } from 'shared/utils'
import { ISelectOption } from 'shared/components/FormComponents/SelectPaginate/types'
import Loader from 'shared/components/Loader'
import useCredentials from 'features/credentials/hooks/useCredentials'
import useTradingSubStrategies from 'features/trading/hooks/useSubTradingStrategies'
import { merge } from 'shared/utils/merge'

function uniqueList<T>(list: Array<{ label: string; value: T }>): { label: string; value: T }[] {
  return [...new Map(list.map(item => [item['label'], item])).values()]
}

export interface CreateUpdateConfigurationDTO {
  base_id?: { label: string; value: CurrencyPairDetail }
  quote_id?: number
  trading_platform_id: number

  name: string
  strategy: string
  grid_strategy_details?: CreateGridStrategyRequest
  cross_strategy_details?: CreateCrossStrategyRequest
  copy_trading_strategy_details?: CreateCopyTradingStrategyRequest
}

type CreateConfigurationFormParams = {
  onSuccess: (newState: boolean) => void
  configuration?: ConfigurationDetail
}

const CreateConfigurationForm = ({ onSuccess, configuration }: CreateConfigurationFormParams): ReactElement => {
  const { credentials, isLoading: isLoadingTradingPlatforms } = useCredentials(false)
  const tradingPlatformFromCredentials = credentials?.map(platform => ({
    value: platform.trading_platform_id,
    label: platform.trading_platform_name,
  }))
  const hasTradingPlatforms = !!tradingPlatformFromCredentials
  const { createTradingConfiguration, updateTradingConfiguration, isUpdated, error } = useTradingConfigurations(false)
  const { strategies, hasStrategies, isLoading: isLoadingStrategies, strategyIdByName } = useTradingStrategies()
  const { subStrategies, isLoading: isLoadingSubStrategies } = useTradingSubStrategies()
  const { stopStrategies } = useTradingStopStrategies()
  const { trancheTypes } = useTradingTrancheTypes()

  const [isLoading, setIsLoading] = useState(false)
  const [currencyPairs, setCurrencyPairs] = useState<CurrencyPairDetail[]>([])
  const [stopLossStrategy, setStopLossStrategy] = useState<number | undefined>(undefined)
  const [stopLossValue, setStopLossValue] = useState<string>('50')
  const [stopStrategyError, setStopStrategyError] = useState<boolean>(false)
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState<CurrencyPairDetail | undefined>()
  const [selectedCurrencyPairPrice, setSelectedCurrencyPairPrice] = useState<CurrencyPairDetailsDTO | undefined>()
  const tradingPlatformId = configuration?.trading_platform?.id ?? tradingPlatformFromCredentials?.map(i => i.value)[0]

  // For allowing to select both strategy/sub strategy from a single select
  const mergedStrategies = useMemo(
    () =>
      merge(
        strategies
          ?.map(strategy => ({
            strategyId: strategy.id,
            subStrategyId: null,
            label: strategy.name,
            strategyEnumValue: strategy.name as TradingConfigurationStrategiesEnum,
            value: strategy.name,
          }))
          ?.filter(strategy => strategy.label !== TradingConfigurationStrategiesEnum.CROSS),
        subStrategies?.map(subStrategy => ({
          strategyId: strategies?.find(strategy => strategy.name == TradingConfigurationStrategiesEnum.CROSS)?.id,
          subStrategyId: subStrategy.id,
          label: subStrategy.name,
          strategyEnumValue: TradingConfigurationStrategiesEnum.CROSS,
          value: `${TradingConfigurationStrategiesEnum.CROSS}_${subStrategy.id}`,
        }))
      ),
    [strategies, subStrategies]
  )
  const mergedStrategiesLoading = isLoadingStrategies || isLoadingSubStrategies

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<CreateUpdateConfigurationDTO>({
    defaultValues: {
      strategy: configuration?.strategy,
      trading_platform_id: tradingPlatformId,
      grid_strategy_details: {
        tranche_type_id: trancheTypes.find(trancheType => trancheType.name === 'PERCENTAGE')?.id,
        tranche_value: 1,
      },
    },
  })

  const strategy = watch('strategy')
  const base = watch('base_id')
  const baseId = base?.value?.base_id
  const quoteId = watch('quote_id')
  const interval = watch('cross_strategy_details.interval')
  const platformId = watch('trading_platform_id')

  const currentStrategy = useMemo(
    () => mergedStrategies?.find(item => item.value === strategy),
    [strategy, mergedStrategies]
  )

  useEffect(() => {
    if (configuration) {
      setValue('name', configuration.name)
      switch (configuration.strategy) {
        case TradingConfigurationStrategiesEnum.CROSS:
          if (configuration.cross_strategy_configuration) {
            setValue(
              'cross_strategy_details.interval',
              configuration.cross_strategy_configuration.interval as CrossStrategyIntervalEnum
            )
            setValue(
              'strategy',
              `${TradingConfigurationStrategiesEnum.CROSS}_${configuration.cross_strategy_configuration.sub_strategy.id}`
            )
          }
          break
        case TradingConfigurationStrategiesEnum.GRID:
          if (configuration.grid_strategy_configuration) {
            setValue(
              'strategy',
              TradingConfigurationStrategiesEnum.GRID
            )
            setValue('grid_strategy_details.step_percentage', configuration.grid_strategy_configuration.step_percentage)
            const trancheType = trancheTypes.find(
              trancheType => trancheType.name === configuration.grid_strategy_configuration?.tranche_type
            )
            if (trancheType) {
              setValue('grid_strategy_details.tranche_type_id', trancheType.id)
              setValue('grid_strategy_details.tranche_value', configuration.grid_strategy_configuration.tranche_value)
            }
            setValue(
              'grid_strategy_details.price_for_finish',
              +toFixed(configuration.grid_strategy_configuration.price_for_finish)
            )
            setValue(
              'grid_strategy_details.lower_price_threshold',
              +toFixed(configuration.grid_strategy_configuration.lower_price_threshold)
            )
            setValue(
              'grid_strategy_details.price_for_start',
              +toFixed(configuration.grid_strategy_configuration.price_for_start)
            )
            const stopStrategy = stopStrategies.find(
              stopStrategy => stopStrategy.name === configuration.grid_strategy_configuration?.stop_strategy
            )
            if (stopStrategy) {
              setStopLossStrategy(stopStrategy.id)
              setStopLossValue(String(configuration.grid_strategy_configuration.stop_strategy_value))
            }
          }
          break
      }
    }
  }, [configuration])

  useEffect(() => {
    if (configuration) {
      const configurationStrategyObj =
        configuration.strategy === TradingConfigurationStrategiesEnum.CROSS
          ? configuration.cross_strategy_configuration
          : configuration.grid_strategy_configuration

      setValue('base_id', {
        value: {
          base_id: configurationStrategyObj?.currency_pair?.base_id,
        } as CurrencyPairDetail,
        label: configurationStrategyObj?.currency_pair?.base ?? '',
      })
      setValue('quote_id', +(configurationStrategyObj?.currency_pair?.quote_external_id ?? 1))
    }
  }, [configuration])


  useEffect(() => {
    if (configuration) return
    setValue('base_id', undefined)
  }, [platformId, strategy, interval])

  const memoizedBaseList = useMemo(() => {
    const baseList = currencyPairs.map(pair => {
      return { label: pair.base, value: pair }
    })
    baseList.sort((a, b) => (a.label > b.label ? 1 : -1))
    return uniqueList(baseList)
  }, [currencyPairs])

  const baseObj = memoizedBaseList.find(base => base?.value?.base_id === baseId)

  const memoizedQuoteList = useMemo(() => {
    const quoteList = currencyPairs
      .filter(pair => pair.base_id === baseObj?.value?.base_id)
      .map(pair => {
        return { label: pair.quote, value: pair.quote_id }
      })
    return uniqueList(quoteList)
  }, [baseObj?.value, currencyPairs])

  const quoteObj = memoizedQuoteList.find(quote => quote.value === quoteId)

  const trancheTypesOptions = useMemo(() => {
    return [
      {
        label: baseObj?.label,
        value: trancheTypes.find(trancheType => trancheType.name === trancheTypeNames.baseCurrency)?.id,
      },
      {
        label: quoteObj?.label,
        value: trancheTypes.find(trancheType => trancheType.name === trancheTypeNames.quoteCurrency)?.id,
      },
      {
        label: '%',
        value: trancheTypes.find(trancheType => trancheType.name === trancheTypeNames.percentage)?.id,
      },
    ]
  }, [baseObj?.label, quoteObj?.label, trancheTypes])

  useEffect(() => {
    if (hasStrategies && trancheTypes) {
      const trancheType = trancheTypes.find(trancheType => trancheType.name === 'PERCENTAGE')
      if (trancheType) {
        setValue('grid_strategy_details.tranche_type_id', trancheType.id)
        setValue('grid_strategy_details.tranche_value', 1)
      }
    }
  }, [trancheTypes, hasStrategies])

  useEffect(() => {
    if (base) {
      setSelectedCurrencyPair(base.value)
    }
  }, [base])

  useEffect(() => {
    if (selectedCurrencyPair) {
      const baseExternalId = selectedCurrencyPair?.base_external_id?.substr(7)

      if (
        baseExternalId &&
        (selectedCurrencyPairPrice?.baseExternalId !== baseExternalId || !selectedCurrencyPairPrice?.baseExternalId)
      ) {
        ; (async () => {
          try {
            const res = await getCurrencyPairPrice(baseExternalId)
            if (res) {
              const low24h = res?.market_data?.low_24h.usd
              const last = res?.market_data?.current_price.usd
              setSelectedCurrencyPairPrice({
                low_24h: String(low24h),
                lowest_ask: '',
                last: String(last),
                baseExternalId,
              })
              if (!configuration) {
                setValue('grid_strategy_details.price_for_start', low24h)
              }
            }
          } catch (e) {
            toast.error(e.message)
          }
        })()
      }
    }
  }, [selectedCurrencyPair, configuration])

  useEffect(() => {
    if (!configuration) {
      // if (memoizedBaseList)
      //   setValue('base_id', { value: memoizedBaseList[0]?.value, label: memoizedBaseList[0]?.label })
      if (memoizedQuoteList) setValue('quote_id', memoizedQuoteList[0]?.value)
    }
  }, [memoizedBaseList, memoizedQuoteList, configuration])

  useEffect(() => {
    if (currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.GRID && !configuration) {
      const lowerPriceThresholdDefault = Number(selectedCurrencyPairPrice?.last) * 0.9
      const priceForFinishDefault = Number(selectedCurrencyPairPrice?.last) * 1.2
      setValue('grid_strategy_details.lower_price_threshold', +toFixed(lowerPriceThresholdDefault) ?? 0)
      setValue('grid_strategy_details.price_for_finish', +toFixed(priceForFinishDefault) ?? 0)
      setValue('grid_strategy_details.step_percentage', 1)
    }
  }, [currentStrategy, selectedCurrencyPairPrice])

  useEffect(() => {
    if (
      stopStrategyError &&
      stopLossStrategy &&
      currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.GRID
    )
      setStopStrategyError(false)
  }, [stopLossStrategy, stopStrategyError, currentStrategy])

  useEffect(() => {
    if (currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.CROSS && !interval) {
      setValue('cross_strategy_details.interval', CrossStrategyIntervalEnum.Type15M)
    }
  }, [currentStrategy, interval])

  const handleAsyncCurrencyPairs = useCallback(
    async (page: number, searchQuery?: string): Promise<ISelectOption<CurrencyPairDetail>[]> => {
      const { data } = await generatedApi.v1.getCurrencyPairsV1AssetsClientCurrencyPairsGet({
        page,
        size: 10,
        sub_strategy_id:
          currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.CROSS
            ? (currentStrategy?.subStrategyId as number)
            : undefined,
        interval:
          currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.CROSS ? interval : undefined,
        platform_id: platformId,
        base_search: searchQuery,
      })
      if (data?.items) {
        setCurrencyPairs(prev => [...prev, ...data.items])
      }
      const baseList = data?.items
        ?.map(pair => ({ label: pair.base, value: pair }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1))
      return uniqueList(baseList)
    },
    [currentStrategy, platformId, interval]
  )

  const handleConfigurationData = (data: CreateUpdateConfigurationDTO): CreateUpdateConfigurationDTO => {
    if (data.copy_trading_strategy_details) {
      data.copy_trading_strategy_details.trading_platform_id = data.trading_platform_id
    }
    data.base_id = undefined
    data.strategy = currentStrategy?.strategyEnumValue as string

    switch (currentStrategy?.strategyEnumValue) {
      case TradingConfigurationStrategiesEnum.CROSS:
        if (data.cross_strategy_details) {
          data.cross_strategy_details.trading_platform_id = data.trading_platform_id
          data.grid_strategy_details = undefined
          data.cross_strategy_details.sub_strategy_id = currentStrategy.subStrategyId as number
          if (selectedCurrencyPair) {
            data.cross_strategy_details.currency_pair_id = selectedCurrencyPair.id
          }
        }
        return data
      case TradingConfigurationStrategiesEnum.GRID:
        if (data.grid_strategy_details) {
          data.cross_strategy_details = undefined
          data.grid_strategy_details.trading_platform_id = data.trading_platform_id
          data.grid_strategy_details.stop_strategy_value = Number(stopLossValue)
          if (selectedCurrencyPair) {
            data.grid_strategy_details.currency_pair_id = selectedCurrencyPair.id
          }
        }
        return data
      default:
        return data
    }
  }

  const onSubmit = (data: CreateUpdateConfigurationDTO): void => {
    const configurationData = handleConfigurationData(data)

    if (currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.GRID && data.grid_strategy_details) {
      if (stopLossStrategy) {
        data.grid_strategy_details.stop_strategy_id = stopLossStrategy
      } else {
        setStopStrategyError(true)
        return
      }
    }
    setIsLoading(true)
    if (configuration) {
      updateTradingConfiguration({
        configurationId: configuration.id,
        body: configurationData,
      })
    } else {
      createTradingConfiguration(configurationData)
    }
  }

  useEffect(() => {
    if (isUpdated) onSuccess(false)
    if (error || isUpdated) setIsLoading(false)
  }, [isUpdated, error])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={isLoading ? styles.form__is__loading : ''}>
        <TextInput
          id={'name'}
          label={'Configuration name'}
          placeholder={'Enter name'}
          register={register('name', requiredField)}
          fullwidth={true}
          error={errors.name?.message}
        />
        <AutoCompleteInput
          id={'strategy'}
          placeholder={'Select strategy...'}
          control={control}
          label={'Strategy'}
          disabled={!hasStrategies || !!configuration}
          options={mergedStrategies}
          rules={{ required: { value: true, message: 'This field is required' } }}
          error={errors.strategy?.message}
          isLoading={mergedStrategiesLoading}
        />
        <AutoCompleteInput
          id={'trading_platform_id'}
          placeholder={'Select platform...'}
          control={control}
          disabled={!hasTradingPlatforms || !!configuration}
          label={'Trading platform'}
          options={tradingPlatformFromCredentials || []}
          rules={{ required: { value: true, message: 'This field is required' } }}
          error={errors.trading_platform_id?.message}
          isLoading={isLoadingTradingPlatforms}
        />
        <Typography variant={'inherit'} className={styles.trading__pair__label}>
          {'Currency pair selection'}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <SelectPaginate
              id={'base_id'}
              onAsync={handleAsyncCurrencyPairs}
              key={`${baseId}_${platformId}_${strategy ?? 0}_${interval ?? 0}`}
              placeholder={'Select asset...'}
              control={control}
              rules={configuration ? { required: { value: true, message: 'This field is required' } } : {}}
              disabled={!!configuration}
              error={errors.base_id?.message}
            />
          </Grid>
        </Grid>
        {currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.GRID && (
          <GridConfigurationFields
            setStopLossStrategy={setStopLossStrategy}
            stopLossStrategy={stopLossStrategy}
            stopLossValue={stopLossValue}
            register={register}
            errors={errors}
            stopStrategies={stopStrategies}
            stopStrategyError={stopStrategyError}
            trancheTypesOptions={trancheTypesOptions}
            setStopLossValue={setStopLossValue}
            selectedCurrencyPairPrice={selectedCurrencyPairPrice}
            control={control}
          />
        )}
        {currentStrategy?.strategyEnumValue === TradingConfigurationStrategiesEnum.CROSS && (
          <CrossConfigurationFields
            configuration={configuration}
            errors={errors}
            register={register}
            control={control}
          />
        )}

        <Box>{isLoading ? <Loader /> : <SubmitButton text={'Save configuration'} fullWidth={true} />}</Box>
      </form>
    </>
  )
}

export default CreateConfigurationForm
