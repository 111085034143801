import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetCurrencyPairsV1AssetsClientCurrencyPairsGetParams, PageCurrencyPairDetail } from 'api/generated'
import { generatedApi } from 'api/v1'
import { ApiError, handleErrors } from 'api/v1/errorUtils'
import axios from 'axios'

const getCurrencyPairs = createAsyncThunk<PageCurrencyPairDetail, GetCurrencyPairsV1AssetsClientCurrencyPairsGetParams>(
  'tradingCurrencyPairs/getCurrencyPairs',
  async (params, thunkAPI) => {
    try {
      const { data } = await generatedApi.v1.getCurrencyPairsV1AssetsClientCurrencyPairsGet(params)
      return data
    } catch (e) {
      return handleErrors(e as ApiError, thunkAPI)
    }
  }
)

interface Low24h {
  usd: number
}

interface MarketData {
  low_24h: Low24h
  current_price: Low24h
}

interface IGetCurrencyPairPriceResponse {
  market_data: MarketData
}

export const getCurrencyPairPrice = async (currencyPair: string): Promise<IGetCurrencyPairPriceResponse> => {
  // try {
  const { data } = await axios.get<IGetCurrencyPairPriceResponse>(
    'https://api.coingecko.com/api/v3/coins/' +
      currencyPair +
      '?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false'
  )
  return data
  // } catch (e) {
  //   return 'Error get Currency Pair Price by Id ' + currencyPair + ' from api.coingecko.com'
  // }
}

const tradingCurrencyPairsThunks = {
  getCurrencyPairs,
}

export default tradingCurrencyPairsThunks
