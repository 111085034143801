import React, { ReactElement } from 'react';
import styles from 'pages/ForgotPasswordPage/styles.module.scss';
import Header from 'shared/components/Header';
import { Grid } from '@mui/material';
import ForgotPasswordForm from 'features/auth/components/ForgotPasswordForm';

const ForgotPasswordPage = (): ReactElement => {
  return (
    <Grid container className={styles.reset__page}>
      <Header logoName={'NOSIS TRADING BOT'} />
      <Grid container className={styles.reset__page__container}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Grid container className={styles.reset__page__sign_in__form__container}>
            <ForgotPasswordForm />
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
