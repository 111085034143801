import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { StopStrategyDetail } from 'api/generated'
import authThunks from 'features/auth/redux/auth/auth.thunks'
import tradingStopStrategiesThunks from './tradingStopStrategies.thunks'
const { getStopStrategies } = tradingStopStrategiesThunks
const { logout } = authThunks

export interface StopStrategiesInitialState {
  strategies: StopStrategyDetail[]
  isLoading: boolean
  error: string
}

const initialState: StopStrategiesInitialState = {
  isLoading: false,
  strategies: [],
  error: '',
}

const tradingStopStrategiesSlice = createSlice({
  name: 'tradingStopStrategies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(isPending(getStopStrategies), state => {
        state.isLoading = true
        state.error = ''
      })
      .addMatcher(isFulfilled(getStopStrategies), (state, action) => {
        state.isLoading = false
        state.strategies = action.payload
      })
      .addMatcher(isRejected(getStopStrategies), (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
  },
})

export const tradingStopStrategiesActions = tradingStopStrategiesSlice.actions

export const { reducer: tradingStopStrategiesReducer } = tradingStopStrategiesSlice

export default tradingStopStrategiesReducer
