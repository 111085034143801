import { createTheme } from '@mui/material';

export const mainTheme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          padding: '3px 0',
          borderRadius: 12,
          height: 40,
          backgroundColor: '#F2F5F6',
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '9px !important',
          margin: '0 5px',
          height: 34,
          padding: '11px 22px',
          color: '#B2BEC3',
          fontWeight: 500,
          lineHeight: '20px',
          textTransform: 'capitalize',
          '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: '#2D3436',
          },
          '&.Mui-disabled': {
            border: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h6: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '24px',
      color: '#2D3436',
    },
  },
});
