import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { OrderDetail } from 'api/generated'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { tradingOrdersActions } from '../redux/tradingOrders/tradingOrders.slice'
import tradingOrdersThunks from '../redux/tradingOrders/tradingOrders.thunks'

const useTradingOrders = () => {
  const ordersState = (store: RootState): OrderDetail[] => store.tradingOrders.orders
  const isLoadingState = (state: RootState): boolean => state.tradingOrders.isLoading
  const errorState = (state: RootState): string => state.tradingOrders.error
  const openOrdersState = (store: RootState) => store.tradingOrders.getOpenOrdersState
  const historicalOrdersState = (store: RootState) => store.tradingOrders.historicalOrdersState
  const paginateState = (store: RootState) => store.tradingOrders.paginate
  const orders = useAppSelector(ordersState)

  const openOrders = useAppSelector(
    createSelector(ordersState, (item): OrderDetail[] => item.filter(order => order.status === 'OPEN'))
  )
  const closedOrders = useAppSelector(
    createSelector(ordersState, (item): OrderDetail[] => item.filter(order => order.status !== 'OPEN'))
  )
  const ordersByTradingConfigurationId = useCallback(
    (id: number) => orders?.filter(order => order.trading_configuration_id === id),
    [orders]
  )
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const paginate = useAppSelector(paginateState)
  const allHistoricalOrders = useAppSelector(historicalOrdersState)
  const getOpenOrderState = useAppSelector(openOrdersState)
  const dispatch = useAppDispatch()

  return useMemo(() => {
    const actions = {
      ...tradingOrdersActions,
      ...tradingOrdersThunks,
    }
    return {
      ...bindActionCreators(actions, dispatch),
      orders,
      isLoading,
      error,
      openOrders,
      closedOrders,
      ordersByTradingConfigurationId,
      allHistoricalOrders,
      getOpenOrderState,
      dispatch,
      paginate,
    }
  }, [
    orders,
    isLoading,
    error,
    openOrders,
    closedOrders,
    ordersByTradingConfigurationId,
    allHistoricalOrders,
    getOpenOrderState,
    dispatch,
    paginate,
  ])
}

export default useTradingOrders
