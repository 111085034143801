import React from 'react'
import ModalPopup from 'shared/components/ModalPopup'
import DepositConfigurationForm from 'features/trading/components/ConfigurationDepositModal/DepositConfigurationForm'
import { ConfigurationDetail } from 'api/generated'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'

type ConfigurationDepositParams = {
  open: boolean
  setOpen: (newState: boolean) => void
  configuration: ConfigurationDetail
}

const ConfigurationDepositModal = ({ open, setOpen, configuration }: ConfigurationDepositParams) => {
  const title = `Deposit to ${configuration.name}`
  const { updateTradingInfo } = useTradingConfigurations(false)

  const onDepositSuccess = () => {
    setOpen(false)
    updateTradingInfo()
  }

  return (
    <ModalPopup
      open={open}
      setOpen={setOpen}
      title={title}
      content={<DepositConfigurationForm configuration={configuration} onSuccess={onDepositSuccess} />}
    />
  )
}

export default ConfigurationDepositModal
