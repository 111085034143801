import React, { ReactElement, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import styles from 'features/auth/components/ResetPasswordForm/styles.module.scss'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import { useForm } from 'react-hook-form'
import { ResetPasswordRequestDTO } from 'features/auth/ts'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import PasswordInput from 'shared/components/FormComponents/PasswordInput'
import { resetPasswordAPIRequest } from 'features/auth/api'
import useAuth from 'features/auth/hooks/useAuth'

const ResetPasswordForm = (): ReactElement => {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordRequestDTO>()

  const navigate = useNavigate()
  const token = useParams().token
  const { isLoading } = useAuth()
  const [isFailed, setIsFailed] = useState<boolean>(false)

  const onSubmit = (data: ResetPasswordRequestDTO): void => {
    if (data.password !== data.confirm_password) {
      return setError('confirm_password', {
        message: 'Confirm password should be equal to password',
      })
    }
    data.reset_token = token || ''
    resetPasswordAPIRequest(data)
      .then(() => navigate('/login'))
      .catch(() => setIsFailed(true))
  }

  return (
    <Box className={styles.reset__form__container}>
      <Typography variant={'inherit'} className={styles.reset__form__name}>
        Please enter your new password
      </Typography>
      <Typography variant={'inherit'} className={[styles.reset__help__text, isFailed && styles.failed].join(' ')}>
        {isFailed ? 'Token signature has expired.' : 'Please make sure your password has at least 8 characters'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={isLoading ? styles.reset__form__loading : null}>
        <PasswordInput
          register={register('password', {
            required: { value: true, message: 'This field is required' },
            minLength: { value: 8, message: 'Password length should be more than 8.' },
          })}
          fullwidth={true}
          label={'Password'}
          error={errors.password?.message}
        />
        <PasswordInput
          register={register('confirm_password', { required: true })}
          fullwidth={true}
          label={'Confirm password'}
          error={errors.confirm_password?.message}
        />

        <SubmitButton text={'Set new password'} fullWidth={true} disabled={isLoading} />
        <Link className={styles.reset__form__go__back__link} to={ROUTES.login}>
          <Button className={styles.reset__form__go__back__button}>Back</Button>
        </Link>
      </form>
    </Box>
  )
}

export default ResetPasswordForm
