import { ChartOptions } from 'chart.js';
import { differenceInHours, differenceInMonths } from 'date-fns';

interface Params {
  startDate: number;
  endDate: number;
}

export const getOptions = (params: Params): ChartOptions => {
  const { startDate, endDate } = params;

  const needShowHourUnit = differenceInHours(endDate, startDate) <= 24;
  const needShowMonthUnit = differenceInMonths(endDate, startDate) >= 4;

  return {
    maintainAspectRatio: false,

    plugins: {
      tooltip: {
        backgroundColor: '#ffffff',
        titleColor: '#595959',
        titleFont: { weight: '500', family: 'Poppins', size: 14 },
        bodyFont: { weight: 'normal', family: 'Poppins', size: 14 },
        bodyColor: '#595959',
        padding: {
          left: 12,
          top: 12,
          bottom: 12,
          right: 60,
        },
        bodySpacing: 6,
        boxPadding: 8,
        usePointStyle: true,
        footerMarginTop: 30,
        footerFont: { weight: 'normal', family: 'Poppins', size: 12 },
        footerColor: '#595959',
        borderWidth: 1,
        borderColor: '#D9D9D9',
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          color: 'rgba(178, 190, 195, 1)',
          font: {
            size: 12,
            weight: '400',
          },
        },
        grid: {
          color: 'rgba(45, 52, 54, 0.08)',
          drawBorder: false,
        },
      },
      x: {
        type: 'time',
        time: (needShowHourUnit && {
          unit: 'hour',
          stepSize: 3,
        }) ||
          (needShowMonthUnit && { unit: 'month' }) || {
            unit: 'day',
          },
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: 'rgba(178, 190, 195, 1)',
          font: {
            size: 12,
            weight: '400',
          },
        },
      },
    },
  };
};
