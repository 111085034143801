import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useCallback, useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { CredentialWithBalanceDetail } from 'api/generated'
import { credentialsActions } from 'features/credentials/redux/credentials/credentials.slice'
import credentialsThunks from 'features/credentials/redux/credentials/credentials.thunks'
import useAuth from 'features/auth/hooks/useAuth'

const useCredentials = (load = true) => {
  const credentialsState = (store: RootState): CredentialWithBalanceDetail[] | null => store.credentials.credentials
  const credentialByIdState = (store: RootState): CredentialWithBalanceDetail | null => store.credentials.credentialById

  const isUpdatedState = (state: RootState): boolean => state.credentials.isUpdated
  const isLoadingState = (state: RootState): boolean => state.credentials.isLoading
  const isLoadingEditState = (state: RootState): boolean => state.credentials.isLoadingEdit
  const errorState = (state: RootState): string => state.credentials.error
  const hasCredentials = useAppSelector(createSelector(credentialsState, (item): boolean => !!item && item.length > 0))
  const credentials = useAppSelector(credentialsState)
  const credentialById = useAppSelector(credentialByIdState)

  const balanceByTradingPlatformId = useCallback(
    (id: number) => credentials?.filter(i => i.trading_platform_id === id)[0].balances,
    [credentials]
  )
  const { isAuth } = useAuth()

  const isLoadingEdit = useAppSelector(isLoadingEditState)
  const isLoading = useAppSelector(isLoadingState)
  const isUpdated = useAppSelector(isUpdatedState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()

  const actions = {
    ...credentialsActions,
    ...credentialsThunks,
  }
  const actionsDispatch = { ...bindActionCreators(actions, dispatch) }
  const { getUserCredentials } = actionsDispatch

  useEffect(() => {
    if (!hasCredentials && isAuth && !isLoading && load) {
      getUserCredentials()
    }
  }, [hasCredentials, isUpdated])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasCredentials,
      credentials,
      credentialById,
      isLoading,
      isUpdated,
      isLoadingEdit,
      error,
      balanceByTradingPlatformId,
    }
  }, [
    hasCredentials,
    credentials,
    credentialById,
    isLoading,
    isLoadingEdit,
    error,
    balanceByTradingPlatformId,
    dispatch,
  ])
}

export default useCredentials
