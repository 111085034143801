import useTradingOrders from 'features/trading/hooks/useTradingOrders'
import { useAppSelector } from 'store'
import { useEffect } from 'react'
import { OrderingEnum } from 'api/generated'

export const useHistoricalOrders = (id?: number) => {
  const { getPaginatedOrders } = useTradingOrders()
  const { historicalOrdersState, isLoading, paginate } = useAppSelector(state => state.tradingOrders)
  useEffect(() => {
    if (!historicalOrdersState[(id ? id : 0) as number] && !isLoading) {
      getPaginatedOrders({
        configuration_id: id,
        page: 1,
        size: 6,
        statuses: 'CANCELLED,CLOSED,PENDING,FAILED',
        ordering: OrderingEnum.DESC,
      })
    }
  }, [id])

  return {
    historicalOrders: historicalOrdersState[id ? (id as number) : 0],
    isLoading: isLoading,
    paginate: paginate[id ? (id as number) : 0],
  }
}
