import { bindActionCreators, createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo } from 'react'
import { RootState, useAppDispatch, useAppSelector } from 'store'
import { StopStrategyDetail } from 'api/generated'
import { tradingStopStrategiesActions } from '../redux/tradingStopStrategies/tradingStopStrategies.slice'
import tradingStopStrategiesThunks from '../redux/tradingStopStrategies/tradingStopStrategies.thunks'

const useTradingStopStrategies = () => {
  const stopStrategiesState = (store: RootState): StopStrategyDetail[] => store.tradingStopStrategies.strategies
  const isLoadingState = (state: RootState): boolean => state.tradingStopStrategies.isLoading
  const errorState = (state: RootState): string => state.tradingStopStrategies.error
  const hasStopStrategies = useAppSelector(
    createSelector(stopStrategiesState, (item): boolean => !!item && item.length > 0)
  )
  const stopStrategies = useAppSelector(stopStrategiesState)
  const isLoading = useAppSelector(isLoadingState)
  const error = useAppSelector(errorState)
  const dispatch = useAppDispatch()
  const actions = {
    ...tradingStopStrategiesActions,
    ...tradingStopStrategiesThunks,
  }
  const actionsDispatch = bindActionCreators(actions, dispatch)
  const { getStopStrategies } = actionsDispatch

  useEffect(() => {
    if (!hasStopStrategies && !isLoading) getStopStrategies()
  }, [hasStopStrategies])

  return useMemo(() => {
    return {
      ...actionsDispatch,
      hasStopStrategies,
      stopStrategies,
      isLoading,
      error,
    }
  }, [hasStopStrategies, stopStrategies, isLoading, error, dispatch])
}

export default useTradingStopStrategies
