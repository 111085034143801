import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SubmitButton from 'shared/components/FormComponents/SubmitButton'
import useCredentials from 'features/credentials/hooks/useCredentials'
import TextInput from 'shared/components/FormComponents/TextInput'
import Loader from 'shared/components/Loader'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import { CredentialWithBalanceDetail } from 'api/generated'
import ServerIps from '../ServerIps'
import styles from './styles.module.scss'

export interface TradingPlatformConnectFormData {
  apiKey: string
  secretKey: string
}

interface ICredentialsConnectModalProps {
  credentialCurrent: CredentialWithBalanceDetail & { connection_user_guide_text?: string }
  onBack: () => void
}

const CredentialsConnectForm = ({ credentialCurrent, onBack }: ICredentialsConnectModalProps) => {
  const { setUserCredentials, isUpdated, isLoadingEdit } = useCredentials(false)
  const { updateTradingInfo } = useTradingConfigurations(false)

  const {
    register,
    handleSubmit: handleSubmitCredentials,
    formState: { errors, isValid },
  } = useForm<TradingPlatformConnectFormData>()

  const onCredentialsUpdate = (data: TradingPlatformConnectFormData) => {
    setUserCredentials({
      trading_platform_id: credentialCurrent.trading_platform_id,
      api_keys_json_data: {
        api_key: data.apiKey,
        secret_key: data.secretKey,
      },
    })
  }

  useEffect(() => {
    if (isUpdated) {
      updateTradingInfo()
      onBack()
    }
  }, [isUpdated])

  return (
    <>
      <Typography align={'left'} className={styles.textHint}>
        {credentialCurrent.connection_user_guide_text?.split('\n')?.map(i => (
          <>
            {i}
            <br />
          </>
        ))}
      </Typography>

      <ServerIps tradingPlatformName={credentialCurrent.trading_platform_name} />

      <form
        id={'form_credentials'}
        key={3}
        onSubmit={handleSubmitCredentials(onCredentialsUpdate)}
        autoComplete={'off'}
      >
        <Grid className={styles.container}>
          <Grid item xs={12}>
            <TextInput
              error={errors?.apiKey?.message}
              fullwidth={true}
              register={register('apiKey', {
                required: { value: true, message: 'Please enter API key' },
              })}
              label={'API key'}
              marginTop={'0px'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              error={errors.secretKey?.message}
              fullwidth={true}
              register={register('secretKey', {
                required: { value: true, message: 'Please enter API secret' },
              })}
              label={'API secret'}
              marginTop={'0px'}
            />
          </Grid>
        </Grid>
        <Grid className={styles.container__button}>
          <Grid item xs={6} className={styles.block__button} sx={{ paddingRight: '16px' }}>
            <Button fullWidth={true} variant={'outlined'} className={styles.button__back} onClick={onBack}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} className={styles.block__button}>
            {isLoadingEdit ? <Loader /> : <SubmitButton fullWidth={true} disabled={!isValid} text={'Connect'} />}
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default CredentialsConnectForm
