import React, { ReactElement, useEffect, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import stopStrategiesNames from 'constants/stopStrategies'
import { configurationStatuses } from 'constants/tradingConfigurations'
import trancheTypeNames from 'constants/trancheType'
import ConfigurationDepositModal from 'features/trading/components/ConfigurationDepositModal'
import ConfigurationActivationButton, {
  ButtonMode,
} from 'features/trading/components/ConfigurationList/ConfigurationCard/ConfigurationActivationButton'
import ConfigurationWithdrawModal from 'features/trading/components/ConfigurationWithdrawModal'
import CreateUpdateConfigurationModal from 'features/trading/components/CreateUpdateConfigurationModal'
import DeleteConfigurationModal from 'features/trading/components/DeleteConfigurationModal'
import OrderTable from 'features/trading/components/OrderTable'
import { ProfitStatistic } from 'features/trading/components/ProfitStatistic'
import useTradingConfigurationProfit from 'features/trading/hooks/useTradingConfigurationProfit'
import useTradingConfigurations from 'features/trading/hooks/useTradingConfigurations'
import useTradingOrders from 'features/trading/hooks/useTradingOrders'
import useTradingPlatforms from 'features/trading/hooks/useTradingPlatforms'
import useTradingStopStrategies from 'features/trading/hooks/useTradingStopStrategies'
import useTradingStrategies from 'features/trading/hooks/useTradingStrategies'
import useTradingTrancheTypes from 'features/trading/hooks/useTradingTrancheTypes'
import { capitalize } from 'lodash'
import styles from 'pages/ConfigurationDetailPage/styles.module.scss'

import { useNavigate, useParams } from 'react-router-dom'
import { DateChart } from 'shared/components/DateChart'
import GoBackButton from 'shared/components/GoBackButton'
import Loader from 'shared/components/Loader'
import ModalConfirmPopup from 'shared/components/ModalConfirmPopup'
import NavPageContainer from 'shared/components/NavPageContainer'
import PageContainer from 'shared/components/PageContainer'
import { Socket } from 'socket.io-client'
import { OrderingEnum, TradingConfigurationState, TradingConfigurationStrategiesEnum } from 'api/generated'
import { useOpenOrders } from 'features/trading/hooks/useOpenOrders'
import { useHistoricalOrders } from 'features/trading/hooks/useHistoricalOrders'

const ConfigurationDetailPage = ({ tabIndex }: { tabIndex: number; ws?: Socket }): ReactElement => {
  const params = useParams()
  const id = Number(params.id)

  const { configurationProfit, isLoading: configurationProfitIsLoading } = useTradingConfigurationProfit(id)

  const navigate = useNavigate()
  const { configurationsById, isLoading, changeStatusTradingConfiguration, isUpdated, toggleIsUpdated } =
    useTradingConfigurations()
  const { getPaginatedOrders } = useTradingOrders()
  useTradingTrancheTypes()
  useTradingPlatforms()
  useTradingStrategies()
  useTradingStopStrategies()
  const configuration = configurationsById(id)
  const { openOrders: getAllOpenOrders } = useOpenOrders(id)
  const {
    historicalOrders: getAllHistoricalOrders,
    isLoading: isLoadingHistoricalOrders,
    paginate,
  } = useHistoricalOrders(id)

  const hasMoreClosedOrders = paginate?.page * paginate?.size < paginate?.total
  const gridDetails = configuration?.grid_strategy_configuration
  const crossDetails = configuration?.cross_strategy_configuration

  const balance = configuration?.balances?.total ?? 0

  const [activationModalOpen, setActivationModalOpen] = useState(false)
  const [depositModalOpen, setDepositModalOpen] = useState(false)
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (isUpdated) {
      toggleIsUpdated()
    }
  }, [isUpdated])

  function createData(
    fieldName: string,
    fieldValue: number | string,
    fieldImage?: string
  ): { fieldName: string; fieldValue: number | string; fieldImage?: string } {
    return { fieldName, fieldValue, fieldImage }
  }

  const handleGetNextPage = (page: number, status: string, ordering: string, configuration_id?: number): void => {
    if (!isLoadingHistoricalOrders && paginate?.page !== page) {
      getPaginatedOrders({
        page,
        size: 6,
        statuses: status,
        ordering: ordering === 'DESC' ? OrderingEnum.DESC : OrderingEnum.ASC,
        configuration_id,
      })
    }
  }

  const trancheLabel =
    gridDetails?.tranche_type === trancheTypeNames.baseCurrency
      ? gridDetails?.currency_pair.base
      : gridDetails?.tranche_type === trancheTypeNames.quoteCurrency
      ? gridDetails?.currency_pair.quote
      : '%'

  const profit = configuration?.profit?.total_percent_profit
    ? `${configuration.profit.total_percent_profit >= 0 ? '+' : ''}${configuration.profit.total_percent_profit}%`
    : ''

  const handleActivation = (): void => {
    if (configuration) {
      changeStatusTradingConfiguration({
        configurationId: configuration.id,
        status: (configuration.status === configurationStatuses.active ? 'stop' : 'start') as TradingConfigurationState,
      })
    }
  }

  const handleDelete = () => {
    setDeleteModalOpen(true)
  }

  const deletedConfiguration = () => {
    navigate('/')
  }

  const handleEdit = () => {
    setIsUpdating(true)
  }

  const handleDeposit = () => {
    setDepositModalOpen(true)
  }

  const handleWithdraw = () => {
    setWithdrawModalOpen(true)
  }

  const handleActivateConfiguration = (): void => {
    setActivationModalOpen(true)
  }

  const generateRows = () => {
    const configurationStrategy =
      configuration?.strategy === TradingConfigurationStrategiesEnum.CROSS
        ? configuration?.cross_strategy_configuration
        : configuration?.grid_strategy_configuration
    const rows = [
      createData(
        'Strategy',
        `${
          configuration?.strategy === TradingConfigurationStrategiesEnum.CROSS
            ? configuration?.cross_strategy_configuration?.sub_strategy?.name
            : configuration?.strategy
        }`
      ),
      createData('Deposited', `${configuration?.balances?.deposited} USDT`),
    ]
    switch (configuration?.strategy) {
      case TradingConfigurationStrategiesEnum.CROSS:
        rows.push(
          createData(
            'Pair',
            `${configurationStrategy?.currency_pair?.base} - ${configurationStrategy?.currency_pair?.quote}`
          ),
          createData(
            'Platform',
            `${configuration?.trading_platform.name ? configuration.trading_platform.name : 'Gate.io'}`,
            configuration?.trading_platform?.external_image_url
          ),
          createData('Interval', `${crossDetails?.interval}`)
        )
        break
      case TradingConfigurationStrategiesEnum.GRID:
        rows.push(
          createData(
            'Pair',
            `${configurationStrategy?.currency_pair?.base} - ${configurationStrategy?.currency_pair?.quote}`
          ),
          createData('Tranche', `${gridDetails?.tranche_value} ${trancheLabel}`),
          createData('Step', `${gridDetails?.step_percentage} %`),
          createData(
            'Platform',
            `${configuration?.trading_platform?.name ? configuration.trading_platform.name : 'Gate.io'}`,
            configuration?.trading_platform?.external_image_url
          ),
          createData(
            `${
              stopStrategiesNames.find(stopStrategy => stopStrategy.name === gridDetails?.stop_strategy)?.description
            }`,
            `${gridDetails?.stop_strategy_value}`
          )
        )
        break
    }
    return rows
  }

  const rows = generateRows()
  const tooltipDisabled = configuration?.status === configurationStatuses.maintenance
  const inActiveStatuses = [
    configurationStatuses.inActive,
    configurationStatuses.withdraw,
    configurationStatuses.maintenance,
  ]
  return (
    <>
      <NavPageContainer tabIndex={tabIndex}>
        <PageContainer
          labelBlock={
            isLoading || !configuration ? (
              <Loader />
            ) : (
              <Grid container>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <GoBackButton />
                    </Grid>
                    <Grid item>
                      <Box>
                        <Typography className={styles.label}>{configuration?.name}</Typography>
                        <Typography
                          className={[
                            styles.status,
                            inActiveStatuses.includes(configuration?.status) ? styles.stopped : styles.active,
                          ].join(' ')}
                        >
                          {configuration?.status === configurationStatuses.inActive ? (
                            <CircleIcon className={styles.status__icon} fontSize={'small'} />
                          ) : (
                            <CircleIcon className={styles.status__icon} />
                          )}
                          {capitalize(configuration?.status)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
          tooltip={
            <Grid container>
              <Grid item>
                <Box className={styles.activation__button}>
                  <ConfigurationActivationButton
                    disabled={tooltipDisabled || !configuration}
                    mode={
                      configuration?.status === configurationStatuses.active
                        ? ('stop' as ButtonMode)
                        : ('run' as ButtonMode)
                    }
                    onClick={handleActivateConfiguration}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Button
                  className={styles.edit__button}
                  onClick={handleEdit}
                  disabled={tooltipDisabled || !configuration}
                  classes={{ disabled: styles.button__disabled }}
                >
                  <EditIcon className={styles.edit__icon} />
                  {'Edit'}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  disabled={tooltipDisabled || !configuration}
                  className={styles.delete__button}
                  classes={{ disabled: styles.button__disabled }}
                  onClick={handleDelete}
                >
                  <DeleteOutlineIcon className={styles.delete__icon} /> {'Delete'}
                </Button>
              </Grid>
            </Grid>
          }
        >
          <>
            {isLoading || !configuration ? (
              <Box className={styles.loader__container}>
                <Loader />
              </Box>
            ) : (
              <>
                <Grid container spacing={3} alignItems='stretch'>
                  <Grid item xs={4}>
                    <Box className={styles.content__container}>
                      <Typography className={styles.label}>Balance</Typography>
                      <Box className={styles.balance__amount__container}>
                        <Typography variant={'inherit'} className={styles.balance__amount}>
                          {balance.toLocaleString()}
                        </Typography>
                        <Box className={styles.balance__profit__container}>
                          <Typography variant={'inherit'} className={styles.balance__currency}>
                            USDT
                          </Typography>
                          <Typography
                            variant={'inherit'}
                            className={
                              configuration?.profit?.total_percent_profit >= 0
                                ? styles.balance__profit__positive
                                : styles.balance__profit__negative
                            }
                          >
                            {profit}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={styles.deposit__container}>
                        <Button onClick={handleDeposit} disabled={tooltipDisabled} className={styles.deposit__button}>
                          Deposit
                        </Button>
                        <Button
                          onClick={handleWithdraw}
                          disabled={tooltipDisabled || balance === 0}
                          className={styles.deposit__button}
                        >
                          Withdraw
                        </Button>
                      </Box>
                    </Box>
                    <Box className={styles.content__container}>
                      <Typography className={styles.label}>Information</Typography>
                      <Table className={styles.table}>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className={styles.table__field__name}>{row.fieldName}</TableCell>
                              <TableCell className={styles.table__field__value}>
                                {row.fieldImage && (
                                  <img
                                    className={styles.table__field__image}
                                    src={row.fieldImage}
                                    alt={String(row.fieldValue)}
                                  />
                                )}
                                {row.fieldValue}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      className={styles.content__container}
                      sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                    >
                      <Stack spacing={3} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Typography className={styles.label}>Profit</Typography>

                        {configurationProfitIsLoading && <Loader />}
                        {!configurationProfitIsLoading && configurationProfit && (
                          <>
                            <ProfitStatistic
                              daily={configurationProfit.daily}
                              total={configuration?.profit?.total_cash_profit}
                              average={configurationProfit.average_return}
                            />
                            <DateChart chartData={configurationProfit.chart_data} />
                          </>
                        )}
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <OrderTable
                  orders={getAllOpenOrders}
                  hasMore={false}
                  statusName={'OPEN'}
                  configuration_id={id}
                  label={'Open orders'}
                />
                <OrderTable
                  hasMore={hasMoreClosedOrders}
                  fetchData={handleGetNextPage}
                  orders={getAllHistoricalOrders}
                  statusName={'CANCELLED,CLOSED,PENDING,FAILED'}
                  configuration_id={id}
                  label={'Historical orders'}
                />
              </>
            )}
            {configuration && (
              <>
                {activationModalOpen && (
                  <ModalConfirmPopup
                    open={activationModalOpen}
                    setOpen={setActivationModalOpen}
                    onConfirm={handleActivation}
                    subTitle={
                      configuration.status === configurationStatuses.active
                        ? 'All open orders will be cancelled.'
                        : undefined
                    }
                    title={`Are you sure you want to ${
                      configuration.status === configurationStatuses.active ? 'stop' : 'run'
                    } this trading configuration?`}
                  />
                )}
                {depositModalOpen && (
                  <ConfigurationDepositModal
                    open={depositModalOpen}
                    setOpen={setDepositModalOpen}
                    configuration={configuration}
                  />
                )}
                {withdrawModalOpen && (
                  <ConfigurationWithdrawModal
                    open={withdrawModalOpen}
                    setOpen={setWithdrawModalOpen}
                    configuration={configuration}
                  />
                )}
                {deleteModalOpen && (
                  <DeleteConfigurationModal
                    open={deleteModalOpen}
                    setOpen={setDeleteModalOpen}
                    onSuccess={deletedConfiguration}
                    configuration={configuration}
                  />
                )}
                <CreateUpdateConfigurationModal
                  open={isUpdating}
                  setOpen={setIsUpdating}
                  configuration={configuration}
                />
              </>
            )}
          </>
        </PageContainer>
      </NavPageContainer>
    </>
  )
}

export default ConfigurationDetailPage
