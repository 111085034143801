import React, { ReactElement } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Zoom } from '@mui/material'
import styles from 'shared/components/SortableTable/styles.module.scss'

type SortableTableParams = {
  headers: Array<{
    label: string
    accessor: string
    styles?: (data?: unknown) => Record<string, string>
  }>
  data: Array<Record<string, string | number>>
}

const SortableTable = ({ headers, data }: SortableTableParams): ReactElement => {
  return (
    <Table className={styles.table}>
      <TableHead className={styles.table__heading}>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell className={styles.table__header__cell} key={index}>
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={styles.table__body}>
        {data.map((tableRow, i) => (
          <TableRow key={i}>
            {headers.map((header, index) => (
              <TableCell
                sx={header.styles ? header.styles(tableRow) : undefined}
                key={index}
                className={styles.table__cell}
              >
                {header.accessor === 'status' && tableRow?.failReason ? (
                  <Tooltip color={'#fff'} TransitionComponent={Zoom} title={tableRow?.failReason} arrow={true}>
                    <span style={{ cursor: 'default' }}>{tableRow[header.accessor]}</span>
                  </Tooltip>
                ) : (
                  tableRow[header.accessor]
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SortableTable
